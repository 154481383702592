/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
// import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React base styles
import typography from "assets/theme/base/typography";

function Footer({ company, links }) {
  const { href, name } = company;
  const { size } = typography;

  const renderLinks = () =>
    links.map((link) => (
      <MDBox color="inherit" key={link.name} component="li" px={2} lineHeight={1}>
        <Link href={link.href} target="_blank">
          <MDTypography color="inherit" variant="button" fontWeight="regular">
            {link.name}
          </MDTypography>
        </Link>
      </MDBox>
    ));

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} xl={10}>
        <MDBox
          width="100%"
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
          alignItems={{ xs: "center", sm: "flex-end" }}
          px={1.5}
          pt={3}
          color="text"
        >
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            color="inherit"
            fontSize={size.sm}
            // px={1.5}
          >
            <MDTypography color="inherit" variant="button" fontWeight="medium">
              <Link href={href} target="_blank">
                {name}&nbsp;
              </Link>
            </MDTypography>
            <MDTypography color="inherit" variant="button" fontWeight="light">
              &copy; {new Date().getFullYear()}
            </MDTypography>
          </MDBox>
          <MDBox
            component="ul"
            color="inherit"
            sx={({ breakpoints }) => ({
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
              listStyle: "none",
              // mt: 3,
              // mb: 0,
              // p: 0,

              [breakpoints.up("lg")]: {
                // mt: 3,
              },
            })}
          >
            {renderLinks()}
          </MDBox>
        </MDBox>
      </Grid>
    </Grid>
  );
}

// Setting default values for the props of Footer
Footer.defaultProps = {
  company: { href: "https://hosposure.com.au/", name: "HospoSure" },
  links: [
    { href: "https://hosposure.com.au/about", name: "About Us" },
    { href: "https://hosposure.com.au/privacy", name: "Privacy Policy" },
    { href: "https://hosposure.com.au/terms", name: "Terms & Conditions" },
  ],
};

// Typechecking props for the Footer
Footer.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
};

export default Footer;
