/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props. https://www.npmjs.com/package/prop-types
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// HospoSure
import { venueSettings } from "config";

function VenueSettings({ venue, show, isAdvanced }) {
  const { id, updateVenue } = venue;

  const joint = Object.assign(
    {},
    ...Object.entries(venueSettings).map(([k, v]) => ({
      [k]: { ...v, value: venue[k] || false },
    }))
  );

  return (
    <Grid item xs={10} sm={8} md={6}>
      <Card>
        {Object.entries(joint)
          .filter(([k]) => show.includes(k))
          .map(([k, { ref, pre, post, tip, value }]) => (
            <MDBox p={2} lineHeight={1.25} key={ref}>
              <Grid
                container
                spacing={3}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                {pre && (
                  // <MDBox width="40%" mr={1} textAlign="right">
                  <Grid item xs={pre ? 5 : 0}>
                    <Grid container justifyContent="flex-end">
                      <Grid item>
                        <MDTypography variant="button" color="text">
                          {pre}
                        </MDTypography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={2}>
                  <Tooltip
                    title={!isAdvanced ? tip : ""}
                    placement="top"
                    TransitionComponent={Zoom}
                  >
                    <Switch
                      checked={value}
                      onChange={() => {
                        updateVenue({ id, [k]: !value });
                      }}
                    />
                  </Tooltip>
                </Grid>
                {post && (
                  <Grid item xs={post ? 5 : 0}>
                    <MDTypography variant="button" wrap="true" color="text">
                      {post}
                    </MDTypography>
                  </Grid>
                )}
              </Grid>
            </MDBox>
          ))}
      </Card>
    </Grid>
  );
}
VenueSettings.propTypes = {
  venue: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.func, PropTypes.array])
  ).isRequired,
  show: PropTypes.arrayOf(PropTypes.string).isRequired,
  isAdvanced: PropTypes.bool.isRequired,
};

export default VenueSettings;
