// import { useContext } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// react-router components
import { Link } from "react-router-dom";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// HospoSure
import { venueType } from "config";

function CallToAction({ route, subscribed, admin }) {
  // const { venue, userProfile } = useContext(AppContext);

  return !route.some((a) => ["profile"].includes(a)) && !subscribed && !admin ? (
    <MDBox pr={3}>
      <Link to="/profile/account">
        <MDButton variant="gradient" color="primary">
          Start modelling your {venueType} venue
        </MDButton>
      </Link>
    </MDBox>
  ) : null;
}

export default CallToAction;

// https://www.npmjs.com/package/prop-types
CallToAction.defaultProps = {
  subscribed: false,
  admin: false,
};
CallToAction.propTypes = {
  route: PropTypes.arrayOf(PropTypes.string).isRequired,
  subscribed: PropTypes.bool,
  admin: PropTypes.bool,
};
