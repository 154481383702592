import { useState, useEffect } from "react";

// Material Dashboard 2 PRO React components
import MDAlert from "components/MDAlert";

// Material Dashboard 2 PRO React Base Styles
// import colors from "assets/theme/base/colors";

// HospoSure
import { list } from "database";
import Bonus from "components/Bonus";

// const { success, info, warning, error } = colors;

function useNotices() {
  const type = "Notice";
  // const initialState = [{
  //     id: "",
  //     type: "",
  //     enabled: false,
  //     name: "",
  //     text: "",
  //   }];
  const [notices, setNotices] = useState([]);

  const getNotices = async () => {
    const { dataSet } = await list({ type });
    if (dataSet?.length) {
      setNotices(dataSet);
    }
  };

  // const updateNotice = async ({ id, ...others }) => {
  //   if (id) {
  //     setNotices((a) => a.map((b) => (b.id === id ? { ...b, ...others } : b)));
  //     await update({ entry: { id, ...others }, type });
  //   }
  // };

  const renderType = {
    Global: (text) => <MDAlert color="error">{text}</MDAlert>,
    Subscribe: (text) => <Bonus icon="star" message={text} />,
  };

  const renderNotice = (noticeType) => {
    const { text } = notices.filter((a) => a.type === noticeType && a.enabled)[0] || {};
    return text ? renderType[noticeType](text) : null;
  };

  useEffect(() => {
    getNotices();
  }, []);

  return renderNotice;
}

export default useNotices;
