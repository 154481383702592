import { Amplify } from "aws-amplify";
import {
  Authenticator,
  Image,
  View,
  useTheme,
  CheckboxField,
  useAuthenticator,
} from "@aws-amplify/ui-react"; // https://ui.docs.amplify.aws/components/authenticator?platform=react
// eslint-disable-next-line import/no-unresolved
import awsExports from "aws-exports";
import { GlobalContext } from "GlobalContext";

import logo from "assets/images/logos/hospoSureLogo.png";

// Material Dashboard 2 PRO React Context Provider
import { MaterialUIControllerProvider } from "context";

Amplify.configure(awsExports);

const components = {
  Header() {
    const { tokens } = useTheme();
    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image alt="HospoSure logo" src={logo} />
      </View>
    );
  },
  SignUp: {
    FormFields() {
      const { validationErrors } = useAuthenticator();

      return (
        <>
          <Authenticator.SignUp.FormFields />
          {/* Append & require Terms & Conditions field to sign up  */}
          <div style={{ display: "inline-block" }}>
            <div style={{ float: "left", marginRight: "10px" }}>
              <CheckboxField
                // errorMessage={validationErrors.acknowledgement as string}
                hasError={!!validationErrors.acknowledgement}
                name="acknowledgement"
                value={false}
                // label="I agree with the Terms and Conditions"
              />
            </div>
            <div>
              I agree with the{" "}
              <a href="https://hosposure.com.au/terms.html" target="_blank" rel="noreferrer">
                Terms and Conditions
              </a>{" "}
              and{" "}
              <a href="https://hosposure.com.au/privacy.html" target="_blank" rel="noreferrer">
                Privacy Policy
              </a>
              .
            </div>
          </div>
        </>
      );
    },
  },
};

const services = {
  // eslint-disable-next-line consistent-return
  async validateCustomSignUp(formData) {
    return (
      !formData.acknowledgement && {
        acknowledgement: "You must agree to the Terms & Conditions",
      }
    );
  },
};

const formFields = {
  signUp: {
    name: {
      labelHidden: true,
      placeholder: "Your name",
      isRequired: true,
      // label: "Your name",
    },
  },
};

export default function Authenticate() {
  return (
    // <Authenticator
    //   initialState="signIn"
    //   components={components}
    //   services={services}
    //   formFields={formFields}
    // >
    <Authenticator
      {...{
        initialState: "signIn",
        components,
        services,
        formFields,
      }}
    >
      {({ user, signOut }) => (
        <MaterialUIControllerProvider>
          <GlobalContext {...{ user, signOut }} />
        </MaterialUIControllerProvider>
      )}
    </Authenticator>
  );
}
