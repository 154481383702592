import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props https://www.npmjs.com/package/prop-types
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
// import MDButton from "components/MDButton";

// HospoSure
import { config, loading } from "config";
import { create, get, list, update, search, del } from "database";
import { format, setDerivedKeys, _ } from "general";
import useLookups from "hooks/useLookups"; // assignLookups
// import { button } from "aws-amplify";

const { derived } = format;

function useItems({ userGroup, maxItems, userProfile, pageCfg, itemId, refresh }) {
  const [soloItem, setSoloItem] = useState({});
  const [items, setItems] = useState([]);
  // const [allItems, setAllItems] = useState([]);
  const [load, setLoad] = useState(loading.start);
  // const [itemsFetched, setItemsFetched] = useState(false);
  // const [nextToken, setNextToken] = useState();
  // const [pages, setPages] = useState(0);
  // const [pageIndex, setPageIndex] = useState(0);
  const { pageSettings, updatePageSettings } = userProfile;
  const { lookups } = useLookups({ pageCfg, userGroup, refresh, setLoad });
  const [newTemplate, setNewTemplate] = useState({});
  const [clientSort, setClientSort] = useState({
    key: "",
    ascending: true,
  });
  // const [entriesStart, setEntriesStart] = useState(0);
  // const [entriesEnd, setEntriesEnd] = useState(0);
  // const [itemTotal, setItemTotal] = useState(0);
  const [entries, setEntries] = useState({ start: 0, end: 0, total: 0 });
  const [pages, setPages] = useState({ index: 0, total: 0 });
  const { primaryKey, admin, hasMany, autoCreate, singleItem, deleteControl, deleteAdditional } =
    pageCfg;
  const hasManyCfg = hasMany && config.filter((a) => a.type === hasMany.type)[0];
  const { title, keys, type, filterKey } = hasManyCfg || pageCfg;
  // const keys = { ...keysX, ...keyMetricsKeys };
  // const soloKeys = singleItem && hasMany && { ...pageCfg.keys, ...pageCfg.keyMetricsKeys };
  const { sort, filter, showFilter } = JSON.parse(pageSettings)[type] || {};

  const addItemState = (item) =>
    singleItem
      ? setSoloItem(setDerivedKeys({ item, keys, lookups }))
      : setItems((existing) => [...existing, setDerivedKeys({ item, keys, lookups })]);

  async function createItem({ event, item, postNotification }) {
    let notification;
    if (event) event.preventDefault();
    // check duplicate
    if (
      !primaryKey ||
      // (items && items.length === 0) ||
      (primaryKey && items.filter((i) => i[primaryKey] === item[primaryKey]).length === 0)
    ) {
      try {
        // eslint-disable-next-line no-param-reassign
        if (admin) delete item.userGroup; // remove userGroup if page is admin
        const createdItem = await create({ entry: _.trim({ item, keys }), type });
        addItemState(createdItem);
        // setEntriesEnd((a) => a + 1);
        // setItemTotal((a) => a + 1);
        setEntries((a) => ({ ...a, end: a.end + 1, total: a.total + 1 }));
        notification = {
          level: "success",
          title: "Add",
          content: `${item[primaryKey]} added to ${title}.`,
        };
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log({ err }, { item });
        // userWarning(`Unable to add as ${primaryKey.replace(/Id$/, "")} is missing information.`);
        notification = {
          level: "warning",
          title: "Unable to add",
          content: `${item[primaryKey]} is missing information.`,
        };
      }
    } else {
      notification = {
        level: "error",
        title: "Unable to add",
        content: `"${item[primaryKey]}" already exists in ${title}.`,
      };
      // userWarning(`Unable to add as ${primaryKey.replace(/Id$/, "")} already exists.`);
    }
    if (postNotification) postNotification(notification);
  }

  async function listItems() {
    let isCancelled = false;
    // setLoad(loading.listItems);
    const { dataSet } = await list({ type, userGroup });
    if (!isCancelled && dataSet) {
      // const dataSetA = dataSet; // .map((item) => removeMetaData(item));
      // .map((item) => assignLookups({ item, initialCreateState, keys, lookups }));
      if (singleItem && dataSet[0]) {
        setSoloItem(dataSet[0]);
      } else {
        setItems(dataSet.sort((a, b) => a.sort - b.sort));
      }
      setLoad(loading.itemsFetched);
    }
    return () => {
      isCancelled = true;
    };
  }

  const buildFilter = (input, many) => {
    const filterX = [];
    if (userGroup) filterX.push({ userGroup: { eq: userGroup } });
    if (showFilter && input)
      filterX.push(
        ...Object.entries(input)
          .filter(([k, v]) => keys[k].keyType.display !== "static" && !["", 0].includes(v))
          .map(([k, v]) => {
            const filType = keys[k].keyType.filter;
            return {
              [k]: { [filType]: filType === "wildcard" ? `*${v}*` : v },
            };
          })
      );
    if (many)
      filterX.push(
        ...Object.entries(many).map(([k, v]) => ({
          [k]: { eq: v },
        }))
      );
    let output = {};
    if (_.exists(filterX)) output = filterX.shift();
    if (_.exists(filterX)) output = { ...output, and: filterX };
    return output;
  };

  async function loadMoreItems({ itemType, setting, manyFilter, from }) {
    // token,
    let isCancelled = false;
    // let totalLoaded;
    if (!isCancelled) setLoad(loading.lookupsFetched);
    // setButtonText("Loading items...");
    // const from = token ? 0 : Math.max(index - maxItems, 0);
    // setIndex((a) => token ? a + maxItems
    const { dataSet, total } = await search({
      // newNextToken,
      type: itemType,
      // sortKey: sortType === "select" ? lookupType : sortKey, // sorting on select fields not working as field cannot be lookedup at DB
      sort: (setting && setting.sort) || (hasMany ? null : sort),
      filter: buildFilter((setting && setting.filter) || filter, manyFilter),
      from,
      // nextToken: token,
      maxItems,
    });

    if (!isCancelled) {
      // if (newNextToken) setButtonText("Load more...");
      if (_.exists(dataSet)) {
        if (singleItem && !manyFilter) {
          setSoloItem(dataSet[0]);
        } else {
          const newItems = dataSet.map((item) => setDerivedKeys({ item, keys, lookups }));
          // setAllItems((existing) => {
          //   const newAllItems = token && allItems ? [...existing, ...newItems] : newItems;
          //   totalLoaded = newAllItems.length;
          //   return hasMany ? newAllItems.sort((a, b) => a.sort - b.sort) : newAllItems;
          // });
          setItems(newItems);
          const pageCount = Math.floor(total / maxItems);
          setPages((a) => ({ ...a, total: pageCount }));

          // setEntriesStart((from || 0) + (newItems.length > 0 || total > 0 ? 1 : 0));
          // setEntriesEnd(
          //   pageIndex === pageCount ? total : (from || 0) + (newItems.length || maxItems)
          // );
          setEntries((a) => ({
            ...a,
            start: (from || 0) + (newItems.length > 0 || total > 0 ? 1 : 0),
            end: pages.index === pageCount ? total : (from || 0) + (newItems.length || maxItems),
            total,
          }));
        }
      } else {
        // setAllItems([]);
        setItems([]);
        // setEntriesStart(0);
        // setEntriesEnd(0);
        setEntries((a) => ({
          ...a,
          start: 0,
          end: 0,
          total: 0,
        }));
      }
      // setNextToken(totalLoaded < total ? newNextToken : null);
      setLoad(loading.itemsFetched);
      // setItemTotal(total);
    }

    return () => {
      isCancelled = true;
    };
  }

  const changePage = {
    next: () => {
      const newPageIndex = pages.index + 1;
      setPages((a) => ({ ...a, index: newPageIndex }));
      setItems([]);
      loadMoreItems({ itemType: type, from: newPageIndex * maxItems });
    },
    previous: () => {
      const newPageIndex = pages.index - 1;
      setPages((a) => ({ ...a, index: newPageIndex }));
      setItems([]);
      loadMoreItems({ itemType: type, from: newPageIndex * maxItems });
    },
    goto: (newPageIndex) => {
      setPages((a) => ({ ...a, index: newPageIndex }));
      setItems([]);
      loadMoreItems({ itemType: type, from: newPageIndex * maxItems });
    },
  };

  // function NextButton() {
  // return nextToken && load === loading.itemsFetched ? (
  //   <tfoot>
  //     <tr>
  //       <td>
  //         <MDButton
  //           variant="gradient"
  //           color="secondary"
  //           type="button"
  //           onClick={() => loadMoreItems({ itemType: type, token: nextToken })}
  //         >
  //           Next page...
  //         </MDButton>
  //       </td>
  //     </tr>
  //   </tfoot>
  // ) : null;
  // }

  async function getItem() {
    let isCancelled = false;
    const { data } = await get({ id: itemId, type: pageCfg.type });
    if (!isCancelled && data) {
      setSoloItem(
        setDerivedKeys({
          item: data,
          keys: { ...pageCfg.keys, ...pageCfg.keyMetricsKeys },
          lookups,
          items,
        })
      );
      setLoad(loading.soloItemFetched);
    }
    return () => {
      isCancelled = true;
    };
  }

  async function updateItem({ item, isHasMany }) {
    let isCancelled = false;
    const index = items.indexOf(items.find((a) => a.id === item.id));
    const updatedItems = [...items];
    const entry = _.trim({ item, keys: isHasMany ? pageCfg.keys : keys });
    const updatedItem = await update({
      entry,
      type: isHasMany ? pageCfg.type : type,
    });
    updatedItems[index] = setDerivedKeys({
      item: updatedItem,
      keys: isHasMany ? pageCfg.keys : keys,
      lookups,
    });
    const updatedSoloItem =
      hasMany &&
      setDerivedKeys({
        item: isHasMany ? item : soloItem,
        keys: { ...pageCfg.keys, ...pageCfg.keyMetricsKeys },
        lookups,
        items: updatedItems,
      });

    if (!isCancelled) {
      setItems(updatedItems);
      if (hasMany) setSoloItem(updatedSoloItem);
    }
    return () => {
      isCancelled = true;
    };
  }

  const checkExists = async ({ id, checkType, checkIdKey, action }) => {
    const checkFilter = {
      userGroup: { eq: userGroup },
      and: { [checkIdKey]: { eq: id } },
    };
    const { total } = await search({ type: checkType, filter: checkFilter });
    return total > 0 ? action : null;
  };

  const deleteAdditionalItems = async ({ id, checkType, checkIdKey }) => {
    const checkFilter = {
      userGroup: { eq: userGroup },
      and: { [checkIdKey]: { eq: id } },
    };
    const { dataSet } = await search({ type: checkType, filter: checkFilter, all: true });
    await Promise.all(dataSet.map((a) => del({ id: a.id, type: checkType })));
  };

  async function deleteItem({ item, postNotification }) {
    let isCancelled = false;
    let notification;
    // if (confirmed) {
    const { id } = item;

    // let preventDelete = "";
    if (deleteControl) {
      const actions = (
        await Promise.all(
          deleteControl.map((a) =>
            checkExists({ id, checkType: a.type, checkIdKey: a.idKey, action: a.action })
          )
        )
      ).filter((a) => a);
      if (_.exists(actions))
        notification = {
          level: "warning",
          title: "Unable to delete",
          content: `Please ${new Intl.ListFormat().format(actions)} before deleting ${item[
            primaryKey
          ].trim()}.`,
        };
    }
    if (!_.exists(notification)) {
      if (deleteAdditional) {
        await Promise.all(
          deleteAdditional.map((a) =>
            deleteAdditionalItems({ id, checkType: a.type, checkIdKey: a.idKey })
          )
        );
      }
      await del({ id, type });
      if (!isCancelled) setItems(items.filter((a) => a.id !== id));
      setEntries((a) => ({ ...a, end: a.end - 1, total: a.total - 1 }));
      notification = {
        level: "error",
        title: "Delete",
        content: `${item[primaryKey].trim()} deleted from ${title}.`,
      };
    }
    // } else {
    //   notification = {
    //     level: "warning",
    //     title: "Delete",
    //     content: "Hold shift key and click to confirm deletion.",
    //   };
    // }
    postNotification(notification);

    return () => {
      isCancelled = true;
    };
  }

  function clientSortItems({ key }) {
    const asc = key === clientSort.key ? !clientSort.ascending : true;
    setClientSort({ key, ascending: asc });
    const { sort: sortFx } = keys[key]?.dataType || keys[key]?.keyType || { sort: null };
    if (sortFx) {
      const sortedItems = items.sort((a, b) =>
        asc ? sortFx(a[key], b[key]) : sortFx(b[key], a[key])
      );
      sortedItems.map((a, i) => updateItem({ item: { ...a, sort: i } }));
    }
  }

  const setSort = ({ key }) => {
    // if (admin || hasMany) {
    //   clientSortItems({ key });
    // } else {
    //   setItems([]);
    //   // setNextToken(null);
    //   const previous = JSON.parse(pageSettings)[type];
    //   const setting = {
    //     sort: {
    //       key,
    //       ascending: previous.sort.key === key ? !previous.sort.ascending : true,
    //     },
    //   };
    //   loadMoreItems({ itemType: type, setting });
    //   updatePageSettings({ pageType: type, setting });
    // }
    switch (true) {
      case admin || hasMany: {
        clientSortItems({ key });
        break;
      }
      case keys[key].keyType !== derived: {
        setItems([]);
        // setNextToken(null);
        const previous = JSON.parse(pageSettings)[type];
        const setting = {
          sort: {
            key,
            ascending: previous?.sort?.key === key ? !previous.sort.ascending : true,
          },
        };
        loadMoreItems({ itemType: type, setting });
        updatePageSettings({ pageType: type, setting });
        break;
      }
      default: {
        break;
      }
    }
  };

  function updateFilter({ item }) {
    // if (event) event.preventDefault();
    const setting = { filter: item };
    loadMoreItems({ itemType: type, setting });
    updatePageSettings({ pageType: type, setting });
  }

  useEffect(() => {
    setItems([]);
    setLoad(loading.start);
  }, [userGroup]);

  useEffect(() => {
    // determine data load method
    if (admin && type) {
      listItems();
    } else if (load >= loading.lookupsFetched) {
      loadMoreItems({
        itemType: type,
        manyFilter: itemId && hasMany ? { [filterKey]: itemId } : null,
      });
    }
  }, [load >= loading.lookupsFetched]);

  useEffect(() => {
    if (!showFilter && load >= loading.itemsFetched) {
      setItems([]);
      setLoad(loading.lookupsFetched);
      loadMoreItems({
        itemType: type,
        manyFilter: itemId && hasMany ? { [filterKey]: itemId } : null,
      });
    }
  }, [showFilter]);

  useEffect(() => {
    if (itemId && load >= loading.itemsFetched) getItem();
    // build template for newItem and create if autoCreate
    let isCancelled = false;
    if (load >= loading.itemsFetched) {
      const template = {
        filter: Object.assign(
          {},
          ...Object.entries(keys).map(([k, v]) => ({
            [k]: v.keyType.initial(0),
          }))
        ),
        item: Object.assign(
          {},
          ...Object.entries(keys).map(([k, v]) => ({
            [k]: v.keyType.initial(
              // v.keyType.display === "select" && _.exists(lookups[v.type]) && lookups[v.type][0].id
              v.keyType.display === "select" && lookups?.[v.type]?.[0].id
            ),
          })),
          { userGroup }
        ),
      };
      if (!isCancelled) {
        setNewTemplate(template);
      }
      if (singleItem && autoCreate && !_.exists(soloItem)) createItem({ item: template.item });
    }

    return () => {
      isCancelled = true;
    };
  }, [load >= loading.itemsFetched]);

  return {
    Items: {
      createItem,
      updateItem,
      deleteItem,
      soloItem,
      items,
      newTemplate,
      lookups,
      load,
      setSort,
      updateFilter,
      entries,
      pages,
      changePage,
    },
  };
}
useItems.defaultProps = {
  maxItems: 100,
  itemId: null,
  // m2m: false,
  refresh: false,
};
useItems.propTypes = {
  userGroup: PropTypes.string.isRequired,
  userProfile: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.bool, PropTypes.func, PropTypes.string])
  ).isRequired,
  // venue: PropTypes.string.isRequired,
  maxItems: PropTypes.number,
  // pageSettings: PropTypes.objectOf(PropTypes.object).isRequired,
  // updateUserProfile: PropTypes.func.isRequired,
  pageCfg: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func])
  ).isRequired,
  itemId: PropTypes.string,
  // m2m: PropTypes.bool,
  refresh: PropTypes.bool,
};

export default useItems;
