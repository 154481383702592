const getSubDomain = () => {
  const a = document.createElement("a");
  a.setAttribute("href", window.location.href);
  return a.hostname.split(".")[0];
};

// environment
const all = {
  // region: "ap-southeast-2",
  // demoVenues: ["135b82d6-a833-41bb-892c-f2d84c71030a"],
  // templateGroups: ["Template"],
  locale: "en-AU",
  dateFormat: {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  },
};

const env = {
  app: {
    env: "prod",
    portalLink: "https://billing.stripe.com/p/login/4gwaFC1UScKOcM03cc",
    pricing: [
      {
        currency: "$",
        value: 299,
        period: "3 Months",
        pricePerMonth: 99.67,
        paymentLink: "https://buy.stripe.com/bIY28t6YiaA21Xi7st",
      },
      {
        currency: "$",
        value: 499,
        period: "6 Months",
        pricePerMonth: 83.17,
        saving: 16.56,
        paymentLink: "https://buy.stripe.com/eVa5kFciCfUm0Te146",
      },
      {
        currency: "$",
        value: 899,
        period: "12 Months",
        pricePerMonth: 74.92,
        saving: 24.83,
        preferred: true,
        paymentLink: "https://buy.stripe.com/dR6aEZbey8rU45q8wz",
      },
    ],
    ...all,
  },
  test: {
    env: "staging",
    portalLink: "https://billing.stripe.com/p/login/test_8wMbM77T30vHaDCaEE",
    pricing: [
      {
        currency: "$",
        value: 3,
        period: "1 Day",
        pricePerMonth: 91.25,
        paymentLink: "https://buy.stripe.com/test_14k2avcwI85r8qA7sw",
      },
      {
        currency: "$",
        value: 5,
        period: "2 Days",
        pricePerMonth: 76.04,
        saving: 16.66,
        paymentLink: "https://buy.stripe.com/test_dR64iDeEQ5XjbCMbIN",
        // paymentLink: "https://buy.stripe.com/test_dR6eXhaoA0CZfT2fZ0", // 6 months
      },
      {
        currency: "$",
        value: 7,
        period: "3 Days",
        pricePerMonth: 70.97,
        saving: 22.22,
        preferred: true,
        paymentLink: "https://buy.stripe.com/test_3cs4iD9kw5XjgX6cMS",
      },
    ],
    ...all,
  },
  localhost: {
    env: "development",
    portalLink: "https://billing.stripe.com/p/login/test_8wMbM77T30vHaDCaEE",
    pricing: [
      {
        currency: "$",
        value: 3,
        period: "1 Day",
        pricePerMonth: 91.25,
        paymentLink: "https://buy.stripe.com/test_14k2avcwI85r8qA7sw",
      },
      {
        currency: "$",
        value: 5,
        period: "2 Days",
        pricePerMonth: 76.04,
        saving: 16.66,
        paymentLink: "https://buy.stripe.com/test_dR64iDeEQ5XjbCMbIN",
        // paymentLink: "https://buy.stripe.com/test_dR6eXhaoA0CZfT2fZ0", // 6 months
      },
      {
        currency: "$",
        value: 7,
        period: "3 Days",
        pricePerMonth: 70.97,
        saving: 22.22,
        preferred: true,
        paymentLink: "https://buy.stripe.com/test_3cs4iD9kw5XjgX6cMS",
      },
    ],
    ...all,
  },
};

export default env[getSubDomain()];
