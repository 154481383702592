/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// Material Dashboard 2 PRO React components
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 PRO React Base Styles
// import colors from "assets/theme/base/colors";

// const { success, info, warning, error } = colors;

function useNotifications() {
  const [notificationSB, setNotificationSB] = useState(false);
  const [notification, setNotification] = useState({});

  const openNotificationSB = () => setNotificationSB(true);
  const closeNotificationSB = () => setNotificationSB(false);

  // const levels = new Set(["success", "info", "warning", "error"]);

  function postNotification({ level, title, content }) {
    const now = new Date().toLocaleTimeString();
    const notify = { title, content, dateTime: now };
    switch (level) {
      case "success":
        setNotification({
          ...notify,
          color: "success",
          icon: "check",
        });
        break;
      case "info":
        setNotification({
          ...notify,
          color: "info",
          icon: "notifications",
        });
        break;
      case "warning":
        setNotification({
          ...notify,
          color: "warning",
          icon: "star",
        });
        break;
      case "error":
        setNotification({
          ...notify,
          color: "error",
          icon: "warning",
        });
        break;
      default:
        setNotification({
          title: "Error",
          content: "Incorrect level set for notification",
          dateTime: now,
          color: "error",
          icon: "warning",
        });
    }
    openNotificationSB();
  }

  const renderNotificationSB = notificationSB && (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notificationSB}
      onClose={closeNotificationSB}
      close={closeNotificationSB}
      bgWhite={notification.bgWhite}
    />
  );

  return { postNotification, renderNotificationSB };
}

export default useNotifications;
