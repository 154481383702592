import { useEffect, useState } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

import { config } from "config";
import { search } from "database";
import { _ } from "general";

function useProgress({ userGroup, openForBusiness }) {
  const pages = config.filter((a) => a.progressComplete);
  const [progress, setProgress] = useState([]);

  const getCount = async ({ type, singleItem }) => {
    const { dataSet, total } = await search({
      type,
      filter: { userGroup: { eq: userGroup } },
      all: true,
    });
    return singleItem && _.exists(dataSet)
      ? Object.entries(dataSet[0]).filter(
          ([k, v]) =>
            k in pages.filter((a) => a.type === type)[0].keys &&
            !["", 0, null, undefined].includes(v)
        ).length
      : total;
  };

  // async function getProgress() {
  //   let isCancelled = false;
  //   const entryCounts = await Promise.all(pages.map(async (a) => getCount(a)));

  //   const progressData = pages.map((a, i) => {
  //     const {
  //       icon,
  //       title,
  //       description,
  //       type,
  //       singleItem,
  //       progressComplete,
  //       band,
  //       action: { open, startup },
  //     } = a;
  //     return {
  //       icon,
  //       title,
  //       description: `${description} ${openForBusiness ? open : startup}`,
  //       dateTime: `${entryCounts[i]} ${type.toLowerCase()}${singleItem ? " detail" : ""}${
  //         entryCounts[i] !== 1 ? "s" : ""
  //       } ${
  //         entryCounts[i] >= progressComplete
  //           ? "entered, good job."
  //           : `so far, aim for ${singleItem ? "all" : "at least"} ${progressComplete}.`
  //       }`,
  //       color: entryCounts[i] >= progressComplete ? "success" : "secondary",
  //       band,
  //       // lastItem: ["Operation", "Ingredients"].includes(title),
  //     };
  //   });

  //   if (!isCancelled) setProgress(progressData);

  //   return () => {
  //     isCancelled = true;
  //   };
  // }

  useEffect(() => {
    (async () => {
      let isCancelled = false;
      if (_.exists(userGroup)) {
        const entryCounts = await Promise.all(pages.map(async (a) => getCount(a)));
        const progressData = pages.map(
          (
            {
              icon,
              title,
              description,
              type,
              singleItem,
              progressComplete,
              band,
              action: { open, startup },
            },
            i
          ) => ({
            icon,
            title,
            description: `${description} ${openForBusiness ? open : startup}`,
            dateTime: `${entryCounts[i]} ${type.toLowerCase()}${singleItem ? " detail" : ""}${
              entryCounts[i] !== 1 ? "s" : ""
            } ${
              entryCounts[i] >= progressComplete
                ? "entered, good job."
                : `so far, aim for ${singleItem ? "all" : "at least"} ${progressComplete}.`
            }`,
            color: entryCounts[i] >= progressComplete ? "success" : "secondary",
            band,
            // lastItem: ["Operation", "Ingredients"].includes(title),
          })
        );

        if (!isCancelled) setProgress(progressData);
      }

      return () => {
        isCancelled = true;
      };
    })();
  }, [userGroup, openForBusiness]);

  return { progress };
}

// https://www.npmjs.com/package/prop-types
useProgress.propTypes = {
  venue: PropTypes.string.isRequired,
  openForBusiness: PropTypes.bool.isRequired,
};

export default useProgress;
