// prop-types is a library for typechecking of props. https://www.npmjs.com/package/prop-types
import PropTypes from "prop-types";

import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

// @mui icons https://fonts.google.com/icons
import Icon from "@mui/material/Icon";

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Popper from "@mui/material/Popper";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

// HospoSure
import { search, create } from "database";
import { _ } from "general";

const link = "/build/recipeAnalysis";

const styles = () => ({
  popper: { width: "fit-content" },
});
function PopperMy(props) {
  return <Popper {...props} style={styles.popper} placement="bottom-start" />;
}

async function addIngredientToRecipe({
  ingredientId,
  ingredient,
  recipeId,
  recipe,
  userGroup,
  postNotification,
}) {
  const createdItem = await create({
    entry: { ingredientId, recipeId, quantity: 1, userGroup },
    type: "RecipeIngredient",
  });
  // message.setAlert(`${ingredient} added to ${recipe}`);
  const notification = Object.keys(createdItem).length
    ? {
        level: "success",
        title: "Add Ingredient to Recipe",
        content: `${ingredient} added to ${recipe}`,
      }
    : {
        level: "error",
        title: "Add Ingredient to Recipe",
        content: `Failed to add ${ingredient} to ${recipe}`,
      };
  postNotification(notification);
  return createdItem;
}

function RecipeSelector({ userGroup, selectedRecipe, updatePageSettings, isAdvanced }) {
  const [recipes, setRecipes] = useState([]);

  const handleSetRecipe = (e) => {
    const newRecipe = recipes.filter((a) => a.recipe === e.target.textContent)[0];
    updatePageSettings({ pageType: "Ingredient", setting: { selectedRecipe: newRecipe } });
  };

  useEffect(() => {
    (async () => {
      if (_.exists(userGroup)) {
        const { dataSet } = await search({
          type: "Recipe",
          filter: { userGroup: { eq: userGroup } },
          sort: { key: "recipe", ascending: true },
          all: true,
        });
        setRecipes(dataSet);
        if (
          // dataSet[0] &&
          // (!selectedRecipe || !dataSet.map((a) => a.id).includes(selectedRecipe.id))
          !dataSet[0] ||
          !dataSet.map((a) => a?.id).includes(selectedRecipe?.id)
        )
          updatePageSettings({
            pageType: "Ingredient",
            setting: { selectedRecipe: dataSet[0] || undefined },
          });
      }
    })();
  }, [userGroup]);

  return _.exists(recipes) &&
    // selectedRecipe &&
    recipes.map((a) => a.id).includes(selectedRecipe?.id) ? (
    <Grid item xs={12} sm={10} md={8} lg={6}>
      <Card>
        <MDBox px={2} py={1.5}>
          <Grid container spacing={1}>
            <Grid item xs={5}>
              <MDTypography variant="caption" fontWeight="regular" color="text">
                Add Ingredient to Recipe
              </MDTypography>
            </Grid>
            <Grid item xs={5}>
              {recipes.length > 1 ? (
                <MDBox color="inherit">
                  <Autocomplete
                    value={selectedRecipe?.recipe}
                    options={recipes.map((a) => a.recipe)}
                    renderInput={(params) => <MDInput {...params} variant="standard" />}
                    onChange={(e) => handleSetRecipe(e)}
                    disableClearable
                    // sx={{ width: 180 }}
                    PopperComponent={PopperMy}
                  />
                </MDBox>
              ) : (
                <MDBox color="inherit">
                  <MDTypography variant="button" fontWeight="regular">
                    {selectedRecipe?.recipe}
                  </MDTypography>
                </MDBox>
              )}
            </Grid>
            <Grid item xs={2}>
              <MDBox align="right">
                <NavLink to={link} state={{ itemId: selectedRecipe?.id }}>
                  {/* key={key} sx={{ textDecoration: "none" }} */}
                  <Tooltip
                    title={!isAdvanced ? `View ${selectedRecipe?.recipe.trim()}` : ""}
                    placement="top"
                  >
                    <MDButton
                      variant="gradient"
                      color="secondary"
                      iconOnly
                      type="button"
                      // title={`View ${selectedRecipe.recipe.trim()}`}
                    >
                      <Icon fontSize="large">visibility</Icon>
                    </MDButton>
                  </Tooltip>
                </NavLink>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </Grid>
  ) : null;
}
RecipeSelector.defaultProps = {
  userGroup: "",
  selectedRecipe: null,
  isAdvanced: false,
};
RecipeSelector.propTypes = {
  userGroup: PropTypes.string,
  selectedRecipe: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number])
  ),
  updatePageSettings: PropTypes.func.isRequired,
  isAdvanced: PropTypes.bool,
};

export { RecipeSelector, addIngredientToRecipe };
