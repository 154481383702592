/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React contexts
// import { useMaterialUIController } from "context";

function DefaultStatisticsCard({ title, value, label, warning }) {
  // dropdown
  // const [controller] = useMaterialUIController();
  // const { darkMode } = controller;
  const { lower, limit, comment } = warning;

  return (
    <Card>
      <MDBox p={2}>
        <Grid container>
          <Grid item xs={12}>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems={{ xs: "flex-start" }}
              flexDirection={{ xs: "col", sm: "row" }}
            >
              <MDBox
              // display="flex"
              // justifyContent="space-between"
              // alignItems={{ xs: "flex-start" }}
              // flexDirection={{ xs: "col", sm: "row" }}
              >
                <MDBox mb={0.5} lineHeight={1}>
                  <MDTypography
                    // variant="button"
                    // fontWeight="regular"
                    color="text"
                    variant="h6"
                    // textTransform="capitalize"
                  >
                    {title}
                  </MDTypography>
                </MDBox>
                <MDBox lineHeight={1}>
                  <MDTypography variant="h5">{label}</MDTypography>
                  {/* {warning && (lower ? value <= limit : value >= limit) ? (
                    <MDTypography variant="button" fontWeight="bold" color="error">
                      {comment}
                    </MDTypography>
                  ) : null} */}
                </MDBox>
              </MDBox>
              {warning && (lower ? value <= limit : value >= limit) ? (
                <MDBox justifyContent="flex-end">
                  <MDTypography variant="button" fontWeight="regular" color="error">
                    <Tooltip title={comment} placement="top" TransitionComponent={Zoom}>
                      <Icon fontSize="large">warning</Icon>
                    </Tooltip>
                  </MDTypography>
                </MDBox>
              ) : null}
            </MDBox>
          </Grid>
          {/* <Grid item xs={5}>
            {dropdown && (
              <MDBox width="100%" textAlign="right" lineHeight={1}>
                <MDTypography
                  variant="caption"
                  color="secondary"
                  fontWeight="regular"
                  sx={{ cursor: "pointer" }}
                  onClick={dropdown.action}
                >
                  {dropdown.value}
                </MDTypography>
                {dropdown.menu}
              </MDBox>
            )}
          </Grid> */}
        </Grid>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of DefaultStatisticsCard
DefaultStatisticsCard.defaultProps = {
  warning: {
    // color: "success",
    lower: false,
    limit: undefined,
    comemnt: undefined,
  },
  value: null,
  // dropdown: false,
};

// Typechecking props for the DefaultStatisticsCard
DefaultStatisticsCard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  warning: PropTypes.shape({
    // color: PropTypes.oneOf([
    //   "primary",
    //   "secondary",
    //   "info",
    //   "success",
    //   "warning",
    //   "error",
    //   "dark",
    //   "white",
    // ]),
    lower: PropTypes.bool,
    limit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    comment: PropTypes.string,
  }),
  // dropdown: PropTypes.oneOfType([
  //   PropTypes.bool,
  //   PropTypes.shape({
  //     action: PropTypes.func,
  //     menu: PropTypes.node,
  //     value: PropTypes.string,
  //   }),
  // ]),
};

export default DefaultStatisticsCard;
