/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

export default function PricingCard({ color, price, specifications, action, shadow }) {
  const { currency, value, period, pricePerMonth, saving, preferred } = price;
  const renderSpecifications = specifications?.map(({ label, includes }) => (
    <MDBox key={label} display="flex" alignItems="center" p={1}>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="1.5rem"
        height="1.5rem"
        mr={2}
        mt={-0.125}
      >
        <MDTypography
          variant="body1"
          color={color === "white" ? "text" : "white"}
          sx={{ lineHeight: 0 }}
        >
          <Icon>{includes ? "done" : "remove"}</Icon>
        </MDTypography>
      </MDBox>
      <MDTypography
        variant="body2"
        color={color === "white" ? "text" : "white"}
        fontWeight="regular"
      >
        {label}
      </MDTypography>
    </MDBox>
  ));

  return (
    <Card sx={{ boxShadow: ({ boxShadows: { lg } }) => (shadow ? lg : "none") }}>
      <MDBox
        bgColor={color === "light" ? "white" : color}
        variant={color === "white" ? "contained" : "gradient"}
        borderRadius="xl"
      >
        <MDBox p={2} textAlign="left" width="100%">
          <Grid container>
            <Grid item xs={8}>
              <MDBox>
                <MDTypography
                  // display="inline"
                  variant="h5"
                  color={color === "light" ? "dark" : "white"}
                >
                  {period}
                </MDTypography>
              </MDBox>
            </Grid>
            {saving ? (
              <Grid item xs={4}>
                <MDBox
                  // display="inline"
                  columns={{ xs: 4 }}
                  bgColor={preferred ? "warning" : color}
                  width="max-content"
                  px={1}
                  // pt={0}
                  pb={0.5}
                  // mr={2}
                  // mt={-1.375}
                  borderRadius="section"
                  lineHeight={1}
                >
                  <MDTypography
                    variant="caption"
                    fontWeight="medium"
                    color={preferred || color === "dark" ? "white" : "dark"}
                  >
                    Save {saving}%
                  </MDTypography>
                </MDBox>
              </Grid>
            ) : null}
          </Grid>
        </MDBox>

        <MDBox pl={2} pr={1}>
          <hr />
        </MDBox>
        <MDBox py={1} px={2} textAlign="left">
          <MDBox my={1}>
            <MDTypography variant="h1" color={color === "light" ? "dark" : "white"}>
              <MDTypography
                display="inline"
                component="small"
                variant="h5"
                color="inherit"
                verticalAlign="top"
              >
                {currency}
              </MDTypography>
              {value}
              <MDTypography display="inline" component="small" variant="body2" color="inherit">
                &nbsp; / {period.toLowerCase()}
              </MDTypography>
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox pb={2} px={2} textAlign="left">
          <MDBox>
            <MDTypography variant="body2" color={color === "light" ? "dark" : "white"}>
              Equivalent to ${pricePerMonth} per month
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox pb={1} px={3}>
          {renderSpecifications}
          <MDBox mt={3}>
            <MDButton
              component={Link}
              to={action.route}
              variant="gradient"
              color={action.color}
              fullWidth
            >
              {action.label} {period}
              {/* <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon> */}
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default props for the DefaultPricingCard
PricingCard.defaultProps = {
  color: "white",
  shadow: true,
  specifications: [],
};

// Typechecking props for the DefaultPricingCard
PricingCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
    "white",
  ]),
  // badge: PropTypes.shape({
  //   color: PropTypes.oneOf([
  //     "primary",
  //     "secondary",
  //     "info",
  //     "success",
  //     "warning",
  //     "error",
  //     "light",
  //     "dark",
  //   ]).isRequired,
  //   label: PropTypes.string.isRequired,
  // }).isRequired,
  // price: PropTypes.shape({
  //   currency: PropTypes.string.isRequired,
  //   value: PropTypes.number.isRequired,
  //   period: PropTypes.string.isRequired,
  //   pricePerMonth: PropTypes.number.isRequired,
  //   saving: PropTypes.number.isRequired,
  // }).isRequired,
  price: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
  ).isRequired,
  specifications: PropTypes.arrayOf(
    PropTypes.objectOf({ label: PropTypes.string.isRequired, includes: PropTypes.bool.isRequired })
  ),
  action: PropTypes.shape({
    // type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]).isRequired,
  }).isRequired,
  shadow: PropTypes.bool,
};
