/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const searchUserProfiles = /* GraphQL */ `
  query SearchUserProfiles(
    $filter: SearchableUserProfileFilterInput
    $sort: [SearchableUserProfileSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableUserProfileAggregationInput]
  ) {
    searchUserProfiles(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        owner
        name
        email
        isDarkMode
        isAdvanced
        isFixedNavbar
        selectedVenueId
        selectedVenue {
          id
          userGroup
          operation
          revenue_stage1
          dishesSold_stage1
          openHours
          periodId
          period {
            id
            sort
            period
            perYear
            createdAt
            updatedAt
            __typename
          }
          salesTaxRate
          prepRoleId
          prepRole {
            id
            userGroup
            role
            areaId
            teamMembers
            amount
            hours
            amountPeriodId
            createdAt
            updatedAt
            __typename
          }
          serviceRoleId
          serviceRole {
            id
            userGroup
            role
            areaId
            teamMembers
            amount
            hours
            amountPeriodId
            createdAt
            updatedAt
            __typename
          }
          openForBusiness
          dashboardBuild
          readOnly
          mainUser
          subscribed
          membershipId
          membership {
            id
            createdAt
            updatedAt
            ownerId
            subscriptionId
            userId
            subscriptionStatus
            email
            customerName
            cancelAt
            cancelAtPeriodEnd
            canceledAt
            cancellationComment
            cancellationFeedback
            cancellationReason
            currency
            currentPeriodEnd
            currentPeriodStart
            planId
            planAmount
            planInterval
            planIntervalCount
            productId
            quantity
            notifications
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        maxItems
        pageSettings
        accessLevel
        notifications
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const searchMemberships = /* GraphQL */ `
  query SearchMemberships(
    $filter: SearchableMembershipFilterInput
    $sort: [SearchableMembershipSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableMembershipAggregationInput]
  ) {
    searchMemberships(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        createdAt
        updatedAt
        ownerId
        subscriptionId
        userId
        subscriptionStatus
        email
        customerName
        cancelAt
        cancelAtPeriodEnd
        canceledAt
        cancellationComment
        cancellationFeedback
        cancellationReason
        currency
        currentPeriodEnd
        currentPeriodStart
        planId
        planAmount
        planInterval
        planIntervalCount
        productId
        quantity
        venues {
          items {
            id
            userGroup
            operation
            revenue_stage1
            dishesSold_stage1
            openHours
            periodId
            salesTaxRate
            prepRoleId
            serviceRoleId
            openForBusiness
            dashboardBuild
            readOnly
            mainUser
            subscribed
            membershipId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const searchOverheads = /* GraphQL */ `
  query SearchOverheads(
    $filter: SearchableOverheadFilterInput
    $sort: [SearchableOverheadSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableOverheadAggregationInput]
  ) {
    searchOverheads(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        userGroup
        overhead
        categoryId
        category {
          id
          sort
          category
          createdAt
          updatedAt
          __typename
        }
        cost
        costPeriodId
        costPeriod {
          id
          sort
          period
          perYear
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const searchSuppliers = /* GraphQL */ `
  query SearchSuppliers(
    $filter: SearchableSupplierFilterInput
    $sort: [SearchableSupplierSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableSupplierAggregationInput]
  ) {
    searchSuppliers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        userGroup
        supplier
        cost_stage1
        cost_stage1PeriodId
        cost_stage1Period {
          id
          sort
          period
          perYear
          createdAt
          updatedAt
          __typename
        }
        ingredients {
          items {
            id
            userGroup
            ingredient
            storeId
            supplierId
            cost
            costUnitId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const searchRoles = /* GraphQL */ `
  query SearchRoles(
    $filter: SearchableRoleFilterInput
    $sort: [SearchableRoleSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableRoleAggregationInput]
  ) {
    searchRoles(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        userGroup
        role
        areaId
        area {
          id
          sort
          area
          createdAt
          updatedAt
          __typename
        }
        teamMembers
        amount
        hours
        amountPeriodId
        amountPeriod {
          id
          sort
          period
          perYear
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const searchOperations = /* GraphQL */ `
  query SearchOperations(
    $filter: SearchableOperationFilterInput
    $sort: [SearchableOperationSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableOperationAggregationInput]
  ) {
    searchOperations(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        userGroup
        operation
        revenue_stage1
        dishesSold_stage1
        openHours
        periodId
        period {
          id
          sort
          period
          perYear
          createdAt
          updatedAt
          __typename
        }
        salesTaxRate
        prepRoleId
        prepRole {
          id
          userGroup
          role
          areaId
          area {
            id
            sort
            area
            createdAt
            updatedAt
            __typename
          }
          teamMembers
          amount
          hours
          amountPeriodId
          amountPeriod {
            id
            sort
            period
            perYear
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        serviceRoleId
        serviceRole {
          id
          userGroup
          role
          areaId
          area {
            id
            sort
            area
            createdAt
            updatedAt
            __typename
          }
          teamMembers
          amount
          hours
          amountPeriodId
          amountPeriod {
            id
            sort
            period
            perYear
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        openForBusiness
        dashboardBuild
        readOnly
        mainUser
        subscribed
        membershipId
        membership {
          id
          createdAt
          updatedAt
          ownerId
          subscriptionId
          userId
          subscriptionStatus
          email
          customerName
          cancelAt
          cancelAtPeriodEnd
          canceledAt
          cancellationComment
          cancellationFeedback
          cancellationReason
          currency
          currentPeriodEnd
          currentPeriodStart
          planId
          planAmount
          planInterval
          planIntervalCount
          productId
          quantity
          venues {
            nextToken
            __typename
          }
          notifications
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const searchIngredients = /* GraphQL */ `
  query SearchIngredients(
    $filter: SearchableIngredientFilterInput
    $sort: [SearchableIngredientSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableIngredientAggregationInput]
  ) {
    searchIngredients(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        userGroup
        ingredient
        storeId
        store {
          id
          sort
          store
          createdAt
          updatedAt
          __typename
        }
        supplierId
        supplier {
          id
          userGroup
          supplier
          cost_stage1
          cost_stage1PeriodId
          cost_stage1Period {
            id
            sort
            period
            perYear
            createdAt
            updatedAt
            __typename
          }
          ingredients {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        cost
        costUnitId
        costUnit {
          id
          sort
          ingredientUnit
          factor
          recipeUnit
          createdAt
          updatedAt
          __typename
        }
        recipeIngredients {
          items {
            id
            sort
            userGroup
            recipeId
            ingredientId
            quantity
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const searchRecipes = /* GraphQL */ `
  query SearchRecipes(
    $filter: SearchableRecipeFilterInput
    $sort: [SearchableRecipeSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableRecipeAggregationInput]
  ) {
    searchRecipes(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        userGroup
        recipe
        portionSellingPrice
        portionSales
        portionSalesPeriodId
        portionSalesPeriod {
          id
          sort
          period
          perYear
          createdAt
          updatedAt
          __typename
        }
        portionCount
        prepTime
        serviceTimePerPortion
        recipeIngredients {
          items {
            id
            sort
            userGroup
            recipeId
            ingredientId
            quantity
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const searchRecipeIngredients = /* GraphQL */ `
  query SearchRecipeIngredients(
    $filter: SearchableRecipeIngredientFilterInput
    $sort: [SearchableRecipeIngredientSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableRecipeIngredientAggregationInput]
  ) {
    searchRecipeIngredients(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        sort
        userGroup
        recipeId
        ingredientId
        recipe {
          id
          userGroup
          recipe
          portionSellingPrice
          portionSales
          portionSalesPeriodId
          portionSalesPeriod {
            id
            sort
            period
            perYear
            createdAt
            updatedAt
            __typename
          }
          portionCount
          prepTime
          serviceTimePerPortion
          recipeIngredients {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ingredient {
          id
          userGroup
          ingredient
          storeId
          store {
            id
            sort
            store
            createdAt
            updatedAt
            __typename
          }
          supplierId
          supplier {
            id
            userGroup
            supplier
            cost_stage1
            cost_stage1PeriodId
            createdAt
            updatedAt
            __typename
          }
          cost
          costUnitId
          costUnit {
            id
            sort
            ingredientUnit
            factor
            recipeUnit
            createdAt
            updatedAt
            __typename
          }
          recipeIngredients {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        quantity
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getNotice = /* GraphQL */ `
  query GetNotice($id: ID!) {
    getNotice(id: $id) {
      id
      sort
      name
      type
      enabled
      text
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listNotices = /* GraphQL */ `
  query ListNotices(
    $filter: ModelNoticeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sort
        name
        type
        enabled
        text
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserProfile = /* GraphQL */ `
  query GetUserProfile($id: ID!) {
    getUserProfile(id: $id) {
      id
      owner
      name
      email
      isDarkMode
      isAdvanced
      isFixedNavbar
      selectedVenueId
      selectedVenue {
        id
        userGroup
        operation
        revenue_stage1
        dishesSold_stage1
        openHours
        periodId
        period {
          id
          sort
          period
          perYear
          createdAt
          updatedAt
          __typename
        }
        salesTaxRate
        prepRoleId
        prepRole {
          id
          userGroup
          role
          areaId
          area {
            id
            sort
            area
            createdAt
            updatedAt
            __typename
          }
          teamMembers
          amount
          hours
          amountPeriodId
          amountPeriod {
            id
            sort
            period
            perYear
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        serviceRoleId
        serviceRole {
          id
          userGroup
          role
          areaId
          area {
            id
            sort
            area
            createdAt
            updatedAt
            __typename
          }
          teamMembers
          amount
          hours
          amountPeriodId
          amountPeriod {
            id
            sort
            period
            perYear
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        openForBusiness
        dashboardBuild
        readOnly
        mainUser
        subscribed
        membershipId
        membership {
          id
          createdAt
          updatedAt
          ownerId
          subscriptionId
          userId
          subscriptionStatus
          email
          customerName
          cancelAt
          cancelAtPeriodEnd
          canceledAt
          cancellationComment
          cancellationFeedback
          cancellationReason
          currency
          currentPeriodEnd
          currentPeriodStart
          planId
          planAmount
          planInterval
          planIntervalCount
          productId
          quantity
          venues {
            nextToken
            __typename
          }
          notifications
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      maxItems
      pageSettings
      accessLevel
      notifications
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserProfiles = /* GraphQL */ `
  query ListUserProfiles(
    $filter: ModelUserProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        name
        email
        isDarkMode
        isAdvanced
        isFixedNavbar
        selectedVenueId
        selectedVenue {
          id
          userGroup
          operation
          revenue_stage1
          dishesSold_stage1
          openHours
          periodId
          period {
            id
            sort
            period
            perYear
            createdAt
            updatedAt
            __typename
          }
          salesTaxRate
          prepRoleId
          prepRole {
            id
            userGroup
            role
            areaId
            teamMembers
            amount
            hours
            amountPeriodId
            createdAt
            updatedAt
            __typename
          }
          serviceRoleId
          serviceRole {
            id
            userGroup
            role
            areaId
            teamMembers
            amount
            hours
            amountPeriodId
            createdAt
            updatedAt
            __typename
          }
          openForBusiness
          dashboardBuild
          readOnly
          mainUser
          subscribed
          membershipId
          membership {
            id
            createdAt
            updatedAt
            ownerId
            subscriptionId
            userId
            subscriptionStatus
            email
            customerName
            cancelAt
            cancelAtPeriodEnd
            canceledAt
            cancellationComment
            cancellationFeedback
            cancellationReason
            currency
            currentPeriodEnd
            currentPeriodStart
            planId
            planAmount
            planInterval
            planIntervalCount
            productId
            quantity
            notifications
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        maxItems
        pageSettings
        accessLevel
        notifications
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMembership = /* GraphQL */ `
  query GetMembership($id: ID!) {
    getMembership(id: $id) {
      id
      createdAt
      updatedAt
      ownerId
      subscriptionId
      userId
      subscriptionStatus
      email
      customerName
      cancelAt
      cancelAtPeriodEnd
      canceledAt
      cancellationComment
      cancellationFeedback
      cancellationReason
      currency
      currentPeriodEnd
      currentPeriodStart
      planId
      planAmount
      planInterval
      planIntervalCount
      productId
      quantity
      venues {
        items {
          id
          userGroup
          operation
          revenue_stage1
          dishesSold_stage1
          openHours
          periodId
          period {
            id
            sort
            period
            perYear
            createdAt
            updatedAt
            __typename
          }
          salesTaxRate
          prepRoleId
          prepRole {
            id
            userGroup
            role
            areaId
            teamMembers
            amount
            hours
            amountPeriodId
            createdAt
            updatedAt
            __typename
          }
          serviceRoleId
          serviceRole {
            id
            userGroup
            role
            areaId
            teamMembers
            amount
            hours
            amountPeriodId
            createdAt
            updatedAt
            __typename
          }
          openForBusiness
          dashboardBuild
          readOnly
          mainUser
          subscribed
          membershipId
          membership {
            id
            createdAt
            updatedAt
            ownerId
            subscriptionId
            userId
            subscriptionStatus
            email
            customerName
            cancelAt
            cancelAtPeriodEnd
            canceledAt
            cancellationComment
            cancellationFeedback
            cancellationReason
            currency
            currentPeriodEnd
            currentPeriodStart
            planId
            planAmount
            planInterval
            planIntervalCount
            productId
            quantity
            notifications
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      notifications
      __typename
    }
  }
`;
export const listMemberships = /* GraphQL */ `
  query ListMemberships(
    $filter: ModelMembershipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMemberships(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        ownerId
        subscriptionId
        userId
        subscriptionStatus
        email
        customerName
        cancelAt
        cancelAtPeriodEnd
        canceledAt
        cancellationComment
        cancellationFeedback
        cancellationReason
        currency
        currentPeriodEnd
        currentPeriodStart
        planId
        planAmount
        planInterval
        planIntervalCount
        productId
        quantity
        venues {
          items {
            id
            userGroup
            operation
            revenue_stage1
            dishesSold_stage1
            openHours
            periodId
            salesTaxRate
            prepRoleId
            serviceRoleId
            openForBusiness
            dashboardBuild
            readOnly
            mainUser
            subscribed
            membershipId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getArea = /* GraphQL */ `
  query GetArea($id: ID!) {
    getArea(id: $id) {
      id
      sort
      area
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAreas = /* GraphQL */ `
  query ListAreas(
    $filter: ModelAreaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAreas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sort
        area
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      sort
      category
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCategories = /* GraphQL */ `
  query ListCategories(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sort
        category
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPeriod = /* GraphQL */ `
  query GetPeriod($id: ID!) {
    getPeriod(id: $id) {
      id
      sort
      period
      perYear
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPeriods = /* GraphQL */ `
  query ListPeriods(
    $filter: ModelPeriodFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPeriods(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sort
        period
        perYear
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStore = /* GraphQL */ `
  query GetStore($id: ID!) {
    getStore(id: $id) {
      id
      sort
      store
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listStores = /* GraphQL */ `
  query ListStores(
    $filter: ModelStoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStores(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sort
        store
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUnit = /* GraphQL */ `
  query GetUnit($id: ID!) {
    getUnit(id: $id) {
      id
      sort
      ingredientUnit
      factor
      recipeUnit
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUnits = /* GraphQL */ `
  query ListUnits(
    $filter: ModelUnitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUnits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sort
        ingredientUnit
        factor
        recipeUnit
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOverhead = /* GraphQL */ `
  query GetOverhead($id: ID!) {
    getOverhead(id: $id) {
      id
      userGroup
      overhead
      categoryId
      category {
        id
        sort
        category
        createdAt
        updatedAt
        __typename
      }
      cost
      costPeriodId
      costPeriod {
        id
        sort
        period
        perYear
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOverheads = /* GraphQL */ `
  query ListOverheads(
    $filter: ModelOverheadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOverheads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userGroup
        overhead
        categoryId
        category {
          id
          sort
          category
          createdAt
          updatedAt
          __typename
        }
        cost
        costPeriodId
        costPeriod {
          id
          sort
          period
          perYear
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSupplier = /* GraphQL */ `
  query GetSupplier($id: ID!) {
    getSupplier(id: $id) {
      id
      userGroup
      supplier
      cost_stage1
      cost_stage1PeriodId
      cost_stage1Period {
        id
        sort
        period
        perYear
        createdAt
        updatedAt
        __typename
      }
      ingredients {
        items {
          id
          userGroup
          ingredient
          storeId
          store {
            id
            sort
            store
            createdAt
            updatedAt
            __typename
          }
          supplierId
          supplier {
            id
            userGroup
            supplier
            cost_stage1
            cost_stage1PeriodId
            createdAt
            updatedAt
            __typename
          }
          cost
          costUnitId
          costUnit {
            id
            sort
            ingredientUnit
            factor
            recipeUnit
            createdAt
            updatedAt
            __typename
          }
          recipeIngredients {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSuppliers = /* GraphQL */ `
  query ListSuppliers(
    $filter: ModelSupplierFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSuppliers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userGroup
        supplier
        cost_stage1
        cost_stage1PeriodId
        cost_stage1Period {
          id
          sort
          period
          perYear
          createdAt
          updatedAt
          __typename
        }
        ingredients {
          items {
            id
            userGroup
            ingredient
            storeId
            supplierId
            cost
            costUnitId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRole = /* GraphQL */ `
  query GetRole($id: ID!) {
    getRole(id: $id) {
      id
      userGroup
      role
      areaId
      area {
        id
        sort
        area
        createdAt
        updatedAt
        __typename
      }
      teamMembers
      amount
      hours
      amountPeriodId
      amountPeriod {
        id
        sort
        period
        perYear
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRoles = /* GraphQL */ `
  query ListRoles(
    $filter: ModelRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userGroup
        role
        areaId
        area {
          id
          sort
          area
          createdAt
          updatedAt
          __typename
        }
        teamMembers
        amount
        hours
        amountPeriodId
        amountPeriod {
          id
          sort
          period
          perYear
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOperation = /* GraphQL */ `
  query GetOperation($id: ID!) {
    getOperation(id: $id) {
      id
      userGroup
      operation
      revenue_stage1
      dishesSold_stage1
      openHours
      periodId
      period {
        id
        sort
        period
        perYear
        createdAt
        updatedAt
        __typename
      }
      salesTaxRate
      prepRoleId
      prepRole {
        id
        userGroup
        role
        areaId
        area {
          id
          sort
          area
          createdAt
          updatedAt
          __typename
        }
        teamMembers
        amount
        hours
        amountPeriodId
        amountPeriod {
          id
          sort
          period
          perYear
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      serviceRoleId
      serviceRole {
        id
        userGroup
        role
        areaId
        area {
          id
          sort
          area
          createdAt
          updatedAt
          __typename
        }
        teamMembers
        amount
        hours
        amountPeriodId
        amountPeriod {
          id
          sort
          period
          perYear
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      openForBusiness
      dashboardBuild
      readOnly
      mainUser
      subscribed
      membershipId
      membership {
        id
        createdAt
        updatedAt
        ownerId
        subscriptionId
        userId
        subscriptionStatus
        email
        customerName
        cancelAt
        cancelAtPeriodEnd
        canceledAt
        cancellationComment
        cancellationFeedback
        cancellationReason
        currency
        currentPeriodEnd
        currentPeriodStart
        planId
        planAmount
        planInterval
        planIntervalCount
        productId
        quantity
        venues {
          items {
            id
            userGroup
            operation
            revenue_stage1
            dishesSold_stage1
            openHours
            periodId
            salesTaxRate
            prepRoleId
            serviceRoleId
            openForBusiness
            dashboardBuild
            readOnly
            mainUser
            subscribed
            membershipId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOperations = /* GraphQL */ `
  query ListOperations(
    $filter: ModelOperationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOperations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userGroup
        operation
        revenue_stage1
        dishesSold_stage1
        openHours
        periodId
        period {
          id
          sort
          period
          perYear
          createdAt
          updatedAt
          __typename
        }
        salesTaxRate
        prepRoleId
        prepRole {
          id
          userGroup
          role
          areaId
          area {
            id
            sort
            area
            createdAt
            updatedAt
            __typename
          }
          teamMembers
          amount
          hours
          amountPeriodId
          amountPeriod {
            id
            sort
            period
            perYear
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        serviceRoleId
        serviceRole {
          id
          userGroup
          role
          areaId
          area {
            id
            sort
            area
            createdAt
            updatedAt
            __typename
          }
          teamMembers
          amount
          hours
          amountPeriodId
          amountPeriod {
            id
            sort
            period
            perYear
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        openForBusiness
        dashboardBuild
        readOnly
        mainUser
        subscribed
        membershipId
        membership {
          id
          createdAt
          updatedAt
          ownerId
          subscriptionId
          userId
          subscriptionStatus
          email
          customerName
          cancelAt
          cancelAtPeriodEnd
          canceledAt
          cancellationComment
          cancellationFeedback
          cancellationReason
          currency
          currentPeriodEnd
          currentPeriodStart
          planId
          planAmount
          planInterval
          planIntervalCount
          productId
          quantity
          venues {
            nextToken
            __typename
          }
          notifications
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getIngredient = /* GraphQL */ `
  query GetIngredient($id: ID!) {
    getIngredient(id: $id) {
      id
      userGroup
      ingredient
      storeId
      store {
        id
        sort
        store
        createdAt
        updatedAt
        __typename
      }
      supplierId
      supplier {
        id
        userGroup
        supplier
        cost_stage1
        cost_stage1PeriodId
        cost_stage1Period {
          id
          sort
          period
          perYear
          createdAt
          updatedAt
          __typename
        }
        ingredients {
          items {
            id
            userGroup
            ingredient
            storeId
            supplierId
            cost
            costUnitId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      cost
      costUnitId
      costUnit {
        id
        sort
        ingredientUnit
        factor
        recipeUnit
        createdAt
        updatedAt
        __typename
      }
      recipeIngredients {
        items {
          id
          sort
          userGroup
          recipeId
          ingredientId
          recipe {
            id
            userGroup
            recipe
            portionSellingPrice
            portionSales
            portionSalesPeriodId
            portionCount
            prepTime
            serviceTimePerPortion
            createdAt
            updatedAt
            __typename
          }
          ingredient {
            id
            userGroup
            ingredient
            storeId
            supplierId
            cost
            costUnitId
            createdAt
            updatedAt
            __typename
          }
          quantity
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listIngredients = /* GraphQL */ `
  query ListIngredients(
    $filter: ModelIngredientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIngredients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userGroup
        ingredient
        storeId
        store {
          id
          sort
          store
          createdAt
          updatedAt
          __typename
        }
        supplierId
        supplier {
          id
          userGroup
          supplier
          cost_stage1
          cost_stage1PeriodId
          cost_stage1Period {
            id
            sort
            period
            perYear
            createdAt
            updatedAt
            __typename
          }
          ingredients {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        cost
        costUnitId
        costUnit {
          id
          sort
          ingredientUnit
          factor
          recipeUnit
          createdAt
          updatedAt
          __typename
        }
        recipeIngredients {
          items {
            id
            sort
            userGroup
            recipeId
            ingredientId
            quantity
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRecipe = /* GraphQL */ `
  query GetRecipe($id: ID!) {
    getRecipe(id: $id) {
      id
      userGroup
      recipe
      portionSellingPrice
      portionSales
      portionSalesPeriodId
      portionSalesPeriod {
        id
        sort
        period
        perYear
        createdAt
        updatedAt
        __typename
      }
      portionCount
      prepTime
      serviceTimePerPortion
      recipeIngredients {
        items {
          id
          sort
          userGroup
          recipeId
          ingredientId
          recipe {
            id
            userGroup
            recipe
            portionSellingPrice
            portionSales
            portionSalesPeriodId
            portionCount
            prepTime
            serviceTimePerPortion
            createdAt
            updatedAt
            __typename
          }
          ingredient {
            id
            userGroup
            ingredient
            storeId
            supplierId
            cost
            costUnitId
            createdAt
            updatedAt
            __typename
          }
          quantity
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRecipes = /* GraphQL */ `
  query ListRecipes(
    $filter: ModelRecipeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecipes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userGroup
        recipe
        portionSellingPrice
        portionSales
        portionSalesPeriodId
        portionSalesPeriod {
          id
          sort
          period
          perYear
          createdAt
          updatedAt
          __typename
        }
        portionCount
        prepTime
        serviceTimePerPortion
        recipeIngredients {
          items {
            id
            sort
            userGroup
            recipeId
            ingredientId
            quantity
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRecipeIngredient = /* GraphQL */ `
  query GetRecipeIngredient($id: ID!) {
    getRecipeIngredient(id: $id) {
      id
      sort
      userGroup
      recipeId
      ingredientId
      recipe {
        id
        userGroup
        recipe
        portionSellingPrice
        portionSales
        portionSalesPeriodId
        portionSalesPeriod {
          id
          sort
          period
          perYear
          createdAt
          updatedAt
          __typename
        }
        portionCount
        prepTime
        serviceTimePerPortion
        recipeIngredients {
          items {
            id
            sort
            userGroup
            recipeId
            ingredientId
            quantity
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ingredient {
        id
        userGroup
        ingredient
        storeId
        store {
          id
          sort
          store
          createdAt
          updatedAt
          __typename
        }
        supplierId
        supplier {
          id
          userGroup
          supplier
          cost_stage1
          cost_stage1PeriodId
          cost_stage1Period {
            id
            sort
            period
            perYear
            createdAt
            updatedAt
            __typename
          }
          ingredients {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        cost
        costUnitId
        costUnit {
          id
          sort
          ingredientUnit
          factor
          recipeUnit
          createdAt
          updatedAt
          __typename
        }
        recipeIngredients {
          items {
            id
            sort
            userGroup
            recipeId
            ingredientId
            quantity
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      quantity
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRecipeIngredients = /* GraphQL */ `
  query ListRecipeIngredients(
    $filter: ModelRecipeIngredientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecipeIngredients(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sort
        userGroup
        recipeId
        ingredientId
        recipe {
          id
          userGroup
          recipe
          portionSellingPrice
          portionSales
          portionSalesPeriodId
          portionSalesPeriod {
            id
            sort
            period
            perYear
            createdAt
            updatedAt
            __typename
          }
          portionCount
          prepTime
          serviceTimePerPortion
          recipeIngredients {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ingredient {
          id
          userGroup
          ingredient
          storeId
          store {
            id
            sort
            store
            createdAt
            updatedAt
            __typename
          }
          supplierId
          supplier {
            id
            userGroup
            supplier
            cost_stage1
            cost_stage1PeriodId
            createdAt
            updatedAt
            __typename
          }
          cost
          costUnitId
          costUnit {
            id
            sort
            ingredientUnit
            factor
            recipeUnit
            createdAt
            updatedAt
            __typename
          }
          recipeIngredients {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        quantity
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMembershipByUserId = /* GraphQL */ `
  query ListMembershipByUserId(
    $userId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelMembershipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMembershipByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        ownerId
        subscriptionId
        userId
        subscriptionStatus
        email
        customerName
        cancelAt
        cancelAtPeriodEnd
        canceledAt
        cancellationComment
        cancellationFeedback
        cancellationReason
        currency
        currentPeriodEnd
        currentPeriodStart
        planId
        planAmount
        planInterval
        planIntervalCount
        productId
        quantity
        venues {
          items {
            id
            userGroup
            operation
            revenue_stage1
            dishesSold_stage1
            openHours
            periodId
            salesTaxRate
            prepRoleId
            serviceRoleId
            openForBusiness
            dashboardBuild
            readOnly
            mainUser
            subscribed
            membershipId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications
        __typename
      }
      nextToken
      __typename
    }
  }
`;
