// prop-types is a library for typechecking of props https://www.npmjs.com/package/prop-types
import PropTypes from "prop-types";

// import { useState } from "react";

// @mui icons https://fonts.google.com/icons
// import Icon from "@mui/material/Icon";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import Icon from "@mui/material/Icon";

// import Box from "@mui/material/Box";
// import Container from "@mui/material/Container";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDPagination from "components/MDPagination";
import MDInput from "components/MDInput";

// HospoSure
import { demoVenues, templateVenues, loading } from "config";
import { camelToTitleCase, _ } from "general";
import Error from "components/Error";
import Loading from "components/Loading";
import Importer from "./components/Importer";
import Row from "./components/Row";
import { RecipeSelector, addIngredientToRecipe } from "./components/RecipeSelector";
import FilterSwitch from "./components/FilterSwitch";

function Table({ userProfile, venue, pageCfg, readOnly, Items, postNotification }) {
  const { pageSettings, updatePageSettings, admin: userAdmin, isAdvanced } = userProfile;
  const { id, userGroup, venues, changeVenue } = venue;
  const { keys, isMany, reroute, type, admin: pageAdmin, warning } = pageCfg;
  const { selectedRecipe, filter, showFilter } = JSON.parse(pageSettings)[type] || {};
  const {
    createItem,
    updateItem,
    deleteItem,
    items,
    newTemplate,
    lookups,
    setSort,
    updateFilter,
    load,
    changePage,
    entries,
    pages,
  } = Items;

  // const showTotalEntries = true;
  const canPreviousPage = pages.index > 0;
  const canNextPage = pages.total > pages.index;

  // Render the paginations
  const renderPagination = [...Array(pages.total + 1).keys()].map((a) => (
    <MDPagination item key={a} onClick={() => changePage.goto(a)} active={pages.index === a}>
      {a + 1}
    </MDPagination>
  ));
  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pages.total + 1 || value < 0 ? changePage.goto(0) : changePage.goto(value);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) => changePage.goto(value.value - 1);

  return (
    <Grid item xs={12}>
      <Grid container spacing={3}>
        {!pageAdmin && !isMany ? ( // && (type === "Ingredient" || templates.includes(venue))
          <Grid item xs={12}>
            <Grid container direction="row-reverse" spacing={3}>
              {!pageAdmin && !isMany && (
                <FilterSwitch
                  showFilter={showFilter}
                  switchFilter={() =>
                    updatePageSettings({
                      pageType: type,
                      setting: {
                        showFilter: !showFilter,
                        filter: newTemplate.filter,
                      },
                    })
                  }
                  isAdvanced={isAdvanced}
                />
              )}
              {type === "Ingredient" && (!templateVenues.includes(id) || userAdmin) && (
                <RecipeSelector
                  {...{
                    userGroup,
                    selectedRecipe,
                    updatePageSettings,
                    isAdvanced,
                  }}
                />
              )}
              {!pageAdmin &&
                !isMany &&
                templateVenues.includes(id) &&
                venues.filter((a) => !templateVenues.includes(a.id)).length > 0 && (
                  <Importer
                    {...{
                      userGroup,
                      venues,
                      items,
                      pageCfg,
                      demoVenues,
                      templateVenues,
                      postNotification,
                      itemTotal: entries.total,
                      isAdvanced,
                    }}
                  />
                )}
            </Grid>
          </Grid>
        ) : null}
        {load !== loading.lookupsMissing ? (
          <Grid item xs={12}>
            <Card>
              <MDBox px={3} pb={3}>
                {/* <form> */}
                <table
                  style={
                    pageAdmin ? { tablelayout: "fixed" } : { tablelayout: "fixed", width: "100%" }
                  }
                >
                  <thead>
                    <tr>
                      {keys &&
                        Object.entries(keys)
                          .filter(([k, v]) => k && v.keyType.display !== "hidden") // k !== m2mKey &&
                          .map(([key, v]) => (
                            <th
                              key={key}
                              onClick={() => setSort({ key })}
                              style={{
                                width: v.width
                                  ? `${
                                      (v.width /
                                        (Object.values(keys).reduce((sum, a) => sum + a.width, 0) +
                                          5)) *
                                      100
                                    }%`
                                  : "auto",
                              }}
                            >
                              <Tooltip
                                title={!isAdvanced ? v.tip : ""}
                                placement="top"
                                TransitionComponent={Zoom}
                              >
                                <MDTypography
                                  variant="caption"
                                  textTransform="capitalize"
                                  color="text"
                                  // title={v.tip}
                                >
                                  {camelToTitleCase(key)}
                                </MDTypography>
                              </Tooltip>
                            </th>
                          ))}
                      {/* <th style={{ width: "5%" }}> </th> */}
                    </tr>
                    {showFilter && (
                      <Row
                        item={{ ...keys, ...filter }}
                        actions={{
                          updateFilter: ({ item }) => updateFilter({ item }),
                        }}
                        pageCfg={pageCfg}
                        lookups={lookups}
                        isAdvanced={isAdvanced}
                      />
                    )}
                  </thead>
                  <tbody>
                    {load === loading.itemsFetched || _.exists(items) ? (
                      items.map((item) => (
                        <Row
                          key={item.id}
                          item={item}
                          actions={{
                            updateItem,
                            deleteItem: (props) => deleteItem({ ...props, postNotification }),
                            editItem: reroute,
                            addIngredientToRecipe:
                              type === "Ingredient" && selectedRecipe
                                ? (props) =>
                                    addIngredientToRecipe({
                                      ...props,
                                      recipeId: selectedRecipe.id,
                                      recipe: selectedRecipe.recipe,
                                      userGroup,
                                      postNotification,
                                    })
                                : null,
                            changeVenue: (props) => changeVenue({ ...props, postNotification }),
                          }}
                          lookups={lookups}
                          pageCfg={pageCfg}
                          readOnly={readOnly}
                          isAdvanced={isAdvanced}
                        />
                      ))
                    ) : (
                      <tr>
                        <td colSpan="100%">
                          <Loading aria-label="Loading" />
                        </td>
                      </tr>
                    )}
                  </tbody>
                  {!isMany &&
                  _.exists(newTemplate.item) &&
                  !readOnly &&
                  load >= loading.itemsFetched ? (
                    <tfoot>
                      <Row
                        item={newTemplate.item}
                        actions={{
                          createItem: (props) => createItem({ ...props, postNotification }),
                        }}
                        lookups={lookups}
                        pageCfg={pageCfg}
                        isAdvanced={isAdvanced}
                      />
                    </tfoot>
                  ) : null}
                </table>
                {/* </form> */}
                {!pageAdmin && (
                  <MDBox
                    display="flex"
                    flexDirection={{ xs: "column", sm: "row" }}
                    justifyContent="space-between"
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    pt={2}
                  >
                    <MDBox mb={{ xs: 3, sm: 0 }}>
                      <MDTypography variant="caption" color="text">
                        {load >= loading.itemsFetched
                          ? `Showing ${entries.start} to ${entries.end} of ${entries.total} entries`
                          : "Loading entries..."}
                      </MDTypography>
                    </MDBox>

                    {pages.total > 0 && (
                      <MDPagination variant="gradient" color="primary">
                        {canPreviousPage && (
                          <MDPagination item onClick={() => changePage.previous()}>
                            <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
                          </MDPagination>
                        )}
                        {pages.total > 6 ? (
                          <MDBox width="4rem" mx={1}>
                            <MDInput
                              inputProps={{ type: "number", min: 1, max: pages.total + 1 }}
                              value={pages.index + 1}
                              onChange={(handleInputPagination, handleInputPaginationValue)}
                            />
                          </MDBox>
                        ) : (
                          renderPagination
                        )}
                        {canNextPage && (
                          <MDPagination item onClick={() => changePage.next()}>
                            <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
                          </MDPagination>
                        )}
                      </MDPagination>
                    )}
                  </MDBox>
                )}
              </MDBox>
            </Card>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Error message={warning} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
Table.defaultProps = {
  // m2m: false,
  // itemId: null,
  readOnly: false,
};
Table.propTypes = {
  userProfile: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.func,
      PropTypes.array,
      PropTypes.bool,
      PropTypes.number,
    ])
  ).isRequired,
  venue: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.func,
      PropTypes.array,
      PropTypes.bool,
      PropTypes.number,
    ])
  ).isRequired, // m2m: PropTypes.bool,
  // itemId: PropTypes.string,
  pageCfg: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.func,
      PropTypes.array,
      PropTypes.bool,
      PropTypes.number,
    ])
  ).isRequired,
  readOnly: PropTypes.bool,
  Items: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.func,
      PropTypes.array,
      PropTypes.bool,
      PropTypes.number,
    ])
  ).isRequired,
  postNotification: PropTypes.func.isRequired,
};

export default Table;
