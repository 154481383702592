/* eslint-disable import/extensions */

import environment from "./env.js";

const { locale, dateFormat } = environment;

const _ = {
  exists: (obj) => {
    try {
      return Array.isArray(obj) ? obj.length > 0 : obj && Object.keys(obj).length > 0;
    } catch {
      return false;
    }
  },
  // name: (v) => Object.keys(v).pop(),
  trim: ({ item, keys, dataMgmt }) =>
    keys
      ? Object.assign(
          {},
          ...Object.entries(item)
            .filter(
              ([k]) =>
                (k in keys && // remove pointer keys
                  !(keys[k].keyType.display === "static")) || // remove derived keys
                (!dataMgmt && ["id", "sort", "userGroup"].includes(k)) // keep data mgmt keys unless specified
            )
            .map(([k, v]) => ({ [k]: v }))
        )
      : Object.assign(
          {},
          ...Object.entries(item)
            .filter(([k]) => !["createdAt", "updatedAt", "owner"].includes(k))
            .map(([k, v]) => ({ [k]: v }))
        ),
  drop: (obj, keys) =>
    Object.assign(
      {},
      ...Object.entries(obj || {})
        .filter(([k]) => !Object.keys(keys).includes(k))
        .map(([k, v]) => ({ [k]: v }))
    ),
  keep: (obj, keys) =>
    Object.assign(
      {},
      ...Object.entries(obj || {})
        .filter(([k]) => Object.keys(keys).includes(k))
        .map(([k, v]) => ({ [k]: v }))
    ),
};

const capitalizeFirstLetter = (a) => a && a.charAt(0).toUpperCase() + a.slice(1);

const camelToTitleCase = (a) =>
  a
    ? a.charAt(0).toUpperCase() +
      a
        .slice(1) // change 1st letter to uppercase
        .replace(/(\B[A-Z])/g, " $1") // add space before any uppercase except word boundary
        .replace(/\bId\b/, "") // remove the word Id
        .replace(/_stage([0-9])\b/, "") // remove the word _stage?
        // .replace(/_stage([1])\b/, " *") // replace the word _stage1 with *
        // .replace(/_stage([2])\b/, "") // remove the word _stage2
        .replace(/\bPer \b/, "/") // change "Per " to "/"
        .replace(/_\b/, "") // remove the char _
        .replace(/-\b/, " ") // replace - with " "
    : "";

const getDate = (timestamp) =>
  timestamp ? new Date(timestamp * 1000).toLocaleDateString(locale, dateFormat) : null;

const pluralize = (noun) =>
  noun.slice(-1) === "y" ? `${noun.slice(0, noun.length - 1)}ies` : `${noun}s`;

// const removeMetaData = (a) => {
//   const metaData = ["createdAt", "updatedAt", "owner"];
//   return Array.isArray(a) ? a.map((b) => filterObj(b, metaData)) : filterObj(a, metaData);
// };

const toThousands = (num) => {
  if (["", null].includes(num)) return "";
  const [wholeNumber, decimal] = num.toString().split(".");
  const formattedNumber = Intl.NumberFormat().format(wholeNumber).toString();
  return decimal ? `${formattedNumber}.${decimal}` : formattedNumber;
};

const formatDecimal = (input, format) =>
  // eslint-disable-next-line no-restricted-globals
  !["", 0].includes(input) && isFinite(input)
    ? format
        .replace("n", toThousands(Number(input).toFixed([...format.matchAll(/d/g)].length)))
        .replace(/d/g, "")
    : "";

const formatDuration = (input, format) => {
  const duration = { h: Math.floor(input / 3600) };
  duration.m = Math.floor(input / 60) - duration.h * 60;
  duration.s = input % 60;
  return input !== ""
    ? format
        .replace("n", duration.h)
        .replace("n", duration.m)
        .replace("n", duration.s)
        .replace(/\s?\b0.\s?/g, "") // remove 0 values with spaces
    : "";
};

function unformatDuration(input) {
  const duration = {
    // extract correct format
    h: input.match(/\d+h/),
    m: input.match(/\d+m/),
    s: input.match(/\d+s/),
  };
  Object.entries(duration).forEach(([k, v]) => {
    // extract digits
    if (v) duration[k] = v[0].match(/\d+/);
  });
  if (Object.values(duration).every((v) => v === null)) {
    // if all null, extract digits in order
    const match = input.match(/\d+/g);
    if (match && match.length === 1) {
      [duration.m] = match;
    } else {
      Object.keys(duration).forEach((k, i) => {
        duration[k] = (match && match[i]) || 0;
      });
    }
  }
  Object.entries(duration).forEach(([k, v]) => {
    // convert to numbers
    if (v) duration[k] = Number(v);
  });
  const output = duration.h * 3600 + duration.m * 60 + duration.s;
  return output;
}

const format = {
  // array: {
  //   display: "list",
  //   initial: (v) => v,
  //   apply: (v) => v,
  //   remove: (v) => v,
  //   filter: "eq",
  // },
  boolean: {
    display: "checkbox",
    initial: () => false,
    apply: (v) => v,
    remove: (v) => v,
    filter: "eq",
  },
  currency: {
    display: "number",
    initial: () => 0,
    apply: (v) =>
      // eslint-disable-next-line no-nested-ternary
      [0, "", null, undefined].includes(v)
        ? ""
        : v < 0
          ? `(${formatDecimal(v, "$ndd").replace("-", "")})`
          : formatDecimal(v, "$ndd"),
    remove: (v) => Number(v.replace(/[^0-9.]/g, "")) || 0,
    filter: "gte",
    sort: (a, b) => a - b,
  },
  date: {
    display: "text",
    initial: () => "",
    apply: (v) =>
      v
        ? new Date(v * 1000).toLocaleDateString("en-GB", {
            day: "numeric",
            month: "short",
            year: "numeric",
          })
        : "",
    remove: (v) => v,
    filter: "eq",
  },
  derived: {
    display: "static",
    initial: () => "",
    apply: (v) => v,
    remove: (v) => v,
  },
  duration: {
    display: "number",
    initial: () => 0,
    apply: (v) => formatDuration(v, "nh nm ns"),
    remove: (v) => unformatDuration(v) || 0,
    filter: "gte",
    sort: (a, b) => a - b,
  },
  fixed: {
    display: "static",
    initial: () => "", // () => v
    apply: (v) => v,
    remove: (v) => v,
    // filter: "wildcard",
  },
  float: {
    display: "number",
    initial: () => 0,
    apply: (v) => (v === 0 ? "" : toThousands(v)),
    remove: (v) => Number(v.replace(/[^0-9.]/g, "")) || 0,
    filter: "gte",
    sort: (a, b) => a - b,
  },
  hidden: {
    display: "hidden",
    initial: () => "",
    apply: (v) => v,
    remove: (v) => v,
  },
  integer: {
    display: "number",
    initial: () => 0,
    apply: (v) => (v === 0 ? "" : formatDecimal(v, "n")),
    remove: (v) => parseInt(v.replace(/[^0-9.]/g, ""), 10) || 0,
    filter: "gte",
    sort: (a, b) => a - b,
  },
  percentage: {
    display: "number",
    initial: () => 0,
    apply: (v) => (v === 0 ? "" : formatDecimal(v * 100, "nd%")),
    remove: (v) => Number(v.replace(/[^0-9.]/g, "")) / 100 || 0,
    filter: "gte",
    sort: (a, b) => a - b,
  },
  select: {
    display: "select",
    initial: (v) => v,
    apply: (v) => v,
    remove: (v) => v,
    filter: "eq",
  },
  staticSelect: {
    display: "staticSelect",
    initial: (v) => v,
    apply: (v) => v,
    remove: (v) => v,
    filter: "eq",
  },
  text: {
    display: "text",
    initial: () => "",
    apply: (v) => v,
    remove: (v) => v,
    filter: "matchPhrasePrefix",
    sort: (a, b) => a.localeCompare(b),
  },
  multilineText: {
    display: "multilineText",
    initial: () => "",
    apply: (v) => v,
    remove: (v) => v,
    filter: "matchPhrasePrefix",
    sort: (a, b) => a.localeCompare(b),
  },
};

const formatNums = ({ item, keys }, withValues) => {
  const f = (a) =>
    Object.assign(
      {},
      ...Object.entries(a).map(([k, v]) => {
        const { apply } = keys[k]?.dataType || keys[k]?.keyType || { apply: null };
        return withValues
          ? { [k]: apply ? { v, l: apply(v) } : { v, l: v } }
          : { [k]: apply ? apply(v) : v };
      })
    );
  return Array.isArray(item) ? item.map((a) => f(a)) : f(item);
};

// eslint-disable-next-line no-alert
const showAlert = (message) => alert(message);

function sendEmail(e, fromEmail) {
  e.preventDefault();
  const url = "https://6gcdonb7r7.execute-api.ap-southeast-2.amazonaws.com";

  const data = {
    subject: "Account Deactivate",
    email: fromEmail,
  };

  // send POST request
  fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response)
      showAlert(
        "We have received your request to deactivate your account and will be in touch shortly."
      );
    // eslint-disable-next-line no-console
    // console.log("Response: ", response);
  });
  // .catch((error) => console.log("Error: ", error));
}

function setDerivedKeys({ item, keys, lookups, items }) {
  const calculatedKeyValues = {};

  const factors = new Set(
    Object.values(keys)
      .filter((v) => v.factors)
      .map((v) => v.factors)
      .flat(2)
  );

  const factorLookups =
    factors.size &&
    Object.assign(
      {},
      ...Object.entries(lookups)
        .filter(([k]) => factors.has(k))
        .map(([k, v]) => ({ [k]: v }))
    );

  if (
    Object.values(keys).some((a) => "formula" in a) &&
    factors.size === Object.keys(factorLookups).length
  ) {
    Object.entries(keys)
      .filter(([k, v]) => k && v.formula)
      .forEach(([k, v]) => {
        calculatedKeyValues[k] = v.formula({
          ...item,
          ...factorLookups,
          ...calculatedKeyValues,
          items,
        });
      });
  }
  return { ...item, ...calculatedKeyValues };
}

function unformatNums({ item, keys }) {
  const output = Object.assign(
    {},
    ...Object.entries(item).map(([k, v]) => ({ [k]: k in keys ? keys[k].keyType.remove(v) : v }))
  );
  return output;
}

// function userWarning(message) {
//   // eslint-disable-next-line no-alert
//   alert(message);
// }

export {
  capitalizeFirstLetter,
  camelToTitleCase,
  format,
  formatNums,
  formatDecimal,
  // getSubDomain,
  getDate,
  pluralize,
  sendEmail,
  setDerivedKeys,
  showAlert,
  unformatDuration,
  unformatNums,
  // userWarning,
  _,
};
