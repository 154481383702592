/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// @mui icons https://fonts.google.com/icons
import Icon from "@mui/material/Icon";

// HospoSure
import Entries from "layouts/entries";
import PlanBuild from "layouts/dashboards/plan-build";
import Account from "layouts/account";
import Settings from "layouts/settings";
import Timeline from "layouts/timeline";
// import Members from "layouts/members";

const routes = [
  {
    type: "collapse",
    name: "Home",
    key: "home",
    route: "/",
    icon: <Icon fontSize="medium">home</Icon>,
    component: <Timeline />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="medium">account_circle</Icon>,
    collapse: [
      {
        name: "Account",
        key: "account",
        route: "/profile/account",
        component: <Account />,
      },
      {
        name: "Settings",
        key: "settings",
        route: "/profile/settings",
        component: <Settings />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Admin",
    key: "admin",
    icon: <Icon fontSize="medium">admin_panel_settings</Icon>,
    collapse: [
      {
        name: "Members",
        key: "members",
        route: "/admin/members",
        component: <Entries key="members" route="/admin/members" />,
      },
      {
        name: "Notices",
        key: "notices",
        route: "/admin/notices",
        component: <Entries key="notices" route="/admin/notices" />,
      },
      {
        type: "collapse",
        name: "Global Values",
        key: "values",
        icon: <Icon fontSize="medium">admin_panel_settings</Icon>,
        collapse: [
          {
            name: "Areas",
            key: "areas",
            route: "/admin/areas",
            component: <Entries key="areas" route="/admin/areas" />,
          },
          {
            name: "Categories",
            key: "categories",
            route: "/admin/categories",
            component: <Entries key="categories" route="/admin/categories" />,
          },
          {
            name: "Periods",
            key: "periods",
            route: "/admin/periods",
            component: <Entries key="periods" route="/admin/periods" />,
          },
          {
            name: "Stores",
            key: "stores",
            route: "/admin/stores",
            component: <Entries key="stores" route="/admin/stores" />,
          },
          {
            name: "Units",
            key: "units",
            route: "/admin/units",
            component: <Entries key="units" route="/admin/units" />,
          },
        ],
      },
    ],
  },
  { type: "divider", key: "divider-model" },
  {
    type: "collapse",
    name: "Cafe Modelling",
    key: "model",
    icon: <Icon fontSize="medium">web</Icon>,
    collapse: [
      {
        name: "Overheads",
        key: "overheads",
        route: "/model/overheads",
        component: <Entries key="overheads" route="/model/overheads" />,
      },
      {
        name: "Suppliers",
        key: "suppliers",
        route: "/model/suppliers",
        component: <Entries key="suppliers" route="/model/suppliers" />,
      },
      {
        name: "Payroll",
        key: "payroll",
        route: "/model/payroll",
        component: <Entries key="payroll" route="/model/payroll" />,
      },
      {
        name: "Operation",
        key: "operation",
        route: "/model/operation",
        component: <Entries key="operation" route="/model/operation" />,
      },
      // {
      //   name: "Dashboard",
      //   key: "dashboard",
      //   route: "/model/dashboard",
      //   icon: <Icon fontSize="medium">dashboard</Icon>,
      //   component: <PlanBuild route="/model/dashboard" />,
      // },
    ],
  },
  {
    type: "collapse",
    name: "Menu Building",
    key: "build",
    icon: <Icon fontSize="medium">lunch_dining</Icon>,
    collapse: [
      {
        name: "Menu",
        key: "menu",
        route: "/build/menu",
        component: <Entries key="menu" route="/build/menu" />,
      },
      {
        name: "Ingredients",
        key: "ingredients",
        route: "/build/ingredients",
        component: <Entries key="ingredients" route="/build/ingredients" />,
      },
      {
        // name: "Menu Builder",
        key: "recipeAnalysis",
        route: "/build/recipeAnalysis",
        component: <Entries key="recipeAnalysis" route="/build/recipeAnalysis" />,
      },
      // {
      //   name: "Dashboard",
      //   key: "dashboard",
      //   route: "/build/dashboard",
      //   icon: <Icon fontSize="medium">dashboard</Icon>,
      //   component: <PlanBuild route="/build/dashboard" />,
      // },
    ],
  },
  { type: "divider", key: "divider-dashboard" },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    component: <PlanBuild route="/dashboard" />,
    noCollapse: true,
  },
];

export default routes;
