// prop-types is a library for typechecking of props. https://www.npmjs.com/package/prop-types
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

function Error({ message }) {
  return (
    <Grid item xs={12}>
      <Card id="error">
        <MDBox
          display="flex"
          // justifyContent="flex-start"
          flexDirection={{ xs: "row" }}
          alignItems="center"
          p={3}
        >
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgColor="warning"
            color="white"
            width="2rem"
            height="2rem"
            borderRadius="50%"
            sx={{ fontSize: ({ typography: { size } }) => size.lg }}
            flexDirection={{ xs: "column", sm: "row" }}
            marginRight="10px"
          >
            <Icon fontSize="inherit">priority_high</Icon>
          </MDBox>
          <MDTypography variant="button" color="text">
            {message}
          </MDTypography>
        </MDBox>
      </Card>
    </Grid>
  );
}
Error.defaultProps = {
  message: "No warning",
};
Error.propTypes = {
  message: PropTypes.string,
};

export default Error;
