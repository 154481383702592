// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

import { useState, useEffect } from "react";

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";
import Popper from "@mui/material/Popper";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
// import TextField from "@mui/material/TextField";
// import { Typography } from "@mui/material";
import MDInput from "components/MDInput";

// HospoSure
import { templateVenues } from "config";

const styles = () => ({
  popper: { width: "fit-content" },
});
function PopperMy(props) {
  return <Popper {...props} style={styles.popper} placement="bottom-start" />;
}

function Venue({ page, venue }) {
  const { id, operation, changeVenue, venues } = venue;
  const [selectVenues, setSelectVenues] = useState(venues);

  // eslint-disable-next-line react/no-unstable-nested-components
  function DisplayVenues() {
    return selectVenues.length > 1 && page !== "recipeAnalysis" ? (
      <MDBox>
        <Autocomplete
          value={selectVenues.filter((a) => a.id === id)[0]}
          options={selectVenues}
          renderOption={(params, option) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <li {...params} key={option.id}>
              {option.operation}
            </li>
          )}
          renderInput={(params) => <MDInput {...params} variant="standard" />}
          getOptionLabel={(a) => a.operation}
          onChange={(_e, newVenue) => changeVenue({ newVenue })}
          disableClearable
          sx={{ width: 120 }}
          PopperComponent={PopperMy}
        />
      </MDBox>
    ) : (
      <MDBox>{operation}</MDBox>
    );
  }

  useEffect(() => {
    const dashboardSelectVenues = venues.filter((a) => !templateVenues.includes(a.id));
    // const dashboardSetVenues = venues.filter(
    //   (a) => ![...templateVenues, ...demoVenues].includes(a.id)
    // );
    setSelectVenues(page === "dashboard" ? dashboardSelectVenues : venues);
    // if (page === "dashboard" && templateVenues.includes(id)) changeVenue(dashboardSetVenues[0]);
  }, [page, venues]);

  return venues.length ? <DisplayVenues /> : <div>...venues...</div>;
}
// Venue.defaultProps = {
// };
Venue.propTypes = {
  page: PropTypes.string.isRequired,
  venue: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.bool, PropTypes.func])
  ).isRequired,
};
export default Venue;
