/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useContext, useEffect, useState, useMemo } from "react";

// prop-types is a library for typechecking of props. https://www.npmjs.com/package/prop-types
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
// import MDBadgeDot from "components/MDBadgeDot";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";

// HospoSure
import { AppContext } from "GlobalContext";
import { config } from "config"; // loading
import useNotices from "hooks/useNotices";
import PageTitleBox from "components/PageTitleBox";
import Error from "components/Error";
import useLookups from "hooks/useLookups";
import { camelToTitleCase, formatNums, formatDecimal, setDerivedKeys, _ } from "general";
import VenueProfile from "components/VenueSettings";
import Loading from "components/Loading";
// import colors from "assets/theme/base/colors";
import DataTable from "./components/DataTable";
import PieChart from "./components/PieChart";
import DefaultStatisticsCard from "./components/DefaultStatisticsCard";

function Dashboard({ route }) {
  const renderNotice = useNotices();
  const { userProfile, venue, membership } = useContext(AppContext);
  const { isAdvanced, admin } = userProfile;
  const { userGroup, openForBusiness, dashboardBuild } = venue;
  const pageCfg = config.filter((a) => a.route === route && a.build === dashboardBuild)[0];
  const { icon, title, description, action, keys, keyMetricsKeys, warning } = pageCfg;
  // const [load, setLoad] = useState(loading.start);
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [formattedItems, setFormattedItems] = useState([]);
  const [keyMetrics, setKeyMetrics] = useState();
  const { lookups } = useLookups({ pageCfg, userGroup }); // setLoad
  // const backgroundColors = Object.values(colors.pie);

  useEffect(() => {
    if (_.exists(userGroup) && _.exists(lookups) && _.exists(lookups.Operation)) {
      // setLoad(loading.lookupsFetched);
      const { Period, Operation } = lookups;
      const { openHours, periodId } = Operation[0];
      const periods = [...Period];
      periods.unshift({
        id: "hour",
        period: "Hour",
        perYear:
          openHours && periodId && openHours * Period.filter((a) => a.id === periodId)[0].perYear,
      });
      setItems(
        periods.map((a) => ({
          ...a,
          ...setDerivedKeys({ item: a, keys: { ...keys, ...keyMetricsKeys }, lookups }),
        }))
      );
      // setLoad(loading.itemsFetched);
    } else {
      setItems([]);
    }
    return () => setItems([]);
  }, [lookups, userGroup]);

  useEffect(() => {
    setFilteredItems(
      items.map((a) =>
        Object.assign(
          {},
          ...Object.entries(a)
            .filter(([k]) => k in keys && keys[k].keyType.display !== "hidden") //  && ![m2mKey].includes(k)
            .map(([k, v]) => ({ [k]: v }))
        )
      )
    );
    const withValues = true;
    if (pageCfg && keyMetricsKeys && _.exists(items)) {
      setKeyMetrics(
        formatNums(
          {
            item: _.keep(items[0], keyMetricsKeys),
            keys: keyMetricsKeys,
          },
          withValues
        )
      );
    }
  }, [items]);

  useEffect(() => {
    setFormattedItems(
      formatNums({
        item: filteredItems,
        keys,
      })
    );
  }, [filteredItems]);

  const dataTableData = useMemo(() => {
    const header = "period";
    const data =
      formattedItems &&
      formattedItems.length > 0 &&
      Object.keys(formattedItems[0]).map((a) =>
        formattedItems
          .map((b) => ({ [b[header]]: b[a] }))
          .reduce((c, d) => Object.assign(c, d), { [header]: a })
      );
    if (data.length > 0) {
      data.slice(1).forEach((a, i) => {
        data[i + 1][header] = keys[a[header]].name || camelToTitleCase(a[header]);
      });
    }
    return data.length > 0
      ? {
          columns: Object.values(data[0]).map((a) => ({
            Header: a,
            accessor: a,
            // width: "20%",
          })),
          rows: data.slice(1),
        }
      : null;
  }, [formattedItems]);

  const pieChartData = useMemo(() => {
    let output;
    if (filteredItems.length > 0) {
      const pieItems = Object.entries(filteredItems[0])
        .filter(([k]) => !["period"].includes(k) && !k.match(/revenue/))
        .filter(([k, v]) => (k.match(/netProfit/) ? v > 0 : true));
      output = {
        labels: pieItems.map(([k]) => keys[k].name || camelToTitleCase(k)),
        datasets: {
          // backgroundColors,
          // data: pieItems.map(([k, v]) => k && formatDecimal(Math.abs(v), "ndd")),
          data: pieItems.map(([, v]) =>
            formatDecimal(
              Math.abs(
                (v /
                  (dashboardBuild
                    ? filteredItems[0].revenue_stage2
                    : filteredItems[0].revenue_stage1)) *
                  100
              ),
              "nd"
            )
          ),
        },
      };
    }
    return output;
  }, [filteredItems]);

  return (
    <DashboardLayout>
      {renderNotice("Global")}
      <DashboardNavbar {...{ venue, membership, admin }} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PageTitleBox
            icon={icon}
            title={title}
            description={description}
            action={action && openForBusiness ? action.open : action && action.startup}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <VenueProfile {...{ venue, show: ["dashboardBuild"], isAdvanced }} />
          </Grid>
        </Grid>
        {_.exists(items) ? (
          <Grid item xs={12}>
            <Grid container spacing={3}>
              {dataTableData?.rows?.length ? (
                <Grid item xs={12}>
                  <Card>
                    <MDBox p={3} lineHeight={1}>
                      <DataTable
                        table={dataTableData}
                        showTotalEntries={false}
                        entriesPerPage={false}
                        isSorted={false}
                        noEndBorder
                      />
                    </MDBox>
                  </Card>
                </Grid>
              ) : (
                // <Grid item xs={12}>
                //   <Card>
                //     <MDBox p={3} lineHeight={1}>
                //       <MDTypography variant="button" color="text">
                //         {warning}
                //       </MDTypography>
                //     </MDBox>
                //   </Card>
                // </Grid>
                <Grid item xs={12}>
                  <Error message={warning} />
                </Grid>
              )}
              {keyMetrics &&
                Object.entries(keyMetrics).map(([k, v], i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Grid key={i} item xs={4} lg={3}>
                    <DefaultStatisticsCard
                      title={keyMetricsKeys[k]?.name || camelToTitleCase(k)}
                      value={v.v}
                      label={v.l}
                      warning={pageCfg?.keyMetricsKeys[k]?.warning}
                    />
                  </Grid>
                ))}
              {pieChartData?.labels?.length > 0 && (
                <Grid item md={8} lg={6}>
                  <Card>
                    {/* <MDBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      pt={2}
                      px={2}
                    >
                      <MDTypography variant="h6" color="text">
                        Revenue Split
                      </MDTypography>
                    </MDBox> */}
                    <MDBox>
                      {/* <Grid container alignItems="left">
                        <Grid item xs={10}> */}
                      <PieChart
                        title="Revenue Split"
                        description="Values shown are %."
                        chart={pieChartData}
                        height="10rem"
                      />
                      {/* <MDBox
                            display="flex"
                            alignItems="center"
                            justifyContent="space-evenly"
                            mb={2}
                          >
                            <MDTypography fontSize="0.75rem">
                              Values shown are $ per Hour
                            </MDTypography>
                          </MDBox> */}
                      {/* </Grid> */}
                      {/* <Grid item xs={4}>
                          <MDBox pr={1}>
                            {pieChartData.labels.map((a, i) => (
                              // eslint-disable-next-line react/no-array-index-key
                              <MDBox key={i} mb={1}>
                                <MDBadgeDot
                                  // color={pieChartData.datasets.backgroundColors[i]}
                                  sx={{
                                    color: pieChartData.datasets.backgroundColors[i],
                                  }}
                                  size="sm"
                                  badgeContent={a}
                                />
                              </MDBox>
                            ))}
                          </MDBox>
                        </Grid> */}
                      {/* </Grid> */}
                    </MDBox>
                  </Card>
                </Grid>
              )}
            </Grid>
          </Grid>
        ) : (
          <Loading type="linear" />
        )}
      </Grid>
      <Footer />
    </DashboardLayout>
  );
}
Dashboard.propTypes = {
  route: PropTypes.string.isRequired,
};

export default Dashboard;
