import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props. https://www.npmjs.com/package/prop-types
import PropTypes from "prop-types";

import { _ } from "general";
import { search, list } from "database";
import { adminTypes, config, loading } from "config";

function useLookups({ pageCfg, userGroup, refresh, setLoad }) {
  const { keys, keyMetricsKeys, hasMany, admin, warning } = pageCfg;
  const hasManyKeys = hasMany && config.filter((a) => a.type === hasMany.type)[0].keys;
  const [lookups, setLookups] = useState();
  // const [newItemLookupValues, setNewItemLookupValues] = useState();
  // const [lookupsFetched, setLookupsFetched] = useState(false);

  async function fetchLookup(type) {
    const { sort } = config.filter((a) => a.type === type)[0].initial || {};
    const { dataSet } = adminTypes.includes(type)
      ? await list({ type })
      : await search({
          type,
          filter: { userGroup: { eq: userGroup } },
          sort,
          all: true,
        });
    return (
      _.exists(dataSet) && {
        [type]: dataSet,
      }
    );
  }

  useEffect(() => {
    (async () => {
      let isCancelled = false;
      if (keys && (admin || userGroup || userGroup === "")) {
        // fetch all LOV lookups
        const selectTypes = [
          ...new Set(
            Object.values({ ...keys, ...keyMetricsKeys, ...hasManyKeys })
              .filter((a) => a.keyType.display === "select")
              .map((a) => a.type)
              .flat(2)
          ),
        ];
        const factorTypes = [
          ...new Set(
            Object.values({ ...keys, ...keyMetricsKeys, ...hasManyKeys })
              .filter((a) => a.factors)
              .map((a) => a.factors)
              .flat(2)
          ),
        ];
        const lookupTypes = [...new Set([...selectTypes, ...factorTypes])];
        const fetchedLookups =
          _.exists(lookupTypes) &&
          Object.assign({}, ...(await Promise.all(lookupTypes.map((a) => fetchLookup(a)))));
        if (!isCancelled) {
          if (fetchedLookups) setLookups(fetchedLookups);
          if (setLoad) {
            const fetchedLookupsKeys = Object.keys(fetchedLookups);
            setLoad(
              fetchedLookups && !lookupTypes.every((a) => fetchedLookupsKeys.includes(a)) && warning
                ? loading.lookupsMissing
                : loading.lookupsFetched
            );
          }
        }
      }
      return () => {
        isCancelled = true;
      };
    })();
  }, [keys, userGroup, refresh]);

  return { lookups };
}
useLookups.defaultProps = {
  refresh: false,
  setLoad: false,
};
useLookups.propTypes = {
  pageCfg: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.object,
      PropTypes.array,
    ])
  ).isRequired,
  venue: PropTypes.string.isRequired,
  refresh: PropTypes.bool,
  setLoad: PropTypes.func,
};

// const assignLookups = ({ item, initialCreateState, keys, lookups }) => {
//   const tmp = Object.entries(item)
//     .map(([k, v]) =>
//       typeof initialCreateState[k] === "object" &&
//       !Array.isArray(initialCreateState[k]) &&
//       (v === null || v === undefined) &&
//       lookups &&
//       lookups[Object.keys(initialCreateState[k])[0]] &&
//       lookups[Object.keys(initialCreateState[k])[0]].length
//         ? { [k]: lookups[Object.keys(initialCreateState[k])[0]][0].id }
//         : { [k]: v }
//     )
//     .reduce((p, c) => Object.assign(p, c), {});

//   const tmp2 = Object.assign(
//     {},
//     ...Object.entries(item).map(([k, v]) => ({ [k]: v })),
//     ...Object.entries(keys)
//       .filter(([k, v]) => k && v.keyType === "select")
//       .map(([k, v]) => ({ [k]: lookups[v.type][0].id }))
//   );

//   return tmp;
// };

// https://www.npmjs.com/package/prop-types
// assignLookups.propTypes = {
//   item: PropTypes.objectOf(
//     PropTypes.oneOfType([
//       PropTypes.string,
//       PropTypes.number,
//       PropTypes.bool,
//       PropTypes.object,
//       PropTypes.array,
//     ])
//   ).isRequired,
//   initialCreateState: PropTypes.objectOf(
//     PropTypes.oneOfType([
//       PropTypes.string,
//       PropTypes.number,
//       PropTypes.bool,
//       PropTypes.object,
//       PropTypes.array,
//     ])
//   ).isRequired,
//   lookups: PropTypes.objectOf(PropTypes.array).isRequired,
// };

export default useLookups; // assignLookups
