// prop-types is a library for typechecking of props. https://www.npmjs.com/package/prop-types
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function Bonus({ icon, message, link }) {
  return (
    <MDBox display="flex" flexDirection={{ xs: "row" }} alignItems="center" p={1}>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginRight="20px"
        sx={link ? { cursor: "pointer" } : null}
        onClick={() => {
          if (link) window.location.href = link;
        }}
      >
        <Icon fontSize="large" color="success">
          {icon}
        </Icon>
      </MDBox>
      <MDTypography variant="button">
        {/* Required to render html text */}
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: message }} />
        {/* {message} */}
      </MDTypography>
    </MDBox>
  );
}
Bonus.defaultProps = {
  link: "",
};
Bonus.propTypes = {
  icon: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  link: PropTypes.string,
};

export default Bonus;
