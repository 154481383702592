/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components
// import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";
// import Tooltip from "@mui/material/Tooltip";
// import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

// // Material Dashboard 2 PRO React base styles
// import colors from "assets/theme/base/colors";
// import typography from "assets/theme/base/typography";

// HospoSure
import ProfileCardHeader from "components/ProfileCardHeader";

function ProfileInfoCard({ title, description, info, shadow, signOut }) {
  // social, action,
  const labels = [];
  const values = [];
  // const { socialMediaColors } = colors;
  // const { size } = typography;

  // Convert this form `objectKey` of the object key in to this `object key`
  Object.keys(info).forEach((el) => {
    if (el.match(/[A-Z\s]+/)) {
      const uppercaseLetter = Array.from(el).find((i) => i.match(/[A-Z]+/));
      const newElement = el.replace(uppercaseLetter, ` ${uppercaseLetter.toLowerCase()}`);

      labels.push(newElement);
    } else {
      labels.push(el);
    }
  });

  // Push the object values into the values array
  Object.values(info).forEach((el) => values.push(el));

  // Render the card info items
  const renderItems = labels.map((label, key) => (
    <MDBox key={label} display="flex" py={1} pr={2}>
      {/* <MDTypography variant="button" fontWeight="bold" textTransform="capitalize"> */}
      <MDTypography variant="body2" fontWeight="bold" textTransform="capitalize">
        {label}: &nbsp;
      </MDTypography>
      <MDTypography variant="body2">&nbsp;{values[key]}</MDTypography>
    </MDBox>
  ));

  // Render the card social media icons
  // const renderSocial =
  //   social &&
  //   social.map(({ link, icon, color }) => (
  //     <MDBox
  //       key={color}
  //       component="a"
  //       href={link}
  //       target="_blank"
  //       rel="noreferrer"
  //       fontSize={size.lg}
  //       color={socialMediaColors[color].main}
  //       pr={1}
  //       pl={0.5}
  //       lineHeight={1}
  //     >
  //       {icon}
  //     </MDBox>
  //   ));

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox p={3}>
        <ProfileCardHeader
          {...{
            icon: "account_circle",
            title,
            description,
            buttons: [
              {
                key: 0,
                onClick: signOut,
                label: "Sign Out",
                display: signOut,
              },
            ],
          }}
        />

        {/* <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start" }}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <MDBox>
            <MDBox>
              <MDBox>
                <MDTypography variant="h5">{title}</MDTypography>
                {action && (
                  <MDTypography
                    component={Link}
                    to={action.route}
                    variant="body2"
                    color="secondary"
                  >
                    <Tooltip title={action.tooltip} placement="top">
                      <Icon>edit</Icon>
                    </Tooltip>
                  </MDTypography>
                )}
              </MDBox>
              <MDTypography variant="button" color="text" fontWeight="light">
                {description}
              </MDTypography>
            </MDBox> */}
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
          pt={2}
        >
          <MDBox>
            {renderItems}
            {/* {social && (
              <MDBox display="flex" py={1} pr={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  social: &nbsp;
                </MDTypography>
                {renderSocial}
              </MDBox>
            )} */}
          </MDBox>
        </MDBox>
        {/* </MDBox>
          {signOut && (
            <MDBox pt={2}>
              <MDButton variant="outlined" color="secondary" onClick={signOut}>
                sign out
              </MDButton>
            </MDBox>
          )} */}
        {/* </MDBox> */}
      </MDBox>
    </Card>
  );
}

// Setting default props for the ProfileInfoCard
ProfileInfoCard.defaultProps = {
  shadow: true,
  // social: null,
  // action: null,
  signOut: null,
};
ProfileInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  info: PropTypes.objectOf(PropTypes.string).isRequired,
  // social: PropTypes.arrayOf(PropTypes.object),
  // action: PropTypes.shape({
  //   route: PropTypes.string.isRequired,
  //   tooltip: PropTypes.string.isRequired,
  // }),
  shadow: PropTypes.bool,
  signOut: PropTypes.func,
};

export default ProfileInfoCard;
