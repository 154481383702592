export const searchSuppliers = /* GraphQL */ `
  query SearchSuppliers(
    $filter: SearchableSupplierFilterInput
    $sort: [SearchableSupplierSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableSupplierAggregationInput]
  ) {
    searchSuppliers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        userGroup
        supplier
        cost_stage1
        cost_stage1PeriodId
        cost_stage1Period {
          id
          sort
          period
          perYear
        }
        ingredients {
          items {
            id
            userGroup
            ingredient
            storeId
            supplierId
            cost
            costUnitId
          }
          nextToken
        }
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchIngredients = /* GraphQL */ `
  query SearchIngredients(
    $filter: SearchableIngredientFilterInput
    $sort: [SearchableIngredientSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableIngredientAggregationInput]
  ) {
    searchIngredients(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        userGroup
        ingredient
        storeId
        store {
          id
          sort
          store
        }
        supplierId
        supplier {
          id
          userGroup
          supplier
          cost_stage1
          cost_stage1PeriodId
        }
        cost
        costUnitId
        costUnit {
          id
          sort
          ingredientUnit
          factor
          recipeUnit
        }
        recipeIngredients {
          items {
            id
            userGroup
            recipeId
            ingredientId
            quantity
          }
          nextToken
        }
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
// export const listRecipes = /* GraphQL */ `
//   query ListRecipes($filter: ModelRecipeFilterInput, $limit: Int, $nextToken: String) {
//     listRecipes(filter: $filter, limit: $limit, nextToken: $nextToken) {
//       items {
//         id
//         userGroup
//         recipe
//         portionSellingPrice
//         portionSales
//         portionSalesPeriodId
//         portionSalesPeriod {
//           id
//           sort
//           period
//           perYear
//         }
//         portionCount
//         prepTime
//         serviceTimePerPortion
//         recipeIngredients {
//           items {
//             id
//             userGroup
//             recipeId
//             ingredientId
//             ingredient {
//               id
//               cost
//             }
//             quantity
//           }
//           nextToken
//         }
//       }
//       nextToken
//     }
//   }
// `;
export const searchRecipes = /* GraphQL */ `
  query SearchRecipes(
    $filter: SearchableRecipeFilterInput
    $sort: [SearchableRecipeSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableRecipeAggregationInput]
  ) {
    searchRecipes(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        userGroup
        recipe
        portionSellingPrice
        portionSales
        portionSalesPeriodId
        portionSalesPeriod {
          id
          sort
          period
          perYear
        }
        portionCount
        prepTime
        serviceTimePerPortion
        recipeIngredients {
          items {
            id
            userGroup
            recipeId
            ingredientId
            ingredient {
              id
              ingredient
              storeId
              cost
              costUnitId
              costUnit {
                id
                sort
                ingredientUnit
                factor
                recipeUnit
              }
            }
            quantity
          }
          nextToken
        }
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchRecipeIngredients = /* GraphQL */ `
  query SearchRecipeIngredients(
    $filter: SearchableRecipeIngredientFilterInput
    $sort: [SearchableRecipeIngredientSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableRecipeIngredientAggregationInput]
  ) {
    searchRecipeIngredients(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        userGroup
        recipeId
        ingredientId
        # recipe {
        #   id
        #   userGroup
        #   recipe
        #   portionSellingPrice
        #   portionSales
        #   portionSalesPeriodId
        #   portionCount
        #   prepTime
        #   serviceTimePerPortion
        #   createdAt
        #   updatedAt
        # }
        ingredient {
          id
          userGroup
          ingredient
          storeId
          supplierId
          cost
          costUnitId
          costUnit {
            id
            sort
            ingredientUnit
            factor
            recipeUnit
          }
          createdAt
          updatedAt
        }
        quantity
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const listMemberships = /* GraphQL */ `
  query ListMemberships($filter: ModelMembershipFilterInput, $limit: Int, $nextToken: String) {
    listMemberships(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        updatedAt
        ownerId
        subscriptionId
        userId
        subscriptionStatus
        email
        customerName
        cancelAt
        cancelAtPeriodEnd
        canceledAt
        cancellationComment
        cancellationFeedback
        cancellationReason
        currency
        currentPeriodEnd
        currentPeriodStart
        planId
        planAmount
        planInterval
        planIntervalCount
        productId
        quantity
        # createdAt
      }
      nextToken
    }
  }
`;
export const searchMemberships = /* GraphQL */ `
  query SearchMemberships(
    $filter: SearchableMembershipFilterInput
    $sort: [SearchableMembershipSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableMembershipAggregationInput]
  ) {
    searchMemberships(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        updatedAt
        ownerId
        subscriptionId
        userId
        subscriptionStatus
        email
        customerName
        cancelAt
        cancelAtPeriodEnd
        canceledAt
        cancellationComment
        cancellationFeedback
        cancellationReason
        currency
        currentPeriodEnd
        currentPeriodStart
        planId
        planAmount
        planInterval
        planIntervalCount
        productId
        quantity
        venues {
          items {
            id
            operation
          }
          nextToken
          # __typename
        }
        createdAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
