// import PropTypes from "prop-types"; // prop-types is a library for typechecking of props. https://www.npmjs.com/package/prop-types

import { useState, useEffect } from "react";
import { fetchAuthSession } from "aws-amplify/auth"; // getCurrentUser

function useAuthUser() {
  const [
    {
      credentials = undefined,
      tokens: {
        idToken: {
          payload: {
            "cognito:groups": userGroups,
            email,
            email_verified: emailVerified,
            name,
            sub: userId,
          },
        },
      } = {
        idToken: {
          payload: {
            userGroups: undefined,
            email: undefined,
            email_verified: undefined,
            name: undefined,
            sub: undefined,
          },
        },
      },
    },
    setAuthUser,
  ] = useState({});

  useEffect(() => {
    // Force refresh on user group permissions
    (async () => {
      // const { username, signInDetails } = await getUser();
      // await getSession();
      setAuthUser({ ...(await fetchAuthSession({ forceRefresh: true })) }); // ...(await getCurrentUser()),
    })();
  }, []);

  return { credentials, userGroups, userId, email, emailVerified, name };
}
// useAuthUser.propTypes = {
// };

export default useAuthUser;
