// import { API } from "aws-amplify";
import { generateClient } from "aws-amplify/api";

import * as mutations from "graphql/mutations";
import * as generatedQueries from "graphql/queries";
import * as customQueries from "graphql/customQueries";
import { pluralize } from "general";

const client = generateClient();
const queries = { ...generatedQueries, ...customQueries };

async function create({ entry, type }) {
  const mutation = `create${type}`;
  const apiData = await client.graphql({
    query: mutations[mutation],
    variables: {
      input: entry,
    },
  });
  return apiData.data[mutation];
}

async function update({ entry, type }) {
  const mutation = `update${type}`;
  const apiData = await client.graphql({
    query: mutations[mutation],
    variables: {
      input: entry,
    },
  });
  return apiData.data[mutation];
}

async function del({ id, type }) {
  const mutation = `delete${type}`;
  await client.graphql({
    query: mutations[mutation],
    variables: { input: { id } },
  });
}

async function get({ id, type }) {
  const query = `get${type}`;
  const apiData = await client.graphql({
    query: queries[query],
    variables: { id },
  });
  const data = apiData.data[query];
  return { data };
}

// list is only used for admin types
const list = async ({ type, filter }) => {
  const query = `list${pluralize(type)}`;
  const variables = {
    ...(filter && { filter }),
  };
  let itemsList = [];
  let token;
  const nextList = async () => {
    const { items, nextToken } = (
      await client.graphql({
        query: queries[query],
        variables,
        nextToken: token,
      })
    ).data[query];
    itemsList = [...itemsList, ...items];
    token = nextToken;
  };
  await nextList();
  // eslint-disable-next-line no-await-in-loop
  while (token) await nextList();
  const dataSet = itemsList.length > 1 ? itemsList.sort((a, b) => a.sort - b.sort) : itemsList;
  return { dataSet };
};

// search is used for all types except admin
async function search({ type, filter, sort, nextToken, from, maxItems, all }) {
  const variables = {
    ...(filter && { filter }),
    ...(sort && { sort: { direction: sort.ascending ? "asc" : "desc", field: sort.key } }),
    ...(maxItems && { limit: maxItems }),
    ...(from && { from }),
  };
  // const query =
  //   type.slice(-1) === "y" ? `search${type.slice(0, type.length - 1)}ies` : `search${type}s`;
  const query = `search${pluralize(type)}`;
  let dataSet = [];
  let newNextToken = nextToken;
  let total;

  const searchMore = async () => {
    let isCancelled = false;
    const response = (
      await client.graphql({
        query: queries[query],
        variables: { ...variables, nextToken: newNextToken },
      })
    ).data[query];
    if (!isCancelled) {
      const { items } = response;
      total = response.total || 0;
      if (items && items.length) dataSet = [...dataSet, ...items];
      newNextToken = dataSet.length < total ? response.nextToken : null;
    }
    return () => {
      isCancelled = true;
    };
  };

  await searchMore();
  // eslint-disable-next-line no-console
  // console.log({ total });
  // eslint-disable-next-line no-await-in-loop
  while (all && newNextToken) await searchMore();
  return { dataSet, newNextToken, total };
}

export { create, update, del, get, list, search };
