// prop-types is a library for typechecking of props. https://www.npmjs.com/package/prop-types
import PropTypes from "prop-types";

import { useEffect, useState } from "react";

// HospoSure
import { create, search } from "database";
import { _, pluralize } from "general";
import { config } from "config";

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Popper from "@mui/material/Popper";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

const styles = () => ({
  popper: { width: "fit-content" },
});
function PopperMy(props) {
  return <Popper {...props} style={styles.popper} placement="bottom-start" />;
}

// const getNewId = ({ oldId, primaryKey, newDataSet, oldDataSet }) => {
//   const oldItem = oldDataSet.filter((a) => a.id === oldId);
//   const primaryName = _.exists(oldItem) ? oldItem[0][primaryKey].trim() : null;
//   const newItem = newDataSet.filter((a) => a[primaryKey].trim() === primaryName);
//   const newId = _.exists(newItem) ? newItem[0].id : null;
//   return newId;
// };

const getNewId = ({ oldId, primaryKey, newDataSet, oldDataSet }) => {
  const oldItem = oldDataSet.find((a) => a && a.id === oldId);
  if (_.exists(oldItem)) {
    const newItem = newDataSet.find((a) => a[primaryKey].trim() === oldItem[primaryKey].trim());
    return _.exists(newItem) ? newItem.id : null;
  }
  return null;
};

function Importer({
  userGroup,
  venues,
  items,
  pageCfg,
  demoVenues,
  templateVenues,
  postNotification,
  itemTotal,
  isAdvanced,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const venuesToSelect = venues.filter((a) => ![...demoVenues, ...templateVenues].includes(a.id));
  const [importTo, setImportTo] = useState(venues[0]);

  const importItems = async ({ itemsToImport, cfg }) => {
    const { keys, type } = cfg;
    const imports = await Promise.all(
      itemsToImport.map(async (item) =>
        create({
          entry: { ..._.trim({ item, keys, dataMgmt: true }), userGroup: importTo.userGroup },
          type,
        })
      )
    );
    // const qtyTotal = all ? allItems.length : items.length;
    // const qtyToImport = itemsToImport.length;
    // const qtyExisting = qtyTotal - qtyToImport;
    return imports;
  };

  const importItemsNoDuplicates = async ({ all, itemsList, cfg }) => {
    const { type, keys, primaryKey } = cfg;
    const applyImportValues = (item) => ({
      ...item,
      // ...Object.entries(keys)
      //   .map(([k, v]) => [k, _.keep(v, { setOnImport: null })])
      //   .filter(([k, v]) => k && _.exists(v))
      //   .map(([k, v]) => ({ [k]: Object.values(v)[0] })),
      ...Object.assign(
        {},
        ...Object.entries(keys)
          .filter(([k, v]) => k && Object.keys(v).includes("setOnImport"))
          .map(([k, v]) => ({ [k]: v.setOnImport }))
      ),
    });
    const db = await Promise.all([
      all
        ? search({
            type,
            filter: { userGroup: { eq: userGroup } },
            all,
          })
        : { dataSet: itemsList },
      search({ type, filter: { userGroup: { eq: importTo.userGroup } }, all: true }),
    ]);
    const mainItems = db.shift().dataSet;
    const existingItems = db.shift().dataSet;
    const itemsToImport = mainItems
      .filter(
        (a) => a && !existingItems.map((b) => b[primaryKey].trim()).includes(a[primaryKey].trim())
      )
      .map((a) => applyImportValues(a));

    const importedItems = await importItems({ itemsToImport, cfg });
    return { mainItems, itemsToImport, importedItems };
  };

  const importToVenue = async ({ all }) => {
    setAnchorEl(null);
    const { mainItems, itemsToImport, importedItems } = await importItemsNoDuplicates({
      all,
      itemsList: items,
      cfg: pageCfg,
    });
    const { additionalImport } = pageCfg;
    if (_.exists(importedItems) && additionalImport) {
      const { type, idKey, pointer, key2Type, key2IdKey, key2Pointer } = additionalImport;
      const additionalCfg = config.filter((a) => a.type === type)[0];
      const key2Cfg = config.filter((a) => a.type === key2Type)[0];
      const additionalItems = itemsToImport.map((a) => a[pointer].items).flat();
      const key2OldItems = [
        ...new Map(
          additionalItems
            .map((a) => a[key2Pointer])
            .filter((a) => a)
            .map((a) => [a.id, a])
        ).values(),
      ];
      const db2 = await Promise.all([
        importItemsNoDuplicates({
          itemsList: key2OldItems,
          cfg: key2Cfg,
        }),
        search({
          type: key2Type,
          filter: { userGroup: { eq: importTo.userGroup } },
          all: true,
        }),
      ]);
      const key2NewItems = db2.shift().importedItems;
      const key2ExistingItems = db2.shift().dataSet;

      const additionalItemsToImport = additionalItems
        .map((a) => {
          const newIdKeyId = getNewId({
            oldId: a[idKey],
            primaryKey: pageCfg.primaryKey,
            newDataSet: importedItems,
            oldDataSet: mainItems,
          });
          const newKey2Id = getNewId({
            oldId: a[key2IdKey],
            primaryKey: key2Cfg.primaryKey,
            newDataSet: [...key2NewItems, ...key2ExistingItems],
            oldDataSet: key2OldItems,
          });
          return newIdKeyId && newKey2Id
            ? {
                ...a,
                [idKey]: newIdKeyId,
                [key2IdKey]: newKey2Id,
              }
            : null;
        })
        .filter((a) => a);
      importItems({ itemsToImport: additionalItemsToImport, cfg: additionalCfg });
    }
    const qtyImported = importedItems.filter((a) => a).length;
    const qtyExisting = (all ? itemTotal : items.length) - qtyImported;
    const { type, primaryKey } = pageCfg;
    const notification = {
      level: qtyImported > 0 ? "success" : "warning",
      title: `Import ${pluralize(type)}`,
      content: `${qtyImported} ${
        qtyImported !== 1 ? pluralize(primaryKey) : primaryKey
      } imported to ${importTo.operation}.`.concat(
        qtyExisting > 0
          ? ` ${qtyExisting} ${qtyExisting > 1 ? pluralize(primaryKey) : primaryKey} already ${
              qtyExisting > 1 ? "exist" : "exists"
            }.`
          : ""
      ),
    };
    postNotification(notification);
  };

  useEffect(() => {
    setImportTo(venuesToSelect[0]);
  }, [venues]);

  const { type } = pageCfg;

  return (
    // <Grid item xs={12}>
    // <Grid container direction="row-reverse">
    <Grid item xs={12} sm={8} md={6} lg={4}>
      <Card>
        <MDBox px={2} py={1.5}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <MDTypography variant="caption" fontWeight="regular" color="text">
                Import to
              </MDTypography>
            </Grid>
            <Grid item xs={6}>
              {venuesToSelect.length > 1 ? (
                <MDBox color="inherit">
                  <Autocomplete
                    value={importTo}
                    // options={venuesToSelect.map((a) => a.operation)}
                    options={venuesToSelect}
                    renderOption={(params, option) => (
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      <li {...params} key={option.id}>
                        {option.operation}
                      </li>
                    )}
                    renderInput={(params) => <MDInput {...params} variant="standard" />}
                    getOptionLabel={(a) => a.operation}
                    onChange={(_e, newVenue) => setImportTo(newVenue)} // e.target.textContent
                    disableClearable
                    // sx={{ width: 120 }}
                    PopperComponent={PopperMy}
                  />
                </MDBox>
              ) : (
                <MDBox color="inherit">
                  <MDTypography variant="button" fontWeight="regular">
                    {importTo.operation}
                  </MDTypography>
                </MDBox>
              )}
            </Grid>
            <Grid item xs={2}>
              <MDBox align="right">
                <Tooltip
                  title={!isAdvanced ? `Import ${pluralize(type)} to ${importTo.operation}` : ""}
                  placement="top"
                >
                  <span>
                    <MDButton
                      variant="gradient"
                      color="secondary"
                      iconOnly
                      type="button"
                      // title={`Import ${items.length} ${title} to ${importTo}`}
                      onClick={handleClick}
                      disabled={itemTotal < 1}
                      // onClick={() => importToVenue()}
                    >
                      <Icon fontSize="large">input</Icon>
                    </MDButton>
                  </span>
                </Tooltip>
              </MDBox>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {_.exists(items) && items.length <= itemTotal && (
                  <MenuItem onClick={importToVenue}>
                    Import {items.length > 1 ? "these" : "this"} {items.length}{" "}
                    {items.length > 1 ? pluralize(type) : type} to {importTo.operation}
                  </MenuItem>
                )}
                {itemTotal > items.length && (
                  <MenuItem onClick={() => importToVenue({ all: true })}>
                    Import all {itemTotal} {pluralize(type)} to {importTo.operation}
                  </MenuItem>
                )}
              </Menu>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </Grid>
    // </Grid>
    // </Grid>
  );
}
Importer.defaultProps = {
  // venue: "",
  venues: [],
  items: [],
  isAdvanced: false,
};
Importer.propTypes = {
  userGroup: PropTypes.string.isRequired,
  venues: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object])
    )
  ),
  items: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object])
    )
  ),
  // type: PropTypes.string.isRequired,
  pageCfg: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.number,
      PropTypes.array,
      PropTypes.bool,
    ])
  ).isRequired,
  demoVenues: PropTypes.arrayOf(PropTypes.string).isRequired,
  templateVenues: PropTypes.arrayOf(PropTypes.string).isRequired,
  postNotification: PropTypes.func.isRequired,
  itemTotal: PropTypes.number.isRequired,
  isAdvanced: PropTypes.bool,
};

export default Importer;
