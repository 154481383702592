// prop-types is a library for typechecking of props. https://www.npmjs.com/package/prop-types
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

export default function ProfileCardHeader({ icon, title, description, buttons }) {
  return (
    <MDBox display="flex" justifyContent="space-between">
      <MDBox
        // display="flex"
        justifyContent="space-between"
        alignItems={{ xs: "flex-start" }}
        flexDirection={{ xs: "column", sm: "row" }}
      >
        <MDBox
          display="flex"
          // justifyContent="flex-start"
          flexDirection={{ xs: "row" }}
          alignItems="center"
        >
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgColor="secondary"
            color="white"
            width="2rem"
            height="2rem"
            borderRadius="50%"
            sx={{ fontSize: ({ typography: { size } }) => size.lg }}
            flexDirection={{ xs: "column", sm: "row" }}
            marginRight="10px"
          >
            <Icon fontSize="inherit">{icon}</Icon>
          </MDBox>

          <MDBox>
            <MDTypography variant="h5">{title}</MDTypography>
          </MDBox>
        </MDBox>
        <MDBox>
          <MDTypography variant="body2" fontWeight="light">
            {description}
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox
        display="inline-flex"
        justifyContent="flex-end"
        alignItems="flex-start"
        flexDirection={{ xs: "column", sm: "row" }}
        gap={1}
      >
        {buttons?.map(({ key, link, onClick, label, display, target, variant }) =>
          display ? (
            <MDBox key={key}>
              <MDButton
                variant={variant || "gradient"}
                color={variant ? "primary" : "secondary"}
                href={link}
                onClick={() => (link ? undefined : onClick())}
                target={target}
              >
                {label}
              </MDButton>
            </MDBox>
          ) : null
        )}
      </MDBox>
    </MDBox>
  );
}
ProfileCardHeader.defaultProps = {
  icon: undefined,
  buttons: undefined,
};
ProfileCardHeader.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.func, PropTypes.number])
    )
  ),
};
