/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useContext } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
// import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";

// Settings page components

// HospoSure
import { AppContext } from "GlobalContext";
import useNotices from "hooks/useNotices";
import PlatformSettings from "./components/PlatformSettings";

function Settings() {
  const renderNotice = useNotices();
  const { userProfile } = useContext(AppContext);

  return (
    <DashboardLayout>
      {renderNotice("Global")}
      <DashboardNavbar />
      {/* <BaseLayout> */}
      {/* <MDBox mt={4}> */}
      <Grid container spacing={3}>
        {/* <Grid item xs={12} lg={3}>
            <Sidenav />
          </Grid> */}
        <Grid item xs={12} xl={10}>
          {/* <MDBox mb={3}> */}
          {/* <Grid container spacing={3}> */}
          {/* <Grid item xs={12}> */}
          <PlatformSettings {...{ userProfile }} />
          {/* </Grid> */}
          {/* <Grid item xs={12}>
                  <Header />
                </Grid>
                <Grid item xs={12}>
                  <BasicInfo />
                </Grid> */}
          {/* <Grid item xs={12}>
                  <Authentication />
                </Grid>
                <Grid item xs={12}>
                  <Accounts />
                </Grid>
                <Grid item xs={12}>
                  <Notifications />
                </Grid>
                <Grid item xs={12}>
                  <Sessions />
                </Grid> */}
        </Grid>
        {/* </MDBox> */}
      </Grid>
      {/* </Grid> */}
      {/* </MDBox> */}
      {/* </BaseLayout> */}
      <Footer />
    </DashboardLayout>
  );
}

export default Settings;
