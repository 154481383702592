/* eslint-disable react/prop-types */
import { createContext, useMemo } from "react";

// HospoSure
import App from "App";
import logo from "assets/images/logos/hospoSureLogo.png";
import useAuthUser from "hooks/useAuthUser";
import useUserProfile from "hooks/useUserProfile";
import useMembership from "hooks/useMembership";
import useVenues from "hooks/useVenues";
// import environment from "./env";

const AppContext = createContext({});

function UnderReview({ signOut }) {
  return (
    <div>
      {/* align="center" */}
      <div>
        <header>
          <div>
            <img src={logo} alt="HospoSure logo" />
          </div>
        </header>
      </div>
      <div>
        <main>
          <article>
            <h1>Oops something went wrong!</h1>
            <p>We experienced a problem creating your account. </p>
            <p>
              Please drop us an email at{" "}
              <a href="mailto:support@hosposure.com.au">support@hosposure.com.au</a> to let us know
              and we&apos;ll get you going as quickly as we can.
            </p>
            <p>Sorry for the inconvenience and thank you for your patience.</p>
          </article>
          <button className="amplify-button" type="button" onClick={signOut}>
            Sign out
          </button>
        </main>
      </div>
      {/* <div>
        <footer>
          <div>
            <p>Copyright HospoSure 2022 | Terms of Use | Privacy Policy</p>
          </div>
        </footer>
      </div> */}
    </div>
  );
}

function GlobalContext({ signOut }) {
  // user
  // const location = useLocation();
  // const { portalLink } = environment;
  const { credentials, userGroups, userId, email, emailVerified, name } = useAuthUser();
  const admin = userGroups?.includes("Admin");
  // const {
  //   name,
  //   email,
  //   email_verified: emailVerified,
  //   sub: userId,
  // } = user.attributes || { name: "", email: "", email_verified: false, sub: "" };
  const { membership } = useMembership({ userId });
  const { subscriptionStatus, quantity, currentPeriodEnd, cancelAtPeriodEnd, cancelAt } =
    membership;
  const { userProfile, updateUserProfile, updatePageSettings } = useUserProfile({
    name,
    email,
    userId,
    userGroups,
    subscribed: ["complete", "active"].includes(subscriptionStatus),
  });
  const { selectedVenue } = userProfile;
  const { venue, venues, setVenue, updateVenue } = useVenues({
    // userId,
    userGroups,
    selectedVenue,
    // admin,
  });
  const changeVenue = ({ newVenue, postNotification }) => {
    setVenue(newVenue);
    updateUserProfile({ selectedVenueId: newVenue.id });
    if (postNotification)
      postNotification({
        level: "success",
        title: "Switch To Venue",
        content: `Venue switched to ${newVenue.operation}.`,
      });
    // if (goHome) console.log("goHome");
  };
  // const { venueProfile, updateVenueProfile } = useVenueProfile({ venue });
  const { id: venueId, userGroup, operation, openForBusiness, dashboardBuild, readOnly } = venue;
  // const [alert, setAlert] = useState("");
  // const { postNotification, renderNotificationSB } = useNotifications();
  const ctx = useMemo(
    () => ({
      userProfile: {
        ...userProfile,
        admin,
        emailVerified,
        signOut,
        updatePageSettings,
        updateUserProfile,
      },
      membership: {
        subscribed: ["complete", "active"].includes(subscriptionStatus),
        quantity,
        currentPeriodEnd,
        cancelAtPeriodEnd,
        cancelAt,
      },
      // stripe: { pricing, portalLink },
      venue: {
        id: venueId,
        userGroup,
        operation,
        openForBusiness,
        dashboardBuild,
        readOnly,
        updateVenue,
        // createVenue,
        changeVenue,
        venues,
      },
      // postNotification,
    }),
    [venue, venues, userGroups, userProfile, membership, selectedVenue]
  );

  switch (true) {
    case userGroups?.length > 0:
      return (
        <AppContext.Provider value={ctx}>
          <App />
          {/* {renderNotificationSB} */}
        </AppContext.Provider>
      );
    case credentials:
      return <UnderReview signOut={signOut} />;
    default:
      return null;
  }
}

export { GlobalContext, AppContext };
