/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
// import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import { Amplify } from "aws-amplify";

import config from "./amplifyconfiguration.json";
import Authenticate from "./Authenticate";
import "index.css";

Amplify.configure(config);

// ReactDOM.render(
//   <BrowserRouter>
//     <Authenticate />
//   </BrowserRouter>,
//   document.getElementById("root")
// );
const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <BrowserRouter>
    <Authenticate />
  </BrowserRouter>
);
