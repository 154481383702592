/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createNotice = /* GraphQL */ `
  mutation CreateNotice(
    $input: CreateNoticeInput!
    $condition: ModelNoticeConditionInput
  ) {
    createNotice(input: $input, condition: $condition) {
      id
      sort
      name
      type
      enabled
      text
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateNotice = /* GraphQL */ `
  mutation UpdateNotice(
    $input: UpdateNoticeInput!
    $condition: ModelNoticeConditionInput
  ) {
    updateNotice(input: $input, condition: $condition) {
      id
      sort
      name
      type
      enabled
      text
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteNotice = /* GraphQL */ `
  mutation DeleteNotice(
    $input: DeleteNoticeInput!
    $condition: ModelNoticeConditionInput
  ) {
    deleteNotice(input: $input, condition: $condition) {
      id
      sort
      name
      type
      enabled
      text
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserProfile = /* GraphQL */ `
  mutation CreateUserProfile(
    $input: CreateUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    createUserProfile(input: $input, condition: $condition) {
      id
      owner
      name
      email
      isDarkMode
      isAdvanced
      isFixedNavbar
      selectedVenueId
      selectedVenue {
        id
        userGroup
        operation
        revenue_stage1
        dishesSold_stage1
        openHours
        periodId
        period {
          id
          sort
          period
          perYear
          createdAt
          updatedAt
          __typename
        }
        salesTaxRate
        prepRoleId
        prepRole {
          id
          userGroup
          role
          areaId
          area {
            id
            sort
            area
            createdAt
            updatedAt
            __typename
          }
          teamMembers
          amount
          hours
          amountPeriodId
          amountPeriod {
            id
            sort
            period
            perYear
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        serviceRoleId
        serviceRole {
          id
          userGroup
          role
          areaId
          area {
            id
            sort
            area
            createdAt
            updatedAt
            __typename
          }
          teamMembers
          amount
          hours
          amountPeriodId
          amountPeriod {
            id
            sort
            period
            perYear
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        openForBusiness
        dashboardBuild
        readOnly
        mainUser
        subscribed
        membershipId
        membership {
          id
          createdAt
          updatedAt
          ownerId
          subscriptionId
          userId
          subscriptionStatus
          email
          customerName
          cancelAt
          cancelAtPeriodEnd
          canceledAt
          cancellationComment
          cancellationFeedback
          cancellationReason
          currency
          currentPeriodEnd
          currentPeriodStart
          planId
          planAmount
          planInterval
          planIntervalCount
          productId
          quantity
          venues {
            nextToken
            __typename
          }
          notifications
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      maxItems
      pageSettings
      accessLevel
      notifications
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserProfile = /* GraphQL */ `
  mutation UpdateUserProfile(
    $input: UpdateUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    updateUserProfile(input: $input, condition: $condition) {
      id
      owner
      name
      email
      isDarkMode
      isAdvanced
      isFixedNavbar
      selectedVenueId
      selectedVenue {
        id
        userGroup
        operation
        revenue_stage1
        dishesSold_stage1
        openHours
        periodId
        period {
          id
          sort
          period
          perYear
          createdAt
          updatedAt
          __typename
        }
        salesTaxRate
        prepRoleId
        prepRole {
          id
          userGroup
          role
          areaId
          area {
            id
            sort
            area
            createdAt
            updatedAt
            __typename
          }
          teamMembers
          amount
          hours
          amountPeriodId
          amountPeriod {
            id
            sort
            period
            perYear
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        serviceRoleId
        serviceRole {
          id
          userGroup
          role
          areaId
          area {
            id
            sort
            area
            createdAt
            updatedAt
            __typename
          }
          teamMembers
          amount
          hours
          amountPeriodId
          amountPeriod {
            id
            sort
            period
            perYear
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        openForBusiness
        dashboardBuild
        readOnly
        mainUser
        subscribed
        membershipId
        membership {
          id
          createdAt
          updatedAt
          ownerId
          subscriptionId
          userId
          subscriptionStatus
          email
          customerName
          cancelAt
          cancelAtPeriodEnd
          canceledAt
          cancellationComment
          cancellationFeedback
          cancellationReason
          currency
          currentPeriodEnd
          currentPeriodStart
          planId
          planAmount
          planInterval
          planIntervalCount
          productId
          quantity
          venues {
            nextToken
            __typename
          }
          notifications
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      maxItems
      pageSettings
      accessLevel
      notifications
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserProfile = /* GraphQL */ `
  mutation DeleteUserProfile(
    $input: DeleteUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    deleteUserProfile(input: $input, condition: $condition) {
      id
      owner
      name
      email
      isDarkMode
      isAdvanced
      isFixedNavbar
      selectedVenueId
      selectedVenue {
        id
        userGroup
        operation
        revenue_stage1
        dishesSold_stage1
        openHours
        periodId
        period {
          id
          sort
          period
          perYear
          createdAt
          updatedAt
          __typename
        }
        salesTaxRate
        prepRoleId
        prepRole {
          id
          userGroup
          role
          areaId
          area {
            id
            sort
            area
            createdAt
            updatedAt
            __typename
          }
          teamMembers
          amount
          hours
          amountPeriodId
          amountPeriod {
            id
            sort
            period
            perYear
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        serviceRoleId
        serviceRole {
          id
          userGroup
          role
          areaId
          area {
            id
            sort
            area
            createdAt
            updatedAt
            __typename
          }
          teamMembers
          amount
          hours
          amountPeriodId
          amountPeriod {
            id
            sort
            period
            perYear
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        openForBusiness
        dashboardBuild
        readOnly
        mainUser
        subscribed
        membershipId
        membership {
          id
          createdAt
          updatedAt
          ownerId
          subscriptionId
          userId
          subscriptionStatus
          email
          customerName
          cancelAt
          cancelAtPeriodEnd
          canceledAt
          cancellationComment
          cancellationFeedback
          cancellationReason
          currency
          currentPeriodEnd
          currentPeriodStart
          planId
          planAmount
          planInterval
          planIntervalCount
          productId
          quantity
          venues {
            nextToken
            __typename
          }
          notifications
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      maxItems
      pageSettings
      accessLevel
      notifications
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMembership = /* GraphQL */ `
  mutation CreateMembership(
    $input: CreateMembershipInput!
    $condition: ModelMembershipConditionInput
  ) {
    createMembership(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      ownerId
      subscriptionId
      userId
      subscriptionStatus
      email
      customerName
      cancelAt
      cancelAtPeriodEnd
      canceledAt
      cancellationComment
      cancellationFeedback
      cancellationReason
      currency
      currentPeriodEnd
      currentPeriodStart
      planId
      planAmount
      planInterval
      planIntervalCount
      productId
      quantity
      venues {
        items {
          id
          userGroup
          operation
          revenue_stage1
          dishesSold_stage1
          openHours
          periodId
          period {
            id
            sort
            period
            perYear
            createdAt
            updatedAt
            __typename
          }
          salesTaxRate
          prepRoleId
          prepRole {
            id
            userGroup
            role
            areaId
            teamMembers
            amount
            hours
            amountPeriodId
            createdAt
            updatedAt
            __typename
          }
          serviceRoleId
          serviceRole {
            id
            userGroup
            role
            areaId
            teamMembers
            amount
            hours
            amountPeriodId
            createdAt
            updatedAt
            __typename
          }
          openForBusiness
          dashboardBuild
          readOnly
          mainUser
          subscribed
          membershipId
          membership {
            id
            createdAt
            updatedAt
            ownerId
            subscriptionId
            userId
            subscriptionStatus
            email
            customerName
            cancelAt
            cancelAtPeriodEnd
            canceledAt
            cancellationComment
            cancellationFeedback
            cancellationReason
            currency
            currentPeriodEnd
            currentPeriodStart
            planId
            planAmount
            planInterval
            planIntervalCount
            productId
            quantity
            notifications
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      notifications
      __typename
    }
  }
`;
export const updateMembership = /* GraphQL */ `
  mutation UpdateMembership(
    $input: UpdateMembershipInput!
    $condition: ModelMembershipConditionInput
  ) {
    updateMembership(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      ownerId
      subscriptionId
      userId
      subscriptionStatus
      email
      customerName
      cancelAt
      cancelAtPeriodEnd
      canceledAt
      cancellationComment
      cancellationFeedback
      cancellationReason
      currency
      currentPeriodEnd
      currentPeriodStart
      planId
      planAmount
      planInterval
      planIntervalCount
      productId
      quantity
      venues {
        items {
          id
          userGroup
          operation
          revenue_stage1
          dishesSold_stage1
          openHours
          periodId
          period {
            id
            sort
            period
            perYear
            createdAt
            updatedAt
            __typename
          }
          salesTaxRate
          prepRoleId
          prepRole {
            id
            userGroup
            role
            areaId
            teamMembers
            amount
            hours
            amountPeriodId
            createdAt
            updatedAt
            __typename
          }
          serviceRoleId
          serviceRole {
            id
            userGroup
            role
            areaId
            teamMembers
            amount
            hours
            amountPeriodId
            createdAt
            updatedAt
            __typename
          }
          openForBusiness
          dashboardBuild
          readOnly
          mainUser
          subscribed
          membershipId
          membership {
            id
            createdAt
            updatedAt
            ownerId
            subscriptionId
            userId
            subscriptionStatus
            email
            customerName
            cancelAt
            cancelAtPeriodEnd
            canceledAt
            cancellationComment
            cancellationFeedback
            cancellationReason
            currency
            currentPeriodEnd
            currentPeriodStart
            planId
            planAmount
            planInterval
            planIntervalCount
            productId
            quantity
            notifications
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      notifications
      __typename
    }
  }
`;
export const deleteMembership = /* GraphQL */ `
  mutation DeleteMembership(
    $input: DeleteMembershipInput!
    $condition: ModelMembershipConditionInput
  ) {
    deleteMembership(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      ownerId
      subscriptionId
      userId
      subscriptionStatus
      email
      customerName
      cancelAt
      cancelAtPeriodEnd
      canceledAt
      cancellationComment
      cancellationFeedback
      cancellationReason
      currency
      currentPeriodEnd
      currentPeriodStart
      planId
      planAmount
      planInterval
      planIntervalCount
      productId
      quantity
      venues {
        items {
          id
          userGroup
          operation
          revenue_stage1
          dishesSold_stage1
          openHours
          periodId
          period {
            id
            sort
            period
            perYear
            createdAt
            updatedAt
            __typename
          }
          salesTaxRate
          prepRoleId
          prepRole {
            id
            userGroup
            role
            areaId
            teamMembers
            amount
            hours
            amountPeriodId
            createdAt
            updatedAt
            __typename
          }
          serviceRoleId
          serviceRole {
            id
            userGroup
            role
            areaId
            teamMembers
            amount
            hours
            amountPeriodId
            createdAt
            updatedAt
            __typename
          }
          openForBusiness
          dashboardBuild
          readOnly
          mainUser
          subscribed
          membershipId
          membership {
            id
            createdAt
            updatedAt
            ownerId
            subscriptionId
            userId
            subscriptionStatus
            email
            customerName
            cancelAt
            cancelAtPeriodEnd
            canceledAt
            cancellationComment
            cancellationFeedback
            cancellationReason
            currency
            currentPeriodEnd
            currentPeriodStart
            planId
            planAmount
            planInterval
            planIntervalCount
            productId
            quantity
            notifications
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      notifications
      __typename
    }
  }
`;
export const createArea = /* GraphQL */ `
  mutation CreateArea(
    $input: CreateAreaInput!
    $condition: ModelAreaConditionInput
  ) {
    createArea(input: $input, condition: $condition) {
      id
      sort
      area
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateArea = /* GraphQL */ `
  mutation UpdateArea(
    $input: UpdateAreaInput!
    $condition: ModelAreaConditionInput
  ) {
    updateArea(input: $input, condition: $condition) {
      id
      sort
      area
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteArea = /* GraphQL */ `
  mutation DeleteArea(
    $input: DeleteAreaInput!
    $condition: ModelAreaConditionInput
  ) {
    deleteArea(input: $input, condition: $condition) {
      id
      sort
      area
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      id
      sort
      category
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      sort
      category
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      id
      sort
      category
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPeriod = /* GraphQL */ `
  mutation CreatePeriod(
    $input: CreatePeriodInput!
    $condition: ModelPeriodConditionInput
  ) {
    createPeriod(input: $input, condition: $condition) {
      id
      sort
      period
      perYear
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePeriod = /* GraphQL */ `
  mutation UpdatePeriod(
    $input: UpdatePeriodInput!
    $condition: ModelPeriodConditionInput
  ) {
    updatePeriod(input: $input, condition: $condition) {
      id
      sort
      period
      perYear
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePeriod = /* GraphQL */ `
  mutation DeletePeriod(
    $input: DeletePeriodInput!
    $condition: ModelPeriodConditionInput
  ) {
    deletePeriod(input: $input, condition: $condition) {
      id
      sort
      period
      perYear
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createStore = /* GraphQL */ `
  mutation CreateStore(
    $input: CreateStoreInput!
    $condition: ModelStoreConditionInput
  ) {
    createStore(input: $input, condition: $condition) {
      id
      sort
      store
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateStore = /* GraphQL */ `
  mutation UpdateStore(
    $input: UpdateStoreInput!
    $condition: ModelStoreConditionInput
  ) {
    updateStore(input: $input, condition: $condition) {
      id
      sort
      store
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteStore = /* GraphQL */ `
  mutation DeleteStore(
    $input: DeleteStoreInput!
    $condition: ModelStoreConditionInput
  ) {
    deleteStore(input: $input, condition: $condition) {
      id
      sort
      store
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUnit = /* GraphQL */ `
  mutation CreateUnit(
    $input: CreateUnitInput!
    $condition: ModelUnitConditionInput
  ) {
    createUnit(input: $input, condition: $condition) {
      id
      sort
      ingredientUnit
      factor
      recipeUnit
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUnit = /* GraphQL */ `
  mutation UpdateUnit(
    $input: UpdateUnitInput!
    $condition: ModelUnitConditionInput
  ) {
    updateUnit(input: $input, condition: $condition) {
      id
      sort
      ingredientUnit
      factor
      recipeUnit
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUnit = /* GraphQL */ `
  mutation DeleteUnit(
    $input: DeleteUnitInput!
    $condition: ModelUnitConditionInput
  ) {
    deleteUnit(input: $input, condition: $condition) {
      id
      sort
      ingredientUnit
      factor
      recipeUnit
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOverhead = /* GraphQL */ `
  mutation CreateOverhead(
    $input: CreateOverheadInput!
    $condition: ModelOverheadConditionInput
  ) {
    createOverhead(input: $input, condition: $condition) {
      id
      userGroup
      overhead
      categoryId
      category {
        id
        sort
        category
        createdAt
        updatedAt
        __typename
      }
      cost
      costPeriodId
      costPeriod {
        id
        sort
        period
        perYear
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOverhead = /* GraphQL */ `
  mutation UpdateOverhead(
    $input: UpdateOverheadInput!
    $condition: ModelOverheadConditionInput
  ) {
    updateOverhead(input: $input, condition: $condition) {
      id
      userGroup
      overhead
      categoryId
      category {
        id
        sort
        category
        createdAt
        updatedAt
        __typename
      }
      cost
      costPeriodId
      costPeriod {
        id
        sort
        period
        perYear
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOverhead = /* GraphQL */ `
  mutation DeleteOverhead(
    $input: DeleteOverheadInput!
    $condition: ModelOverheadConditionInput
  ) {
    deleteOverhead(input: $input, condition: $condition) {
      id
      userGroup
      overhead
      categoryId
      category {
        id
        sort
        category
        createdAt
        updatedAt
        __typename
      }
      cost
      costPeriodId
      costPeriod {
        id
        sort
        period
        perYear
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSupplier = /* GraphQL */ `
  mutation CreateSupplier(
    $input: CreateSupplierInput!
    $condition: ModelSupplierConditionInput
  ) {
    createSupplier(input: $input, condition: $condition) {
      id
      userGroup
      supplier
      cost_stage1
      cost_stage1PeriodId
      cost_stage1Period {
        id
        sort
        period
        perYear
        createdAt
        updatedAt
        __typename
      }
      ingredients {
        items {
          id
          userGroup
          ingredient
          storeId
          store {
            id
            sort
            store
            createdAt
            updatedAt
            __typename
          }
          supplierId
          supplier {
            id
            userGroup
            supplier
            cost_stage1
            cost_stage1PeriodId
            createdAt
            updatedAt
            __typename
          }
          cost
          costUnitId
          costUnit {
            id
            sort
            ingredientUnit
            factor
            recipeUnit
            createdAt
            updatedAt
            __typename
          }
          recipeIngredients {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSupplier = /* GraphQL */ `
  mutation UpdateSupplier(
    $input: UpdateSupplierInput!
    $condition: ModelSupplierConditionInput
  ) {
    updateSupplier(input: $input, condition: $condition) {
      id
      userGroup
      supplier
      cost_stage1
      cost_stage1PeriodId
      cost_stage1Period {
        id
        sort
        period
        perYear
        createdAt
        updatedAt
        __typename
      }
      ingredients {
        items {
          id
          userGroup
          ingredient
          storeId
          store {
            id
            sort
            store
            createdAt
            updatedAt
            __typename
          }
          supplierId
          supplier {
            id
            userGroup
            supplier
            cost_stage1
            cost_stage1PeriodId
            createdAt
            updatedAt
            __typename
          }
          cost
          costUnitId
          costUnit {
            id
            sort
            ingredientUnit
            factor
            recipeUnit
            createdAt
            updatedAt
            __typename
          }
          recipeIngredients {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSupplier = /* GraphQL */ `
  mutation DeleteSupplier(
    $input: DeleteSupplierInput!
    $condition: ModelSupplierConditionInput
  ) {
    deleteSupplier(input: $input, condition: $condition) {
      id
      userGroup
      supplier
      cost_stage1
      cost_stage1PeriodId
      cost_stage1Period {
        id
        sort
        period
        perYear
        createdAt
        updatedAt
        __typename
      }
      ingredients {
        items {
          id
          userGroup
          ingredient
          storeId
          store {
            id
            sort
            store
            createdAt
            updatedAt
            __typename
          }
          supplierId
          supplier {
            id
            userGroup
            supplier
            cost_stage1
            cost_stage1PeriodId
            createdAt
            updatedAt
            __typename
          }
          cost
          costUnitId
          costUnit {
            id
            sort
            ingredientUnit
            factor
            recipeUnit
            createdAt
            updatedAt
            __typename
          }
          recipeIngredients {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRole = /* GraphQL */ `
  mutation CreateRole(
    $input: CreateRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    createRole(input: $input, condition: $condition) {
      id
      userGroup
      role
      areaId
      area {
        id
        sort
        area
        createdAt
        updatedAt
        __typename
      }
      teamMembers
      amount
      hours
      amountPeriodId
      amountPeriod {
        id
        sort
        period
        perYear
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRole = /* GraphQL */ `
  mutation UpdateRole(
    $input: UpdateRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    updateRole(input: $input, condition: $condition) {
      id
      userGroup
      role
      areaId
      area {
        id
        sort
        area
        createdAt
        updatedAt
        __typename
      }
      teamMembers
      amount
      hours
      amountPeriodId
      amountPeriod {
        id
        sort
        period
        perYear
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRole = /* GraphQL */ `
  mutation DeleteRole(
    $input: DeleteRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    deleteRole(input: $input, condition: $condition) {
      id
      userGroup
      role
      areaId
      area {
        id
        sort
        area
        createdAt
        updatedAt
        __typename
      }
      teamMembers
      amount
      hours
      amountPeriodId
      amountPeriod {
        id
        sort
        period
        perYear
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOperation = /* GraphQL */ `
  mutation CreateOperation(
    $input: CreateOperationInput!
    $condition: ModelOperationConditionInput
  ) {
    createOperation(input: $input, condition: $condition) {
      id
      userGroup
      operation
      revenue_stage1
      dishesSold_stage1
      openHours
      periodId
      period {
        id
        sort
        period
        perYear
        createdAt
        updatedAt
        __typename
      }
      salesTaxRate
      prepRoleId
      prepRole {
        id
        userGroup
        role
        areaId
        area {
          id
          sort
          area
          createdAt
          updatedAt
          __typename
        }
        teamMembers
        amount
        hours
        amountPeriodId
        amountPeriod {
          id
          sort
          period
          perYear
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      serviceRoleId
      serviceRole {
        id
        userGroup
        role
        areaId
        area {
          id
          sort
          area
          createdAt
          updatedAt
          __typename
        }
        teamMembers
        amount
        hours
        amountPeriodId
        amountPeriod {
          id
          sort
          period
          perYear
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      openForBusiness
      dashboardBuild
      readOnly
      mainUser
      subscribed
      membershipId
      membership {
        id
        createdAt
        updatedAt
        ownerId
        subscriptionId
        userId
        subscriptionStatus
        email
        customerName
        cancelAt
        cancelAtPeriodEnd
        canceledAt
        cancellationComment
        cancellationFeedback
        cancellationReason
        currency
        currentPeriodEnd
        currentPeriodStart
        planId
        planAmount
        planInterval
        planIntervalCount
        productId
        quantity
        venues {
          items {
            id
            userGroup
            operation
            revenue_stage1
            dishesSold_stage1
            openHours
            periodId
            salesTaxRate
            prepRoleId
            serviceRoleId
            openForBusiness
            dashboardBuild
            readOnly
            mainUser
            subscribed
            membershipId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOperation = /* GraphQL */ `
  mutation UpdateOperation(
    $input: UpdateOperationInput!
    $condition: ModelOperationConditionInput
  ) {
    updateOperation(input: $input, condition: $condition) {
      id
      userGroup
      operation
      revenue_stage1
      dishesSold_stage1
      openHours
      periodId
      period {
        id
        sort
        period
        perYear
        createdAt
        updatedAt
        __typename
      }
      salesTaxRate
      prepRoleId
      prepRole {
        id
        userGroup
        role
        areaId
        area {
          id
          sort
          area
          createdAt
          updatedAt
          __typename
        }
        teamMembers
        amount
        hours
        amountPeriodId
        amountPeriod {
          id
          sort
          period
          perYear
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      serviceRoleId
      serviceRole {
        id
        userGroup
        role
        areaId
        area {
          id
          sort
          area
          createdAt
          updatedAt
          __typename
        }
        teamMembers
        amount
        hours
        amountPeriodId
        amountPeriod {
          id
          sort
          period
          perYear
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      openForBusiness
      dashboardBuild
      readOnly
      mainUser
      subscribed
      membershipId
      membership {
        id
        createdAt
        updatedAt
        ownerId
        subscriptionId
        userId
        subscriptionStatus
        email
        customerName
        cancelAt
        cancelAtPeriodEnd
        canceledAt
        cancellationComment
        cancellationFeedback
        cancellationReason
        currency
        currentPeriodEnd
        currentPeriodStart
        planId
        planAmount
        planInterval
        planIntervalCount
        productId
        quantity
        venues {
          items {
            id
            userGroup
            operation
            revenue_stage1
            dishesSold_stage1
            openHours
            periodId
            salesTaxRate
            prepRoleId
            serviceRoleId
            openForBusiness
            dashboardBuild
            readOnly
            mainUser
            subscribed
            membershipId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOperation = /* GraphQL */ `
  mutation DeleteOperation(
    $input: DeleteOperationInput!
    $condition: ModelOperationConditionInput
  ) {
    deleteOperation(input: $input, condition: $condition) {
      id
      userGroup
      operation
      revenue_stage1
      dishesSold_stage1
      openHours
      periodId
      period {
        id
        sort
        period
        perYear
        createdAt
        updatedAt
        __typename
      }
      salesTaxRate
      prepRoleId
      prepRole {
        id
        userGroup
        role
        areaId
        area {
          id
          sort
          area
          createdAt
          updatedAt
          __typename
        }
        teamMembers
        amount
        hours
        amountPeriodId
        amountPeriod {
          id
          sort
          period
          perYear
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      serviceRoleId
      serviceRole {
        id
        userGroup
        role
        areaId
        area {
          id
          sort
          area
          createdAt
          updatedAt
          __typename
        }
        teamMembers
        amount
        hours
        amountPeriodId
        amountPeriod {
          id
          sort
          period
          perYear
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      openForBusiness
      dashboardBuild
      readOnly
      mainUser
      subscribed
      membershipId
      membership {
        id
        createdAt
        updatedAt
        ownerId
        subscriptionId
        userId
        subscriptionStatus
        email
        customerName
        cancelAt
        cancelAtPeriodEnd
        canceledAt
        cancellationComment
        cancellationFeedback
        cancellationReason
        currency
        currentPeriodEnd
        currentPeriodStart
        planId
        planAmount
        planInterval
        planIntervalCount
        productId
        quantity
        venues {
          items {
            id
            userGroup
            operation
            revenue_stage1
            dishesSold_stage1
            openHours
            periodId
            salesTaxRate
            prepRoleId
            serviceRoleId
            openForBusiness
            dashboardBuild
            readOnly
            mainUser
            subscribed
            membershipId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createIngredient = /* GraphQL */ `
  mutation CreateIngredient(
    $input: CreateIngredientInput!
    $condition: ModelIngredientConditionInput
  ) {
    createIngredient(input: $input, condition: $condition) {
      id
      userGroup
      ingredient
      storeId
      store {
        id
        sort
        store
        createdAt
        updatedAt
        __typename
      }
      supplierId
      supplier {
        id
        userGroup
        supplier
        cost_stage1
        cost_stage1PeriodId
        cost_stage1Period {
          id
          sort
          period
          perYear
          createdAt
          updatedAt
          __typename
        }
        ingredients {
          items {
            id
            userGroup
            ingredient
            storeId
            supplierId
            cost
            costUnitId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      cost
      costUnitId
      costUnit {
        id
        sort
        ingredientUnit
        factor
        recipeUnit
        createdAt
        updatedAt
        __typename
      }
      recipeIngredients {
        items {
          id
          sort
          userGroup
          recipeId
          ingredientId
          recipe {
            id
            userGroup
            recipe
            portionSellingPrice
            portionSales
            portionSalesPeriodId
            portionCount
            prepTime
            serviceTimePerPortion
            createdAt
            updatedAt
            __typename
          }
          ingredient {
            id
            userGroup
            ingredient
            storeId
            supplierId
            cost
            costUnitId
            createdAt
            updatedAt
            __typename
          }
          quantity
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateIngredient = /* GraphQL */ `
  mutation UpdateIngredient(
    $input: UpdateIngredientInput!
    $condition: ModelIngredientConditionInput
  ) {
    updateIngredient(input: $input, condition: $condition) {
      id
      userGroup
      ingredient
      storeId
      store {
        id
        sort
        store
        createdAt
        updatedAt
        __typename
      }
      supplierId
      supplier {
        id
        userGroup
        supplier
        cost_stage1
        cost_stage1PeriodId
        cost_stage1Period {
          id
          sort
          period
          perYear
          createdAt
          updatedAt
          __typename
        }
        ingredients {
          items {
            id
            userGroup
            ingredient
            storeId
            supplierId
            cost
            costUnitId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      cost
      costUnitId
      costUnit {
        id
        sort
        ingredientUnit
        factor
        recipeUnit
        createdAt
        updatedAt
        __typename
      }
      recipeIngredients {
        items {
          id
          sort
          userGroup
          recipeId
          ingredientId
          recipe {
            id
            userGroup
            recipe
            portionSellingPrice
            portionSales
            portionSalesPeriodId
            portionCount
            prepTime
            serviceTimePerPortion
            createdAt
            updatedAt
            __typename
          }
          ingredient {
            id
            userGroup
            ingredient
            storeId
            supplierId
            cost
            costUnitId
            createdAt
            updatedAt
            __typename
          }
          quantity
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteIngredient = /* GraphQL */ `
  mutation DeleteIngredient(
    $input: DeleteIngredientInput!
    $condition: ModelIngredientConditionInput
  ) {
    deleteIngredient(input: $input, condition: $condition) {
      id
      userGroup
      ingredient
      storeId
      store {
        id
        sort
        store
        createdAt
        updatedAt
        __typename
      }
      supplierId
      supplier {
        id
        userGroup
        supplier
        cost_stage1
        cost_stage1PeriodId
        cost_stage1Period {
          id
          sort
          period
          perYear
          createdAt
          updatedAt
          __typename
        }
        ingredients {
          items {
            id
            userGroup
            ingredient
            storeId
            supplierId
            cost
            costUnitId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      cost
      costUnitId
      costUnit {
        id
        sort
        ingredientUnit
        factor
        recipeUnit
        createdAt
        updatedAt
        __typename
      }
      recipeIngredients {
        items {
          id
          sort
          userGroup
          recipeId
          ingredientId
          recipe {
            id
            userGroup
            recipe
            portionSellingPrice
            portionSales
            portionSalesPeriodId
            portionCount
            prepTime
            serviceTimePerPortion
            createdAt
            updatedAt
            __typename
          }
          ingredient {
            id
            userGroup
            ingredient
            storeId
            supplierId
            cost
            costUnitId
            createdAt
            updatedAt
            __typename
          }
          quantity
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRecipe = /* GraphQL */ `
  mutation CreateRecipe(
    $input: CreateRecipeInput!
    $condition: ModelRecipeConditionInput
  ) {
    createRecipe(input: $input, condition: $condition) {
      id
      userGroup
      recipe
      portionSellingPrice
      portionSales
      portionSalesPeriodId
      portionSalesPeriod {
        id
        sort
        period
        perYear
        createdAt
        updatedAt
        __typename
      }
      portionCount
      prepTime
      serviceTimePerPortion
      recipeIngredients {
        items {
          id
          sort
          userGroup
          recipeId
          ingredientId
          recipe {
            id
            userGroup
            recipe
            portionSellingPrice
            portionSales
            portionSalesPeriodId
            portionCount
            prepTime
            serviceTimePerPortion
            createdAt
            updatedAt
            __typename
          }
          ingredient {
            id
            userGroup
            ingredient
            storeId
            supplierId
            cost
            costUnitId
            createdAt
            updatedAt
            __typename
          }
          quantity
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRecipe = /* GraphQL */ `
  mutation UpdateRecipe(
    $input: UpdateRecipeInput!
    $condition: ModelRecipeConditionInput
  ) {
    updateRecipe(input: $input, condition: $condition) {
      id
      userGroup
      recipe
      portionSellingPrice
      portionSales
      portionSalesPeriodId
      portionSalesPeriod {
        id
        sort
        period
        perYear
        createdAt
        updatedAt
        __typename
      }
      portionCount
      prepTime
      serviceTimePerPortion
      recipeIngredients {
        items {
          id
          sort
          userGroup
          recipeId
          ingredientId
          recipe {
            id
            userGroup
            recipe
            portionSellingPrice
            portionSales
            portionSalesPeriodId
            portionCount
            prepTime
            serviceTimePerPortion
            createdAt
            updatedAt
            __typename
          }
          ingredient {
            id
            userGroup
            ingredient
            storeId
            supplierId
            cost
            costUnitId
            createdAt
            updatedAt
            __typename
          }
          quantity
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRecipe = /* GraphQL */ `
  mutation DeleteRecipe(
    $input: DeleteRecipeInput!
    $condition: ModelRecipeConditionInput
  ) {
    deleteRecipe(input: $input, condition: $condition) {
      id
      userGroup
      recipe
      portionSellingPrice
      portionSales
      portionSalesPeriodId
      portionSalesPeriod {
        id
        sort
        period
        perYear
        createdAt
        updatedAt
        __typename
      }
      portionCount
      prepTime
      serviceTimePerPortion
      recipeIngredients {
        items {
          id
          sort
          userGroup
          recipeId
          ingredientId
          recipe {
            id
            userGroup
            recipe
            portionSellingPrice
            portionSales
            portionSalesPeriodId
            portionCount
            prepTime
            serviceTimePerPortion
            createdAt
            updatedAt
            __typename
          }
          ingredient {
            id
            userGroup
            ingredient
            storeId
            supplierId
            cost
            costUnitId
            createdAt
            updatedAt
            __typename
          }
          quantity
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRecipeIngredient = /* GraphQL */ `
  mutation CreateRecipeIngredient(
    $input: CreateRecipeIngredientInput!
    $condition: ModelRecipeIngredientConditionInput
  ) {
    createRecipeIngredient(input: $input, condition: $condition) {
      id
      sort
      userGroup
      recipeId
      ingredientId
      recipe {
        id
        userGroup
        recipe
        portionSellingPrice
        portionSales
        portionSalesPeriodId
        portionSalesPeriod {
          id
          sort
          period
          perYear
          createdAt
          updatedAt
          __typename
        }
        portionCount
        prepTime
        serviceTimePerPortion
        recipeIngredients {
          items {
            id
            sort
            userGroup
            recipeId
            ingredientId
            quantity
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ingredient {
        id
        userGroup
        ingredient
        storeId
        store {
          id
          sort
          store
          createdAt
          updatedAt
          __typename
        }
        supplierId
        supplier {
          id
          userGroup
          supplier
          cost_stage1
          cost_stage1PeriodId
          cost_stage1Period {
            id
            sort
            period
            perYear
            createdAt
            updatedAt
            __typename
          }
          ingredients {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        cost
        costUnitId
        costUnit {
          id
          sort
          ingredientUnit
          factor
          recipeUnit
          createdAt
          updatedAt
          __typename
        }
        recipeIngredients {
          items {
            id
            sort
            userGroup
            recipeId
            ingredientId
            quantity
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      quantity
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRecipeIngredient = /* GraphQL */ `
  mutation UpdateRecipeIngredient(
    $input: UpdateRecipeIngredientInput!
    $condition: ModelRecipeIngredientConditionInput
  ) {
    updateRecipeIngredient(input: $input, condition: $condition) {
      id
      sort
      userGroup
      recipeId
      ingredientId
      recipe {
        id
        userGroup
        recipe
        portionSellingPrice
        portionSales
        portionSalesPeriodId
        portionSalesPeriod {
          id
          sort
          period
          perYear
          createdAt
          updatedAt
          __typename
        }
        portionCount
        prepTime
        serviceTimePerPortion
        recipeIngredients {
          items {
            id
            sort
            userGroup
            recipeId
            ingredientId
            quantity
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ingredient {
        id
        userGroup
        ingredient
        storeId
        store {
          id
          sort
          store
          createdAt
          updatedAt
          __typename
        }
        supplierId
        supplier {
          id
          userGroup
          supplier
          cost_stage1
          cost_stage1PeriodId
          cost_stage1Period {
            id
            sort
            period
            perYear
            createdAt
            updatedAt
            __typename
          }
          ingredients {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        cost
        costUnitId
        costUnit {
          id
          sort
          ingredientUnit
          factor
          recipeUnit
          createdAt
          updatedAt
          __typename
        }
        recipeIngredients {
          items {
            id
            sort
            userGroup
            recipeId
            ingredientId
            quantity
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      quantity
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRecipeIngredient = /* GraphQL */ `
  mutation DeleteRecipeIngredient(
    $input: DeleteRecipeIngredientInput!
    $condition: ModelRecipeIngredientConditionInput
  ) {
    deleteRecipeIngredient(input: $input, condition: $condition) {
      id
      sort
      userGroup
      recipeId
      ingredientId
      recipe {
        id
        userGroup
        recipe
        portionSellingPrice
        portionSales
        portionSalesPeriodId
        portionSalesPeriod {
          id
          sort
          period
          perYear
          createdAt
          updatedAt
          __typename
        }
        portionCount
        prepTime
        serviceTimePerPortion
        recipeIngredients {
          items {
            id
            sort
            userGroup
            recipeId
            ingredientId
            quantity
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ingredient {
        id
        userGroup
        ingredient
        storeId
        store {
          id
          sort
          store
          createdAt
          updatedAt
          __typename
        }
        supplierId
        supplier {
          id
          userGroup
          supplier
          cost_stage1
          cost_stage1PeriodId
          cost_stage1Period {
            id
            sort
            period
            perYear
            createdAt
            updatedAt
            __typename
          }
          ingredients {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        cost
        costUnitId
        costUnit {
          id
          sort
          ingredientUnit
          factor
          recipeUnit
          createdAt
          updatedAt
          __typename
        }
        recipeIngredients {
          items {
            id
            sort
            userGroup
            recipeId
            ingredientId
            quantity
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      quantity
      createdAt
      updatedAt
      __typename
    }
  }
`;
