/* eslint-disable camelcase */
import { format } from "general";

const {
  // array,
  boolean,
  currency,
  date,
  derived,
  duration,
  fixed,
  float,
  hidden,
  integer,
  // number,
  percentage,
  select,
  staticSelect,
  text,
  multilineText,
} = format;
const widths = {
  smallText: 5,
  mediumText: 15,
  text: 30,
  largeText: 45,
  currency: 15,
  select: 20,
  derived: 10,
  number: 10,
  duration: 10,
  boolean: 5,
  date: 10,
};
const ascending = true;
const email = "hello@hosposure.com.au";
const venueType = "Cafe";
const salesTaxName = "GST";
const loading = {
  start: 0,
  lookupsMissing: 1,
  lookupsFetched: 2,
  itemsFetched: 3,
  soloItemFetched: 4,
  done: 5,
};
const adminTypes = ["Area", "Category", "Period", "Store", "Unit"];
const demoVenues = ["135b82d6-a833-41bb-892c-f2d84c71030a"];
const templateVenues = ["135b82d6-a833-41bb-892c-f2d84c71030b"];
const venueSettings = {
  openForBusiness: {
    ref: 0,
    pre: "Planning to open",
    post: "Open for business",
    tip: "Select your operational status",
  },
  dashboardBuild: {
    ref: 1,
    pre: `Use ${venueType} Model`,
    post: "Use Menu Build",
    tip: "Select data source for Revenue & Supplier Cost",
  },
};

// pageConfig = {
//   title: "page name",
//   description: "page description",
//   route: "url path",
//   type: "graphql schema type",
//   admin: "is admin level page",
//   build: "is build stage for dashboard",
//   icon: "header icon: https://fonts.google.com/icons",
//   primaryKey: "database primary key",
//   band: "menu band for Progress display grouping",
//   progressComplete: "progress level for completion state in Progress display",
//   autoCreate: "automatically create if no existing record",
//   warning: "warning message",
//   singleItem: "is single record entry only to display form instead of table",
//   editRoute: "url path to edit item",
//   isMany: "is many-to-many graphql schema type",
//   m2mKey: "? still needed ?",
//   m2mDeleteControl: "? still needed ?",
//   keys: {
//     keyName: {
//       name: "name to use instead of keyName",
//       keyType: "key type",
//       dataType: "data type when keyType is derived",
//       factors: "additional graphql schema types to retrieve for formula calculation",
//       tip: "title mouseOver text",
//       width: "column width",
//       type: "select keyType graphQL schema type",
//       key: "select keyType primary key",
//       formula: "derived keyType calculation function",
//     },
//   },
// };
const pageConfig = [
  {
    route: "/webhook",
    description: "",
    help: {},
  },
  {
    title: "Your Progress",
    route: "/",
    icon: "home",
    description:
      "Keep track of your journey and progress through HospoSure. Checkpoints will turn green as you enter more data.",
    action: {
      startup: `Are you operating an established ${venueType}? Select "Open for business".`,
      open: `Are you planning a new ${venueType}? Select "Planning to open".`,
    },
    help: {
      "Educational Journey": `As you work your way through the program, you will learn to blend the financial, analytical, operational, and creative parts of ${venueType} operations, which will help you develop a robust and profitable business model, giving you some serious ${venueType} management skills.`,
      Templates:
        "Select the Template venue at the top of the page to import pre-defined templates to your own venue. This is an excellent way to speed up what can be a time-consuming process of data entry. Always remember the template financials are only estimates - once imported, you will still need to review the details for accuracy and update to suit your own venue.",
      "Support & Guidance":
        "Don't forget to look out for Coaching Notes, handy prompts and mouse overs for extra support. We'll be there for you every step of the way. If you have any general queries, please don't hesitate to get in touch.",
      Time: `Expect to take about 1 hour for the ${venueType} Modelling stage and about 2 hours for the Menu Building stage.`,
    },
  },
  {
    title: "Account",
    route: "/profile/account",
    icon: "loyalty",
    description:
      "The Account page shows your Profile details which were provided on sign up, as well as your current Membership subscription and access levels.",
    help: {
      Subscription: `Please subscribe to model and build your own ${venueType} venue. If you have more than one ${venueType} venue to model, you can increase the quantity on the payment page. Once subscribed, you will be able to update the name of your ${venueType} venue on this page.`,
    },
  },
  {
    title: "Settings",
    route: "/profile/settings",
    icon: "settings",
    description: "Configure your user experience on the app.",
    help: {
      "Heading & Button mouseover tips":
        "Toggle to show handy tips when you hover over headings or buttons. As you become familiar you may like to disable this option.",
      "Dark Mode":
        "Toggle between light mode, a brighter/traditional display, and dark mode which helps to conserve battery life and reduce eye strain.",
      "Fixed top navigation bar": "Toggle to keep the top navigation bar always in view.",
    },
  },
  {
    title: "Members",
    route: "/admin/members",
    admin: true,
    icon: "loyalty",
    type: "Membership",
    description: "All members.",
    readOnly: true,
    // editRoute: "/",
    keys: {
      // userId: {
      //   keyType: text,
      //   tip: "User ID",
      //   width: widths.smallText,
      // },
      email: {
        keyType: text,
        tip: "Email address",
        width: widths.text,
      },
      customerName: {
        keyType: text,
        tip: "Customer name",
        width: widths.mediumText,
      },
      subscriptionStatus: {
        keyType: text,
        tip: "Subscription status",
        width: widths.mediumText,
      },
      cancelAtPeriodEnd: {
        keyType: boolean,
        tip: "Cancel at period end",
        width: widths.boolean,
      },
      currentPeriodEnd: {
        keyType: date,
        tip: "Current subscription period end",
        width: widths.date,
      },
      quantity: {
        keyType: integer,
        tip: "Quantity of venues subscribed",
        width: widths.smallText,
      },
      switchToVenue: {
        keyType: select,
        // dataType: array,
        // formula: ({ venues }) => venues.items,
        type: "Operation",
        // key: "supplierId",
        changeVenue: true,
        tip: "List of venues subscribed",
        width: widths.select,
      },
    },
    primaryKey: "email",
  },
  {
    title: "Notices",
    route: "/admin/notices",
    admin: true,
    icon: "loyalty",
    type: "Notice",
    description: "All notices.",
    readOnly: false,
    // editRoute: "/",
    keys: {
      name: {
        keyType: text,
        tip: "Name of the notice",
        width: widths.mediumText,
      },
      type: {
        keyType: staticSelect,
        options: ["Global", "Subscribe"],
        tip: "Type of notice",
        width: widths.mediumText,
      },
      enabled: {
        keyType: boolean,
        tip: "Enabled",
        width: widths.boolean,
      },
      text: {
        keyType: multilineText,
        tip: "Text message to display",
        width: widths.largeText,
      },
    },
    primaryKey: "name",
  },
  {
    title: "Areas",
    route: "/admin/areas",
    admin: true,
    icon: "my_location",
    type: "Area",
    description: "All areas, accessible by all users.",
    keys: {
      area: {
        keyType: text,
        tip: "Name of the area",
        width: widths.smallText,
      },
    },
    primaryKey: "area",
  },
  {
    title: "Categories",
    route: "/admin/categories",
    admin: true,
    icon: "category",
    type: "Category",
    description: "All categories, accessible by all users.",
    keys: {
      category: {
        keyType: text,
        tip: "Name of the category",
        width: widths.smallText,
      },
    },
    primaryKey: "category",
  },
  {
    title: "Periods",
    route: "/admin/periods",
    admin: true,
    icon: "calendar_month",
    description: "All time periods, accessible by all users.",
    type: "Period",
    keys: {
      period: {
        keyType: text,
        tip: "Name of the period",
        width: widths.smallText,
      },
      perYear: {
        keyType: integer,
        tip: "Number of these periods per year",
        width: widths.smallText,
      },
    },
    primaryKey: "period",
  },
  {
    title: "Stores",
    route: "/admin/stores",
    admin: true,
    icon: "store",
    type: "Store",
    description: "All store labels, accessible by all users.",
    keys: {
      store: {
        keyType: text,
        tip: "Name of the store",
        width: widths.smallText,
      },
    },
    primaryKey: "store",
  },
  {
    title: "Units",
    route: "/admin/units",
    admin: true,
    icon: "scale",
    type: "Unit",
    description: "All unit labels, accessible by all users.",
    keys: {
      ingredientUnit: {
        keyType: text,
        tip: "Name/abbreviation of the unit used when purchasing the Ingredient",
        width: widths.smallText,
      },
      factor: {
        keyType: float,
        tip: "Factor of difference between the Ingredient & Recipe unit",
        width: widths.smallText,
      },
      recipeUnit: {
        keyType: text,
        tip: "Name/abbreviation of the smaller unit when the Ingredient is used in the Recipe",
        width: widths.smallText,
      },
    },
    primaryKey: "ingredientUnit",
  },
  {
    title: "Overheads",
    route: "/model/overheads",
    type: "Overhead",
    icon: "electric_bolt",
    description: `Overheads are the fixed and variable business costs associated with the day-to-day operations of your ${venueType}.`,
    action: {
      startup:
        "Project your future overheads by researching what you are likely to pay service providers when your doors open.",
      open: "Enter your existing overheads using invoices and statements from your current service providers.",
    },
    help: {
      "Overhead as a proportion of Revenue":
        "Overhead costs should ideally be below 30% of Revenue. A large proportion of this will be made up by your lease or rent, which is recommended to be no more than 15% of Revenue.",
      Control: `Overheads account for a sizable chunk of your ongoing expenses and when you're busy running your ${venueType} it's the little costs that can really add up quickly, having a significant impact on your cash flow and bottom line. Check the necessity of any new overhead charges before incorporating them into your business model. Do you really need that extra oven on credit?`,
      Monitor:
        'After you complete the Model section, take a look in your Dashboard for "Overhead allocation /Item". You can get a clear picture of how much of your total Overhead costs you should apply to everything you sell.',
    },
    initial: { sort: { key: "overhead", ascending } },
    primaryKey: "overhead",
    band: "model",
    progressComplete: 15,
    keys: {
      overhead: {
        keyType: text,
        tip: "Name or description of the overhead",
        width: widths.text,
      },
      categoryId: {
        keyType: select,
        type: "Category",
        key: "categoryId",
        tip: "Category of the overhead",
        width: widths.select,
      },
      cost: {
        keyType: currency,
        tip: "Cost of the overhead for the period",
        width: widths.currency,
      },
      costPeriodId: {
        keyType: select,
        type: "Period",
        key: "costPeriodId",
        tip: "Period for the cost of the overhead",
        width: widths.select,
      },
      costPerYear: {
        keyType: derived,
        dataType: currency,
        formula: ({ costPeriod, cost }) => costPeriod.perYear * cost,
        tip: "Total cost for one year",
        width: widths.derived,
      },
    },
  },
  {
    title: "Suppliers",
    route: "/model/suppliers",
    type: "Supplier",
    description: `Great ${venueType} suppliers will deliver awesome products, value, and service.`,
    action: {
      startup:
        "Jump online to research some suppliers that you think would cover what you need for your startup, and then add them into the system.",
      open: "If you haven't already, make a list of your current suppliers then enter them into the system.",
    },
    help: {
      "Supplier as a proportion of Revenue": `Supplier costs should ideally be no more than 30% of Revenue. It is always a good idea to develop a robust ${venueType} menu that is designed to be consistent, sustainable, and profitable by using seasonal, economical produce and being imaginative with humble, simple ingredients.`,
      Service: `Suppliers are an extension of your business, and building healthy supplier relationships are an important aspect of running a successful ${venueType}. The consistency of your supplier's service will have an impact on your service and your customers' experience. Be sure to pick reputable suppliers who are well known for their reliability. They need to deliver on time and alert you of any issues prior to delivery.`,
      Respect: `Note that ${venueType} suppliers also have a business to run, so respect minimum order requirements, order cut off deadlines, and payment terms.`,
      "Qty Ingredients Supplied": `When your menu is finished, you'll be able to see how many items each supplier provides to your ${venueType} as a whole. Consider consolidating suppliers if it will improve ordering efficiency and reduce costs.`,
    },
    initial: { sort: { key: "supplier", ascending } },
    primaryKey: "supplier",
    band: "model",
    icon: "local_shipping",
    progressComplete: 10,
    deleteControl: [
      {
        type: "Ingredient",
        idKey: "supplierId",
        action: "change Supplier for all supplied Ingredients",
      },
    ],
    keys: {
      supplier: {
        keyType: text,
        tip: "Name of the supplier",
        width: widths.text,
      },
      cost_stage1: {
        keyType: currency,
        tip: `Cost of the supplier for the period - used for ${venueType} Modelling stage only`,
        width: widths.currency,
      },
      cost_stage1PeriodId: {
        keyType: select,
        type: "Period",
        key: "cost_stage1PeriodId",
        tip: `Period for the cost of the supplier - used for ${venueType} Modelling stage only`,
        width: widths.select,
      },
      qtyIngredientsSupplied: {
        keyType: derived,
        dataType: integer,
        formula: ({ ingredients }) => ingredients.items.length,
        tip: "Quantity of ingredients supplied by this supplier",
        width: widths.number,
      },
    },
  },
  {
    title: "Payroll",
    route: "/model/payroll",
    type: "Role",
    description:
      "Payroll is all the money you need to have set aside to pay the crew that power your business.",
    action: {
      startup:
        "Have a think about the team you'll need in place to open, including both front and back-of house staff, then list their job title and approximate wages.",
      open: "Enter your team members, including their wages in accordance with their usual payment cycles.",
    },
    help: {
      "Labour as a proportion of Revenue":
        "Ideally, you don't want this to exceed 30%, and often this can take some time to achieve. Your Labour cost is heavily linked to your menu offering, so consider the time involved in prepping and then serving it to evaluate the menu viability before committing.",
      "Trust & Growth": `Having a dependable team is crucial to your ${venueType}'s day-to-day operations. You only have a limited amount of time to turn a new customer into a regular, and this is heavily dependent on the experience offered by your employees. Paying your employees on time is undoubtedly one of the most crucial aspects of running a business and goes a long way in building culture, trust and growth in your team.`,
      Workflow: `Using a popular online payroll solution can help you stay organised. Hiring a bookkeeper or an accountant to handle payroll for your ${venueType} might be a wise option and save you a lot of time, effort and stress and will free up your time to continue doing what you love.`,
      "Hiring Obligations": `When you hire an employee, you take on additional tax and superannuation obligations. Visit <a href="https://www.ato.gov.au/Business" target="_blank">ATO Business</a> to learn more about how to ensure your ${venueType} is meeting these requirements.`,
    },
    initial: { sort: { key: "role", ascending } },
    primaryKey: "role",
    band: "model",
    icon: "attach_money",
    progressComplete: 3,
    keys: {
      role: {
        keyType: text,
        tip: "Title of the role",
        width: widths.text,
      },
      areaId: {
        keyType: select,
        type: "Area",
        key: "areaId",
        tip: "Area of the venue",
        width: widths.select,
      },
      teamMembers: {
        keyType: float,
        tip: "Quantity of team members performing this role",
        width: 15,
      },
      amount: {
        keyType: currency,
        tip: "Wage amount for each team member performing this role for the period",
        width: widths.currency,
      },
      hours: {
        keyType: float,
        tip: "Hours worked for the period",
        width: widths.number,
      },
      amountPeriodId: {
        keyType: select,
        type: "Period",
        key: "amountPeriodId",
        tip: "Period for the wage amount",
        width: widths.select,
      },
      wagePerHour: {
        keyType: derived,
        dataType: currency,
        factors: ["Operation"],
        // formula: ({ amount, amountPeriod, Operation }) =>
        //   (amount * amountPeriod.perYear) / Operation[0].openHours / Operation[0].period.perYear,
        formula: ({ amount, hours }) => amount / hours,
        // tip: "Average wage for each team member per hour",
        tip: "Wage for each team member per hour",
        width: widths.derived,
      },
    },
  },
  {
    title: "Operation",
    route: "/model/operation",
    type: "Operation",
    description: `Your ${venueType}'s high level functionality plays a crucial role in the success of your operation.`,
    action: {
      startup: `Look over the key areas and play around with some options for your future ${venueType} operation.`,
      open: `Input the current operational data for your existing ${venueType} operation.`,
    },
    help: {
      Framework: `Having insight into your ${venueType}'s key operational functions lets you change the way it does business. A clear, well-defined operational structure can help you decide whether to scale up or down. Keep in mind you can make changes at any time.`,
      "Volume, Scale & Availability": `Blending a profitable menu with these three items and an operationally optimised business model will give you every opportunity to join the realms of successful ${venueType}s.`,
      "Prep & Service Roles": ` Many ${venueType}s underestimate the expense margins; how operationally costly a menu is to run. Assigning Prep and Service roles enables automatic calculation of these labor costs on the Recipe Analysis page.`,
    },
    singleItem: true,
    band: "model",
    icon: "warehouse",
    progressComplete: 7,
    autoCreate: true,
    // warning: "At least one Payroll entry is required before Operation details can be entered.",
    keys: {
      operation: {
        name: "Venue",
        keyType: text,
        tip: "Name of the venue",
        width: widths.text,
      },
      revenue_stage1: {
        keyType: currency,
        tip: `Total estimated revenue for the period - used for ${venueType} Modelling stage only`,
      },
      dishesSold_stage1: {
        name: "Dishes / Portions Sold",
        keyType: integer,
        tip: `Total estimated dishes or portions sold for the period - used for ${venueType} Modelling stage only`,
      },
      openHours: {
        keyType: float,
        tip: "Number of hours open for the period",
      },
      periodId: {
        keyType: select,
        type: "Period",
        key: "periodId",
        tip: "Period for the total estimated revenue, dishes, and hours open",
      },
      salesTaxRate: {
        name: salesTaxName,
        keyType: percentage,
        tip: "Tax rate applied to all sales",
      },
      // overheadPerDish: {
      //   keyType: currency,
      //   tip: "Estimated total overhead cost for each dish sold",
      // },
      prepRoleId: {
        keyType: select,
        type: "Role",
        key: "prepRoleId",
        tip: "Role preparing the food in the kitchen",
      },
      serviceRoleId: {
        keyType: select,
        type: "Role",
        key: "serviceRoleId",
        tip: "Role serving the food to customers",
      },
    },
  },
  {
    title: "Menu",
    route: "/build/menu",
    type: "Recipe",
    description: `A successful ${venueType} menu will boost your brand image and should make you a healthy profit.`,
    action: {
      startup: `Enter the ${venueType} menu you're working on and planning to open with, including both food and drink options.`,
      open: "Enter the menu you currently offer, this is usually a good opportunity to evaluate what is working and what isn't, and to adjust volume if necessary.",
    },
    help: {
      "The Reality of Creativity": `Creating a ${venueType} menu that is both creative and profitable can be a challenge. A number of obstacles can stifle creativity, ranging from restricted space and compliance laws to increased product costs, equipment outlay, and the chef's ability.`,
      "Keeping it Real": `While developing and then launching a ${venueType} menu, it's a good idea to keep all of the above considerations in mind. You need to be operationally realistic about what will be cost effective, consistently served to standards, and ultimately profitable.`,
      "Menu Development":
        "Your menu should have impact in order to attract and maintain loyal customers. Seasonality, simplicity, sustainability, dietary restrictions, and flavour all have to be delicately balanced.",
    },
    initial: { sort: { key: "recipe", ascending } },
    primaryKey: "recipe",
    deleteAdditional: [
      {
        type: "RecipeIngredient",
        idKey: "recipeId",
        // action: "delete all Recipe Ingredients",
      },
    ],
    reroute: "/build/recipeAnalysis",
    band: "build",
    icon: "menu_book",
    progressComplete: 12,
    additionalImport: {
      type: "RecipeIngredient",
      idKey: "recipeId",
      pointer: "recipeIngredients",
      key2Type: "Ingredient",
      key2IdKey: "ingredientId",
      key2Pointer: "ingredient",
    },
    keys: {
      recipe: {
        keyType: text,
        tip: "Name of the recipe",
        width: 30,
      },
      portionSellingPrice: {
        keyType: currency,
        tip: "Selling price for each portion",
        width: widths.currency,
      },
      portionSales: {
        keyType: integer,
        tip: "Number of sales of each portion for the period",
        width: widths.number,
      },
      portionSalesPeriodId: {
        keyType: select,
        type: "Period",
        key: "portionSalesPeriodId",
        tip: "Period for the number of sales",
        width: widths.select,
      },
      portionCount: {
        keyType: float,
        tip: "Quantity of portions made by the recipe",
        width: widths.number,
      },
      prepTime: {
        keyType: duration,
        tip: "Time required to prepare the recipe",
        width: widths.duration,
      },
      serviceTimePerPortion: {
        keyType: duration,
        tip: "Time required to serve a single portion of the recipe",
        width: widths.duration,
      },
      ingredientCount: {
        keyType: derived,
        dataType: integer,
        formula: ({ recipeIngredients }) => recipeIngredients.items.length,
        tip: "Quantity of ingredients used in the recipe",
        width: widths.number,
      },
    },
  },
  {
    title: "Ingredients",
    route: "/build/ingredients",
    type: "Ingredient",
    description: `Ingredients are the core of any profitable and effective ${venueType} menu.`,
    action: {
      startup:
        "Now you've created your sample menu, include all of the ingredients you'll need to make it.",
      open: "Enter all of the ingredients that you are using to make your menu, then evaluate the volume of inventory and its seasonality.",
    },
    help: {
      "Seasonal and Local":
        "Seasonal and locally sourced will never go out of fashion. Buying locally is more cost effective but not necessarily always possible. Any supplier worth their weight will tell you using seasonal food is the way to go, and you'll notice a difference in taste, availability, cost, and quality.",
      "Derivative Method":
        'How many dishes do you think you can make from a simple breakfast tomato? Its easy to feel compelled to buy hundreds of different items to spruce up your menu, but you will thank yourself for incorporating fewer elements. Your storage, prep and service time, cleanup and profit will all benefit from the "less is more" attitude to sourcing ingredients.',
    },
    initial: { sort: { key: "ingredient", ascending } },
    primaryKey: "ingredient",
    band: "build",
    icon: "category",
    progressComplete: 30,
    // warning: "At least one Supplier entry is required before Ingredients can be entered.",
    deleteControl: [
      {
        type: "RecipeIngredient",
        idKey: "ingredientId",
        action: "delete Ingredient from all related Recipes",
      },
    ],
    keys: {
      ingredient: {
        keyType: text,
        tip: "Name of the ingredient",
        width: 30,
      },
      storeId: {
        keyType: select,
        type: "Store",
        key: "storeId",
        tip: "Location where the ingredient is stored",
        width: widths.select,
      },
      supplierId: {
        keyType: select,
        type: "Supplier",
        key: "supplierId",
        tip: "Supplier for the ingredient",
        width: widths.select,
        setOnImport: undefined,
      },
      cost: {
        keyType: currency,
        tip: "Cost of the ingredient per unit",
        width: widths.currency,
      },
      costUnitId: {
        keyType: select,
        type: "Unit",
        key: "costUnitId",
        selectKey: "ingredientUnit",
        tip: "Unit of the ingredient cost",
        width: 10,
      },
      recipeCount: {
        keyType: derived,
        dataType: integer,
        formula: ({ recipeIngredients }) =>
          Array.isArray(recipeIngredients.items) ? recipeIngredients.items.length : 0,
        tip: "Quantity of menu recipes with this ingredient",
        width: widths.derived,
      },
    },
  },
  {
    title: "Recipe Analysis",
    route: "/build/recipeAnalysis",
    type: "Recipe",
    icon: "menu_book",
    description: `Knowing how much it costs to make your recipes while keeping prep and serving consistency in mind will help you hero everything you offer at your ${venueType}.`,
    action: {
      startup: "Plan your recipes, type them in, and see how they stack up.",
      open: "Enter your current recipes and check their performance.",
    },
    singleItem: true,
    // warning: "At least one Recipe Ingredient entry is required.",
    hasMany: { type: "RecipeIngredient", field: "recipeIngredients" },
    primaryKey: "ingredient_",
    help: {
      "Portions or Dishes":
        "For the purpose of analysis we are considering a portion and a dish to be the same. These terms are equally applicable for both food and beverage items.",
      Ingredients: "You can add Ingredients to your selected Recipe from the Ingredients page.",
    },
    warning: `Add entries under ${venueType} Modelling before viewing Recipe Analysis.`,
    keys: {
      recipe: {
        keyType: text,
        tip: "Name of the recipe",
      },
      portionSellingPrice: {
        keyType: currency,
        tip: `Selling price for each portion (inc. ${salesTaxName})`,
        width: widths.currency,
      },
      portionSales: {
        keyType: integer,
        tip: "Number of sales of each portion for the period",
        width: widths.number,
      },
      portionSalesPeriodId: {
        keyType: select,
        type: "Period",
        key: "portionSalesPeriodId",
        tip: "Period for the number of sales",
        width: widths.select,
      },
      portionCount: {
        keyType: float,
        tip: "Quantity of portions made by the recipe",
        width: widths.number,
      },
      prepTime: {
        keyType: duration,
        tip: "Time required to prepare the recipe",
        width: widths.duration,
      },
      serviceTimePerPortion: {
        keyType: duration,
        tip: "Time required to serve a single portion of the recipe",
        width: widths.duration,
      },
    },
    keyMetricsKeys: {
      recipeRevenue: {
        keyType: derived,
        dataType: currency,
        formula: ({ portionCount, portionSellingPrice }) => portionCount * portionSellingPrice,
        tip: "Total revenue for sales of all portions of the recipe",
      },
      ingredientsCost: {
        keyType: derived,
        dataType: currency,
        formula: ({ items }) =>
          items.reduce(
            (a, b) =>
              a +
              b.quantity *
                ((b.ingredient && b.ingredient.cost) || 0) *
                ((b.ingredient && b.ingredient.costUnit.factor) || 0),
            0
          ),
        tip: "Total cost of ingredients for the recipe",
      },
      prepLabourCost: {
        keyType: derived,
        dataType: currency,
        factors: ["Operation", "Period"],
        formula: ({ prepTime, Operation, Period }) =>
          Operation[0] && Operation[0].prepRole
            ? (prepTime *
                Operation[0].prepRole.amount *
                Period.filter((a) => a.id === Operation[0].prepRole.amountPeriodId)[0].perYear) /
              Operation[0].period.perYear /
              Operation[0].openHours /
              3600
            : 0,
        tip: "Cost of recipe prep based on prep time and prep role set",
      },
      serviceLabourCost: {
        keyType: derived,
        dataType: currency,
        factors: ["Operation", "Period"],
        formula: ({ portionCount, serviceTimePerPortion, Operation, Period }) =>
          Operation[0] && Operation[0].serviceRole
            ? (portionCount *
                serviceTimePerPortion *
                Operation[0].serviceRole.amount *
                Period.filter((a) => a.id === Operation[0].serviceRole.amountPeriodId)[0].perYear) /
              Operation[0].period.perYear /
              Operation[0].openHours /
              3600
            : 0,
        tip: "Cost of service based on service time and service role set",
      },
      overheadPerDish: {
        keyType: derived,
        dataType: currency,
        factors: ["Overhead", "Recipe"], // "Operation"
        formula: ({ Overhead, Recipe }) =>
          Overhead.reduce((b, c) => b + c.costPeriod.perYear * c.cost, 0) /
          // (Operation[0].dishesSold_stage1 * Operation[0].period.perYear),
          Recipe.reduce((a, b) => a + b.portionSales * b.portionSalesPeriod.perYear, 0),
        tip: "Estimated total overhead cost per portion sold",
      },
      overheadForRecipe: {
        keyType: derived,
        dataType: currency,
        formula: ({ overheadPerDish, portionCount }) => overheadPerDish * portionCount,
        tip: "Estimated total overhead cost for the whole recipe",
      },
      recipeNetProfit: {
        keyType: derived,
        dataType: currency,
        factors: ["Operation"],
        formula: ({
          portionCount,
          recipeRevenue,
          ingredientsCost,
          prepLabourCost,
          serviceLabourCost,
          overheadPerDish,
          Operation,
        }) =>
          recipeRevenue / (1 + Operation[0].salesTaxRate) -
          ingredientsCost -
          prepLabourCost -
          serviceLabourCost -
          overheadPerDish * portionCount,
        tip: "Total profit for the recipe after all costs deducted",
      },
      recipeNetProfitMargin: {
        keyType: derived,
        dataType: percentage,
        formula: ({ recipeNetProfit, recipeRevenue }) => recipeNetProfit / recipeRevenue,
        tip: "Net profit percentage of recipe revenue",
      },
    },
  },
  {
    title: "Recipe Ingredients",
    type: "RecipeIngredient",
    itemType: "Ingredient",
    filterKey: "recipeId",
    primaryKey: "ingredient_",
    initial: { sort: { key: "ingredient_", ascending } },
    isMany: true,
    m2mKey: "recipeId",
    importDependancy: ["recipeId", "ingredientId"],
    keys: {
      ingredient_: {
        keyType: derived,
        dataType: text,
        formula: ({ ingredient }) => (ingredient && ingredient.ingredient) || "[Not found]",
        tip: "Ingredient included in the recipe",
        width: widths.text,
      },
      quantity: {
        keyType: float,
        tip: "Quantity of units of this ingredient",
        width: widths.number,
      },
      costUnit: {
        keyType: derived,
        dataType: text,
        formula: ({ ingredient }) =>
          (ingredient && ingredient.costUnit.recipeUnit) || "[Not found]",
        tip: "Unit defined for this ingredient",
        width: 20,
      },
      cost: {
        keyType: derived,
        dataType: currency,
        formula: ({ quantity, ingredient }) =>
          quantity *
          ((ingredient && ingredient.cost) || 0) *
          ((ingredient && ingredient.costUnit.factor) || 0),
        tip: "Total cost of this ingredient for the recipe",
        width: widths.currency,
      },
      recipeId: {
        keyType: hidden,
        setOnImport: true,
      },
      ingredientId: {
        keyType: hidden,
        setOnImport: true,
      },
    },
    // warning:
    //   "At least one Ingredient entry is required before Recipe Ingredients can be entered.",
  },
  {
    title: "Dashboard",
    route: "/dashboard",
    // route: "/model/dashboard",
    icon: "dashboard",
    build: false,
    description: `The Dashboard summarise all venue data entered from both the ${venueType} Modelling and Menu Building stages.`,
    action: {
      startup: `Assess your entire projected ${venueType} operation carefully and update your modelling and menu entries to improve profit.`,
      open: `Evaluate your ${venueType} operation and then update your modelling and menu entries to fine tune and increase profit.`,
    },
    help: {
      "Net Profit as a Proportion of Revenue": `Every ${venueType} is unique with profits directly linked to the effectiveness of excellent planning and modelling. Through your Dashboard, you can easily see what percentage of revenue is going where.`,
      "Real numbers in, real numbers out.": `Consider the Dashboard to be your command center. It will greatly assist you in determining the profitability of your ${venueType}, but it is dependent on you entering the correct information and keeping it up to date.`,
      [`Use ${venueType} Model vs Menu Build`]: `Toggling this button changes how the Revenue and Supplier Costs are calculated. In the ${venueType} Modelling phase we enter Revenue and Supplier Costs as generalised numbers. In the Menu Building phase we drill down into the sales and ingredients of each Recipe, allowing us to summarise with greater accuracy. Once you have built your full menu toggle to "Use Menu Build" to show the difference from your initial Revenue and Supplier Cost projections.`,
    },
    warning: `Add entries under ${venueType} Modelling before viewing the Dashboard.`,
    keys: {
      period: {
        keyType: fixed,
        factors: ["Period"],
      },
      perYear: {
        keyType: hidden,
      },
      revenue_stage1: {
        keyType: derived,
        dataType: currency,
        factors: ["Operation"],
        formula: ({ perYear, Operation }) =>
          (Operation[0].revenue_stage1 * Operation[0].period.perYear) / perYear,
      },
      supplierCost_stage1: {
        keyType: derived,
        dataType: currency,
        factors: ["Supplier"],
        formula: ({ perYear, Supplier }) =>
          -Supplier.reduce((a, b) => a + b.cost_stage1 * b.cost_stage1Period.perYear, 0) / perYear,
      },
      labourCost: {
        keyType: derived,
        dataType: currency,
        factors: ["Role"],
        formula: ({ perYear, Role }) =>
          -Role.reduce((a, b) => a + b.teamMembers * b.amount * b.amountPeriod.perYear, 0) /
          perYear,
      },
      overheadCost: {
        keyType: derived,
        dataType: currency,
        factors: ["Overhead"],
        formula: ({ perYear, Overhead }) =>
          -Overhead.reduce((a, b) => a + b.cost * b.costPeriod.perYear, 0) / perYear,
      },
      salesTax: {
        name: salesTaxName,
        keyType: derived,
        dataType: currency,
        factors: ["Operation"],
        formula: ({ Operation, revenue_stage1 }) => -Operation[0].salesTaxRate * revenue_stage1,
      },
      netProfit_stage1: {
        keyType: derived,
        dataType: currency,
        factors: ["Operation"],
        formula: ({
          revenue_stage1,
          supplierCost_stage1,
          labourCost,
          overheadCost,
          salesTax,
          // Operation,
        }) =>
          revenue_stage1 + // / (1 + Operation[0].salesTaxRate)
          supplierCost_stage1 +
          labourCost +
          overheadCost +
          salesTax,
      },
    },
    keyMetricsKeys: {
      netProfitMargin_stage1: {
        keyType: derived,
        dataType: percentage,
        warning: { lower: true, limit: 0, comment: "Venue is not generating a profit" },
        formula: ({ netProfit_stage1, revenue_stage1 }) => netProfit_stage1 / revenue_stage1,
      },
      supplierProportionOfRevenue_stage1: {
        name: "Supplier Proportion Of Revenue",
        keyType: derived,
        dataType: percentage,
        factors: ["Role", "Supplier", "Operation"],
        warning: { limit: 0.3, comment: "Aim to keep Suppliers below 30% of Revenue" },
        formula: ({ supplierCost_stage1, revenue_stage1 }) =>
          Math.abs(supplierCost_stage1 / revenue_stage1),
        // formula: ({ Role, Supplier, Operation }) =>
        //   (Role.reduce((a, b) => a + b.teamMembers * b.amount * b.amountPeriod.perYear, 0) +
        //     Supplier.reduce((a, b) => a + b.cost_stage1 * b.cost_stage1Period.perYear, 0)) /
        //   (Operation[0].revenue_stage1 * Operation[0].period.perYear),
      },
      labourProportionOfRevenue_stage1: {
        name: "Labour Proportion Of Revenue",
        keyType: derived,
        dataType: percentage,
        factors: ["Role", "Operation"],
        warning: { limit: 0.3, comment: "Aim to keep Labour below 30% of Revenue" },
        formula: ({ labourCost, revenue_stage1 }) => Math.abs(labourCost / revenue_stage1),
        // formula: ({ Role, Operation }) =>
        //   Role.reduce((a, b) => a + b.teamMembers * b.amount * b.amountPeriod.perYear, 0) /
        //   (Operation[0].revenue_stage1 * Operation[0].period.perYear),
      },
      overheadProportionOfRevenue_stage1: {
        name: "Overhead Proportion Of Revenue",
        keyType: derived,
        dataType: percentage,
        factors: ["Overhead", "Operation"],
        warning: { limit: 0.3, comment: "Aim to keep Overheads below 30% of Revenue" },
        formula: ({ overheadCost, revenue_stage1 }) => Math.abs(overheadCost / revenue_stage1),
        // formula: ({ Overhead, Operation }) =>
        //   Overhead.reduce((a, b) => a + b.cost * b.costPeriod.perYear, 0) /
        //   (Operation[0].revenue_stage1 * Operation[0].period.perYear),
      },
      overheadAllocationPerDish_stage1: {
        name: "Overhead Allocation /Dish",
        keyType: derived,
        dataType: currency,
        factors: ["Overhead", "Operation"],
        formula: ({ Overhead, Operation }) =>
          Math.abs(
            Overhead.reduce((a, b) => a + b.cost * b.costPeriod.perYear, 0) /
              (Operation[0].dishesSold_stage1 * Operation[0].period.perYear)
          ),
      },
    },
  },
  {
    title: "Dashboard",
    route: "/dashboard",
    // route: "/build/dashboard",
    icon: "dashboard",
    build: true,
    description: `The Dashboard summarise all venue data entered from both the ${venueType} Modelling and Menu Building stages.`,
    action: {
      startup: `Assess your entire projected ${venueType} operation carefully and update your modelling and menu entries to improve profit.`,
      open: `Evaluate your ${venueType} operation and then update your modelling and menu entries to fine tune and increase profit.`,
    },
    help: {
      "Net Profit as a Proportion of Revenue": `Every ${venueType} is unique with profits directly linked to the effectiveness of excellent planning and modelling. Through your Dashboard, you can easily see what percentage of revenue is going where.`,
      "Real numbers in, real numbers out.": `Consider the Dashboard to be your command center. It will greatly assist you in determining the profitability of your ${venueType}, but it is dependent on you entering the correct information and keeping it up to date.`,
      [`Use ${venueType} Model vs Menu Build`]: `Toggling this button changes how the Revenue and Supplier Costs are calculated. In the ${venueType} Modelling phase we enter Revenue and Supplier Costs as generalised numbers. In the Menu Building phase we drill down into the sales and ingredients of each Recipe, allowing us to summarise with greater accuracy. Once you have built your full menu toggle to "Use Menu Build" to show the difference from your initial Revenue and Supplier Cost projections.`,
    },
    warning: `Add entries under ${venueType} Modelling before viewing the Dashboard.`,
    keys: {
      period: {
        keyType: fixed,
        factors: ["Period"],
      },
      perYear: {
        keyType: hidden,
      },
      revenue_stage2: {
        keyType: derived,
        dataType: currency,
        factors: ["Recipe"],
        formula: ({ perYear, Recipe }) =>
          Recipe.reduce(
            (a, b) => a + b.portionSales * b.portionSellingPrice * b.portionSalesPeriod.perYear,
            0
          ) / perYear,
      },
      supplierCost_stage2: {
        keyType: derived,
        dataType: currency,
        factors: ["Recipe"],
        formula: ({ perYear, Recipe }) =>
          -Recipe.reduce(
            (a, b) =>
              a +
              (b.recipeIngredients.items.reduce(
                (c, d) =>
                  c +
                  d.quantity *
                    d.ingredient.cost *
                    (d.ingredient.costUnit && d.ingredient.costUnit.factor),
                0
              ) /
                b.portionCount) *
                b.portionSales *
                b.portionSalesPeriod.perYear,
            0
          ) / perYear,
      },
      labourCost: {
        keyType: derived,
        dataType: currency,
        factors: ["Role"],
        formula: ({ perYear, Role }) =>
          -Role.reduce((a, b) => a + b.teamMembers * b.amount * b.amountPeriod.perYear, 0) /
          perYear,
      },
      overheadCost: {
        keyType: derived,
        dataType: currency,
        factors: ["Overhead"],
        formula: ({ perYear, Overhead }) =>
          -Overhead.reduce((a, b) => a + b.cost * b.costPeriod.perYear, 0) / perYear,
      },
      salesTax: {
        name: salesTaxName,
        keyType: derived,
        dataType: currency,
        factors: ["Operation"],
        formula: ({ Operation, revenue_stage2 }) => -Operation[0].salesTaxRate * revenue_stage2,
      },
      netProfit_stage2: {
        keyType: derived,
        dataType: currency,
        factors: ["Operation"],
        formula: (
          { revenue_stage2, supplierCost_stage2, labourCost, overheadCost, salesTax } // Operation
        ) =>
          revenue_stage2 + // / (1 + Operation[0].salesTaxRate)
          supplierCost_stage2 +
          labourCost +
          overheadCost +
          salesTax,
      },
    },
    keyMetricsKeys: {
      netProfitMargin_stage2: {
        keyType: derived,
        dataType: percentage,
        warning: { lower: true, limit: 0, comment: "Venue is not generating a profit" },
        formula: ({ netProfit_stage2, revenue_stage2 }) => netProfit_stage2 / revenue_stage2,
      },
      supplierProportionOfRevenue_stage2: {
        name: "Supplier Proportion Of Revenue",
        keyType: derived,
        dataType: percentage,
        factors: ["Role", "Supplier", "Operation"],
        warning: { limit: 0.3, comment: "Aim to keep Suppliers below 30% of Revenue" },
        formula: ({ supplierCost_stage2, revenue_stage2 }) =>
          Math.abs(supplierCost_stage2 / revenue_stage2),
        // formula: ({ Role, Recipe }) =>
        //   (Role.reduce((a, b) => a + b.teamMembers * b.amount * b.amountPeriod.perYear, 0) +
        //     Recipe.reduce(
        //       (a, b) =>
        //         a +
        //         (b.recipeIngredients.items.reduce(
        //           (c, d) =>
        //             c +
        //             d.quantity *
        //               d.ingredient.cost *
        //               (d.ingredient.costUnit && d.ingredient.costUnit.factor),
        //           0
        //         ) /
        //           b.portionCount) *
        //           b.portionSales *
        //           b.portionSalesPeriod.perYear,
        //       0
        //     )) /
        //   Recipe.reduce(
        //     (a, b) => a + b.portionSellingPrice * b.portionSales * b.portionSalesPeriod.perYear,
        //     0
        //   ),
      },
      labourProportionOfRevenue_stage2: {
        name: "Labour Proportion Of Revenue",
        keyType: derived,
        dataType: percentage,
        factors: ["Role", "Recipe"],
        warning: { limit: 0.3, comment: "Aim to keep Labour below 30% of Revenue" },
        formula: ({ labourCost, revenue_stage2 }) => Math.abs(labourCost / revenue_stage2),
        // formula: ({ Role, Recipe }) =>
        //   Role.reduce((a, b) => a + b.teamMembers * b.amount * b.amountPeriod.perYear, 0) /
        //   Recipe.reduce(
        //     (a, b) => a + b.portionSellingPrice * b.portionSales * b.portionSalesPeriod.perYear,
        //     0
        //   ),
      },
      overheadProportionOfRevenue_stage2: {
        name: "Overhead Proportion Of Revenue",
        keyType: derived,
        dataType: percentage,
        factors: ["Overhead", "Operation"],
        warning: { limit: 0.3, comment: "Aim to keep Overheads below 30% of Revenue" },
        formula: ({ overheadCost, revenue_stage2 }) => Math.abs(overheadCost / revenue_stage2),
        // formula: ({ Overhead, Recipe }) =>
        //   Overhead.reduce((a, b) => a + b.cost * b.costPeriod.perYear, 0) /
        //   Recipe.reduce(
        //     (a, b) => a + b.portionSellingPrice * b.portionSales * b.portionSalesPeriod.perYear,
        //     0
        //   ),
      },
      overheadAllocationPerDish_stage2: {
        name: "Overhead Allocation /Dish",
        keyType: derived,
        dataType: currency,
        factors: ["Overhead", "Recipe"],
        formula: ({ Overhead, Recipe }) =>
          Math.abs(
            Overhead.reduce((a, b) => a + b.cost * b.costPeriod.perYear, 0) /
              Recipe.reduce((a, b) => a + b.portionSales * b.portionSalesPeriod.perYear, 0)
          ),
      },
    },
  },
];

export {
  email,
  venueType,
  loading,
  adminTypes,
  demoVenues,
  templateVenues,
  venueSettings,
  pageConfig as config,
};
