import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props. https://www.npmjs.com/package/prop-types
import PropTypes from "prop-types";

// import listGroups from "adminActions";
import { _ } from "general";
import { update, list } from "database";
import { demoVenues } from "config";

const type = "Operation";

function useVenues({ userGroups, selectedVenue }) {
  const venueTemplate = {
    openForBusiness: false,
    dashboardBuild: false,
    readOnly: false,
    subscribed: false,
  };
  const [venue, setVenue] = useState(venueTemplate);
  const [venues, setVenues] = useState([]);

  // const createVenue = async ({ event, operation, postNotification }) => {
  //   if (event) event.preventDefault();
  //   if (operation) {
  //     // const operation = event.target.textContent;
  //     let notification;
  //     let template;
  //     const { keys } = config.filter((a) => a.type === type)[0];
  //     try {
  //       const { dataSet: Period } = await list({ type: "Period" });
  //       const lookups = { Period };
  //       template = Object.assign(
  //         {},
  //         ...Object.entries(keys).map(([k, v]) => ({
  //           [k]: v.keyType.initial(
  //             v.keyType.display === "select" && _.exists(lookups[v.type]) && lookups[v.type][0].id
  //           ),
  //         })),
  //         {
  //           group: `${operation || "New Venue"}-${Date.now()}`,
  //           operation: `${operation || "New Venue"}`,
  //           openForBusiness: false,
  //           dashboardBuild: false,
  //           readOnly: false,
  //           mainUser: userId,
  //           subscribed: true,
  //         }
  //       );
  //       const createdVenue = await create({ entry: template, type });
  //       notification = {
  //         level: "success",
  //         title: "Create Venue",
  //         content: `${createdVenue.operation} created.`,
  //       };
  //     } catch (err) {
  //       // eslint-disable-next-line no-console
  //       console.log(err);
  //       notification = {
  //         level: "error",
  //         title: "Unable to create venue",
  //         content: `${template.operation} could not be created.`,
  //       };
  //     }
  //     if (postNotification) postNotification(notification);
  //   }
  // };

  const updateVenue = ({ id, ...setting }) => {
    if (venue.id === id) setVenue((a) => ({ ...a, ...setting }));
    setVenues((a) => a.map((b) => (b.id === id ? { ...b, ...setting } : b)));
    if (!demoVenues.includes(id)) update({ entry: { id, ...setting }, type });
  };

  useEffect(() => {
    (async () => {
      const { dataSet } = await list({ type });
      if (_.exists(dataSet)) {
        const sortedDataSet = dataSet.sort(({ operation: a }, { operation: b }) =>
          a.localeCompare(b)
        );
        // if (selectedVenue || selectedVenue === null)
        setVenue(
          sortedDataSet.map((a) => a.id).includes(selectedVenue?.id)
            ? selectedVenue
            : sortedDataSet[0]
        );
        setVenues(sortedDataSet);
      }
    })();
  }, [userGroups, selectedVenue]);

  return { venue, venues, setVenue, updateVenue };
}
useVenues.propTypes = {
  // userId: PropTypes.string.isRequired,
  userGroups: PropTypes.arrayOf(PropTypes.string),
  selectedVenue: PropTypes.string,
  // admin: PropTypes.bool,
};

export default useVenues;
