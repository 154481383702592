// prop-types is a library for typechecking of props. https://www.npmjs.com/package/prop-types
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

export default function SystemAccess({ admin, systemVenues }) {
  const access = admin ? "Admin" : systemVenues.join(", ");
  return (
    <MDBox display="flex" py={1} pr={2}>
      <MDTypography variant="body2" fontWeight="bold" textTransform="capitalize">
        System Access: &nbsp;
      </MDTypography>
      <MDTypography variant="body2">
        &nbsp;
        {access}
      </MDTypography>
    </MDBox>
  );
}
SystemAccess.propTypes = {
  admin: PropTypes.bool.isRequired,
  systemVenues: PropTypes.arrayOf(PropTypes.string).isRequired,
};
