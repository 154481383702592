import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui icons https://fonts.google.com/icons
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
// import useTheme from "@mui/material/styles/useTheme";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import { formatNums, camelToTitleCase, unformatNums } from "general";
import Field from "components/Field";

function Row({ item, actions, lookups, pageCfg, readOnly, isAdvanced }) {
  // const theme = useTheme();
  const initialItem = { ...item };
  const { type, keys, primaryKey } = pageCfg;
  const [unformattedItem, setUnformattedItem] = useState({});
  const [formattedItem, setFormattedItem] = useState({});
  const [button, enableButton] = useState(false);
  const [del, setDel] = useState({ color: "secondary" });
  const {
    createItem,
    updateItem,
    editItem,
    deleteItem,
    addIngredientToRecipe,
    updateFilter,
    changeVenue,
  } = actions;

  useEffect(() => {
    setUnformattedItem(item);
    setFormattedItem(
      // updateFilter
      //   ? item
      //   :
      formatNums({
        item: createItem || updateFilter ? item : { ...keys, ...item }, // ...setDerivedKeys({ item, keys, lookups })
        keys,
      })
    );
  }, [item, lookups]);

  const handleFieldChange = (k, newValue) => {
    if (!readOnly) {
      const unformattedItemX = unformatNums({
        item: { ...formattedItem, [k]: newValue.id || newValue },
        keys,
      });
      enableButton(unformattedItemX[primaryKey] !== "");
      setFormattedItem((existing) => ({
        ...existing,
        [k]: newValue.id || newValue,
      }));
      setUnformattedItem(unformattedItemX);
    }
    if (keys[k].changeVenue) changeVenue({ newVenue: newValue });
  };

  const handleFieldBlur = () => {
    if (!readOnly) {
      if (updateItem) updateItem({ item: unformattedItem });
    }
  };

  const handleCreateItem = (event) => {
    createItem({ event, item: unformattedItem }); // pageCfg
    setUnformattedItem(initialItem);
    setFormattedItem({
      ...formatNums({
        item: {
          ...initialItem,
          // ...setDerivedKeys({
          //   item: initialItem,
          //   keys,
          //   lookups,
          // }),
        },
        keys,
      }),
    });
    enableButton(false);
  };

  const handleKeyDown = (event) => {
    // keyCode 13 is Enter
    if (event.keyCode === 13) {
      if (updateFilter) updateFilter({ item: unformattedItem });
      if (createItem) handleCreateItem(event);
    }
  };

  const handleAddIngredientToRecipe = () => {
    addIngredientToRecipe({
      ingredientId: unformattedItem.id,
      ingredient: unformattedItem.ingredient,
    });
    updateItem({ item: unformattedItem, derivedOnly: true });
  };

  const handleDelete = () => {
    if (del.item && del.item.id === unformattedItem.id) {
      deleteItem({ item: unformattedItem });
    } else {
      setDel({ item, color: "error" });
    }
  };

  return formattedItem && Object.keys(formattedItem).length > 0 ? (
    <tr
    // onBlur={(e) => {
    //   // https://muffinman.io/blog/catching-the-blur-event-on-an-element-and-its-children/
    //   const { currentTarget } = e;
    //   requestAnimationFrame(() => {
    //     if (!currentTarget.contains(document.activeElement) && actions.updateItem) {
    //       actions.updateItem(unformattedItem);
    //     }
    //   });
    // }}
    >
      {Object.entries(formattedItem).flatMap(([k, v]) =>
        k in keys && keys[k].keyType.display !== "hidden" // && ![m2mKey].includes(k)
          ? [
              <td key={k} className={updateFilter && "filter"}>
                <Field
                  aria-label={keys[k].name || camelToTitleCase(k)}
                  placeholder={keys[k].name || camelToTitleCase(k)}
                  value={v}
                  lookups={
                    type === "Membership"
                      ? Object.assign(
                          {},
                          ...Object.entries(lookups).map(([kk, vv]) => ({
                            [kk]: vv.filter((a) => a.membershipId === item.id),
                          }))
                        )
                      : lookups
                  }
                  lovs={keys[k].options}
                  singleKey={keys[k]}
                  // readOnly={k === primaryKey && !actions.createItem}
                  onKeyDown={handleKeyDown}
                  // onKeyPress={handleKeyDown}
                  onChange={(newValue) => handleFieldChange(k, newValue)}
                  onBlur={handleFieldBlur}
                  showSelect={(updateFilter && true) || type === "Membership"}
                />
              </td>,
            ]
          : []
      )}
      <td key="buttons">
        <MDBox
          display="flex"
          // justifyContent="flex-start"
          flexDirection={{ xs: "row" }}
          alignItems="center"
          marginRight="2px"
          // padding="2px"
        >
          {editItem && (
            <NavLink to={editItem} state={{ itemId: formattedItem.id }}>
              <Tooltip
                title={!isAdvanced ? `View ${unformattedItem[primaryKey].trim()}` : ""}
                placement="left"
                TransitionComponent={Zoom}
              >
                <span style={{ paddingRight: "2px" }}>
                  <MDButton variant="gradient" color="secondary" iconOnly type="button">
                    <Icon fontSize="large">visibility</Icon>
                  </MDButton>
                </span>
              </Tooltip>
            </NavLink>
          )}
          {createItem && (
            <Tooltip
              title={!isAdvanced ? `Add ${unformattedItem[primaryKey].trim()}` : ""}
              placement="left"
              TransitionComponent={Zoom}
            >
              <span>
                <MDButton
                  variant="gradient"
                  color="secondary"
                  iconOnly
                  disabled={!button}
                  type="button"
                  onClick={handleCreateItem}
                >
                  <Icon fontSize="medium">add_circle</Icon>
                </MDButton>
              </span>
            </Tooltip>
          )}
          {!createItem && addIngredientToRecipe && (
            <Tooltip
              title={
                !isAdvanced ? `Add ${unformattedItem[primaryKey].trim()} to selected Recipe` : ""
              }
              placement="left"
              TransitionComponent={Zoom}
            >
              <span style={{ paddingRight: "2px" }}>
                <MDButton
                  variant="gradient"
                  color="secondary"
                  iconOnly
                  type="button"
                  disabled={readOnly}
                  // title={`Add ${unformattedItem[primaryKey].trim()} to selected Recipe`}
                  onClick={handleAddIngredientToRecipe}
                >
                  <Icon fontSize="large">library_add</Icon>
                </MDButton>
              </span>
            </Tooltip>
          )}
          {!createItem && !updateFilter && type !== "Membership" && (
            <Tooltip
              title={!isAdvanced ? `Delete ${unformattedItem[primaryKey].trim()}` : ""}
              placement="left"
              TransitionComponent={Zoom}
            >
              <span>
                <MDButton
                  variant="gradient"
                  color={del.color}
                  iconOnly
                  type="button"
                  disabled={readOnly}
                  onClick={handleDelete}
                  onBlur={() => setDel({ color: "secondary" })}
                >
                  <Icon fontSize="large">delete_forever</Icon>
                </MDButton>
              </span>
            </Tooltip>
          )}
          {updateFilter && (
            <Tooltip
              title={!isAdvanced ? "Apply Search filter" : ""}
              placement="left"
              TransitionComponent={Zoom}
            >
              <MDButton
                variant="gradient"
                color="secondary"
                iconOnly
                type="button"
                onClick={() => updateFilter({ item: unformattedItem })}
              >
                <Icon fontSize="large">filter_list</Icon>
              </MDButton>
            </Tooltip>
          )}
        </MDBox>
      </td>
    </tr>
  ) : null;
}

// https://www.npmjs.com/package/prop-types
Row.defaultProps = {
  actions: {},
  lookups: {},
  readOnly: false,
  isAdvanced: false,
  // pageCfg: {},
};

Row.propTypes = {
  item: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.object,
      PropTypes.array,
    ])
  ).isRequired,
  actions: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.func, PropTypes.bool, PropTypes.string])
  ),
  lookups: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number, PropTypes.bool])
    )
  ),
  pageCfg: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.object,
      PropTypes.array,
    ])
  ).isRequired,
  readOnly: PropTypes.bool,
  isAdvanced: PropTypes.bool,
};

export default Row;
