/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useContext } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
// import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";

// Data
// import timelineData from "layouts/pages/projects/timeline/data/timelineData";

// HospoSure
import { AppContext } from "GlobalContext";
import { config } from "config";
import useNotices from "hooks/useNotices";
import PageTitleBox from "components/PageTitleBox";
import VenueSettings from "components/VenueSettings";
import useProgress from "./hooks/useProgress";
import TimelineList from "./components/TimelineList";
import TimelineItem from "./components/TimelineItem";

function Timeline() {
  const renderNotice = useNotices();
  const { userProfile, venue, membership } = useContext(AppContext);
  const { isAdvanced, admin } = userProfile;
  const { userGroup, openForBusiness } = venue;
  const { progress } = useProgress({ userGroup, openForBusiness });
  const modelProgress = progress.filter((a) => a.band === "model");
  const buildProgress = progress.filter((a) => a.band === "build");
  const pageCfg = config.filter((a) => a.route === "/")[0];
  const { icon, action } = pageCfg;

  return (
    <DashboardLayout>
      {renderNotice("Global")}
      <DashboardNavbar {...{ venue, membership, admin }} />
      <Grid container item spacing={3} xs={12}>
        <Grid item xs={12}>
          <PageTitleBox
            icon={icon}
            title={pageCfg.title}
            description={pageCfg.description}
            action={action && openForBusiness ? action.open : action && action.startup}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <VenueSettings {...{ venue, show: ["openForBusiness"], isAdvanced }} />
          </Grid>
        </Grid>
        {progress.length > 0 && (
          <Grid item xs={12} lg={6}>
            <TimelineList title="Cafe Modelling Stage">
              {modelProgress.map(
                ({ color, icon: sectionIcon, title, description, dateTime }, i) => (
                  <TimelineItem
                    key={title + color}
                    color={color}
                    icon={sectionIcon}
                    title={title}
                    dateTime={dateTime} // status
                    description={description}
                    // badges={badges}
                    lastItem={i + 1 === modelProgress.length}
                  />
                )
              )}
            </TimelineList>
          </Grid>
        )}
        {progress.length > 0 && (
          <Grid item xs={12} lg={6}>
            <TimelineList title="Menu Building Stage">
              {buildProgress.map(
                ({ color, icon: sectionIcon, title, description, dateTime }, i) => (
                  <TimelineItem
                    key={title + color}
                    color={color}
                    icon={sectionIcon}
                    title={title}
                    dateTime={dateTime} // status
                    description={description}
                    // badges={badges}
                    lastItem={i + 1 === buildProgress.length}
                  />
                )
              )}
            </TimelineList>
          </Grid>
        )}
      </Grid>
      <Footer />
    </DashboardLayout>
  );
}

export default Timeline;
