// prop-types is a library for typechecking of props. https://www.npmjs.com/package/prop-types
import PropTypes from "prop-types";

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "components/FormField";

// HospoSure
import { venueType } from "config";

export default function UserVenues({ admin, userVenues, isAdvanced, onChange, onBlur }) {
  return userVenues?.length > 0 && !admin ? (
    <MDBox display="flex" py={1} pr={2}>
      <MDTypography variant="body2" fontWeight="bold" textTransform="capitalize">
        Your {venueType} venue{userVenues?.length > 1 ? "s" : ""}: &nbsp;
      </MDTypography>
      <Tooltip
        title={!isAdvanced ? `Name of your ${venueType} venue` : ""}
        placement="right"
        TransitionComponent={Zoom}
      >
        <span>
          {userVenues.map(({ id, operation }) => (
            <MDBox key={id}>
              <FormField
                {...{
                  type: "text",
                  value: operation,
                  onChange: (event) => onChange(id, event.target.value),
                  onBlur: (event) => onBlur(id, event.target.value),
                }}
              />
            </MDBox>
          ))}
        </span>
      </Tooltip>
    </MDBox>
  ) : null;
}
UserVenues.propTypes = {
  admin: PropTypes.bool.isRequired,
  userVenues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      operation: PropTypes.string.isRequired,
    })
  ),
  isAdvanced: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};
UserVenues.defaultProps = {
  userVenues: [],
};
