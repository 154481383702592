/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props. https://www.npmjs.com/package/prop-types
import PropTypes from "prop-types";

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// import Tooltip from "@mui/material/Tooltip";
// import Zoom from "@mui/material/Zoom";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";

// HospoSure
import { loading } from "config";
import { camelToTitleCase, formatNums, unformatNums, _ } from "general";
import Error from "components/Error";
import Loading from "components/Loading";
import Field from "components/Field";

function Form({ pageCfg, readOnly, Items, userProfile }) {
  const { keys, keyMetricsKeys, warning, hasMany } = pageCfg;
  // const { m2mKey } = m2m;
  const [unformattedItem, setUnformattedItem] = useState({});
  const [formattedItem, setFormattedItem] = useState({});
  // const [refresh, setRefresh] = useState(false);
  const { soloItem, updateItem, lookups, load } = Items;
  const { isAdvanced } = userProfile;

  const handleFieldChange = (k, newValue) => {
    if (!readOnly) {
      const localUnformattedItem = unformatNums({
        item: { ...formattedItem, [k]: newValue.id || newValue },
        keys,
      });
      setFormattedItem((existing) => ({
        ...existing,
        [k]: newValue.id || newValue,
      }));
      setUnformattedItem(localUnformattedItem);
    }
  };

  const handleFieldBlur = () => {
    setFormattedItem((existing) => ({
      ...existing,
      ...formatNums({
        item: unformattedItem,
        keys,
      }),
    }));

    if (!readOnly && updateItem) updateItem({ item: unformattedItem, isHasMany: hasMany !== null });
  };

  const handleKeyDown = (event) => {
    // keyCode 13 is Enter
    if (event.keyCode === 13) {
      handleFieldBlur();
    }
  };

  useEffect(() => {
    if (_.exists(soloItem) && load >= loading.itemsFetched) {
      setUnformattedItem(soloItem);
      setFormattedItem(
        formatNums({
          item: soloItem,
          keys: { ...keys, ...keyMetricsKeys },
        })
      );
    }
  }, [soloItem, load === loading.itemsFetched]);

  // onBlur={(e) => {
  //   // https://muffinman.io/blog/catching-the-blur-event-on-an-element-and-its-children/
  //   const { currentTarget } = e;
  //   requestAnimationFrame(() => {
  //     if (!currentTarget.contains(document.activeElement)) {
  //       updateItem(item);
  //       // eslint-disable-next-line no-console
  //       console.log("blur-update");
  //     }
  //   });
  // }}

  return load !== loading.lookupsMissing ? (
    <>
      <Grid item xs={12}>
        <Card>
          <MDBox p={3}>
            {_.exists(formattedItem) ? (
              <Grid container spacing={3}>
                {Object.entries(formattedItem)
                  .filter(([k]) => k in keys)
                  .map(([k, v]) => (
                    // <Tooltip
                    //   title={keys[k].tip}
                    //   placement="top"
                    //   TransitionComponent={Zoom}
                    //   key={k}
                    // >
                    <Grid item xs={12} sm={6} md={4} lg={3} key={k}>
                      <Field
                        title={!isAdvanced ? keys[k].tip : ""}
                        label={keys[k].name || camelToTitleCase(k)}
                        value={v}
                        lookups={lookups}
                        singleKey={keys[k]}
                        onKeyDown={handleKeyDown}
                        onChange={(newValue) => handleFieldChange(k, newValue)}
                        // onKeyPress={handleKeyDown}
                        // onBlur={(newV) =>
                        //   !readOnly && updateItem({ item: { ...items[0], [k]: newV } })
                        // }
                        onBlur={handleFieldBlur}
                      />
                    </Grid>
                    // </Tooltip>
                  ))}
              </Grid>
            ) : (
              <Loading />
            )}
          </MDBox>
        </Card>
      </Grid>
      {_.exists(formattedItem) &&
      keyMetricsKeys &&
      Object.entries(formattedItem).filter(([k, v]) => k in keyMetricsKeys && v).length > 0 ? (
        <Grid item xs={12}>
          {/* <Card onClick={() => setRefresh((a) => !a)}> */}
          <Card>
            <MDBox p={3}>
              <Grid container spacing={3}>
                {Object.entries(formattedItem)
                  .filter(([k]) => k in keyMetricsKeys)
                  .map(([k, v]) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={k}>
                      <Field
                        singleKey={keyMetricsKeys[k]}
                        label={camelToTitleCase(k)}
                        value={v}
                        lookups={lookups}
                        readOnly
                        title={keyMetricsKeys[k].tip}
                      />
                    </Grid>
                  ))}
              </Grid>
            </MDBox>
          </Card>
        </Grid>
      ) : null}
    </>
  ) : (
    <Grid item xs={12}>
      <Error message={warning} />
    </Grid>
  );
}
Form.defaultProps = {
  // userProfile: null,
  // itemId: null,
  readOnly: false,
};
Form.propTypes = {
  userProfile: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.func,
      PropTypes.array,
      PropTypes.bool,
      PropTypes.number,
    ])
  ).isRequired,
  pageCfg: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.func,
      PropTypes.array,
      PropTypes.bool,
      PropTypes.number,
    ])
  ).isRequired,
  // itemId: PropTypes.string,
  readOnly: PropTypes.bool,
  Items: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.func,
      PropTypes.array,
      PropTypes.bool,
      PropTypes.number,
    ])
  ).isRequired,
};

export default Form;
