/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useContext } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";

// HospoSure
import { AppContext } from "GlobalContext";
import useNotices from "hooks/useNotices";
import ProfileDetails from "./components/ProfileDetails";
import Membership from "./components/Membership";

export default function Overview() {
  const renderNotice = useNotices();
  const {
    userProfile: { id, name, email, admin, signOut, isAdvanced },
    venue: { venues, updateVenue },
    membership,
    stripe,
  } = useContext(AppContext);

  return (
    <DashboardLayout>
      {renderNotice("global")}
      <DashboardNavbar />
      <Grid container spacing={3}>
        <Grid item xs={12} xl={10}>
          <ProfileDetails
            {...{
              title: "Profile Details",
              description: "Information you provided on sign up.",
              info: {
                name,
                email,
              },
              shadow: false,
              signOut,
            }}
          />
        </Grid>
        <Grid item xs={12} xl={10}>
          <Membership
            {...{
              member: { email, id },
              // customerEmail: email,
              // userId: id,
              membership,
              stripe,
              venues,
              admin,
              updateVenue,
              isAdvanced,
              notice: renderNotice("Subscribe"),
            }}
          />
        </Grid>
        {/* {(subscribedQuantity > userSubscribedVenues || admin) && (
          <Grid item xs={12} xl={10}>
            <CreateVenue {...{ createVenue }} />
          </Grid>
        )} */}
        {/* <Grid item xs={12} xl={10}>
          <DeleteAccount fromEmail={email} />
        </Grid> */}
      </Grid>
      <Footer />
    </DashboardLayout>
  );
}
