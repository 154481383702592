// prop-types is a library for typechecking of props. https://www.npmjs.com/package/prop-types
import PropTypes from "prop-types";

import { useState, useEffect } from "react";

import { _ } from "general";
import { list } from "database";

const type = "Membership";

function useMembership({ userId }) {
  const [membership, setMembership] = useState({});

  async function getMembership() {
    const { dataSet } = await list({ type, filter: { userId: { eq: userId } } });
    if (_.exists(dataSet)) {
      setMembership(dataSet.sort(({ updatedAt: a }, { updatedAt: b }) => b.localeCompare(a))[0]);
    }
  }

  useEffect(() => {
    if (_.exists(userId)) getMembership();
  }, [userId]);

  return { membership };
}
useMembership.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default useMembership;
