/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// HospoSure
import { camelToTitleCase } from "general";
import Venue from "./components/Venue";

function Breadcrumbs({ icon, route, light, venue }) {
  const routes = route.slice(0, -1);
  const page = route.at(-1);

  return (
    <MDBox mr={{ xs: 0, xl: 8 }}>
      <MuiBreadcrumbs
        sx={{
          "& .MuiBreadcrumbs-separator": {
            color: ({ palette: { white, text } }) => (light ? white.main : text.main), // grey[600]
          },
        }}
      >
        <Link to="/">
          <MDTypography
            component="span"
            variant="body2"
            color={light ? "white" : "text"}
            opacity={light ? 0.8 : 0.5}
            sx={{ lineHeight: 0 }}
          >
            <Icon>{icon}</Icon>
          </MDTypography>
        </Link>
        {!route.some((a) => ["admin", "profile"].includes(a)) ? (
          <MDTypography
            variant="button"
            fontWeight="regular"
            // textTransform="capitalize"
            color={light ? "white" : "text"}
            opacity={light ? 0.8 : 0.5}
            sx={{ lineHeight: 0 }}
          >
            <Venue {...{ page, venue }} />
          </MDTypography>
        ) : null}
        {routes.map((el) => (
          // <Link to={`/${el}`} key={el}>
          <MDTypography
            key={el}
            component="span"
            variant="button"
            fontWeight="regular"
            textTransform="capitalize"
            color={light ? "white" : "text"}
            opacity={light ? 0.8 : 0.5}
            // sx={{ lineHeight: 0 }}
          >
            {el}
          </MDTypography>
          // </Link>
        ))}
        {page ? (
          <MDTypography
            variant="button"
            fontWeight="regular"
            textTransform="capitalize"
            // color={light ? "white" : "dark"}
            // sx={{ lineHeight: 0 }}
          >
            {camelToTitleCase(page)}
          </MDTypography>
        ) : null}
      </MuiBreadcrumbs>
      {/* <MDTypography
        fontWeight="bold"
        textTransform="capitalize"
        variant="h6"
        color={light ? "white" : "dark"}
        noWrap
      >
        {title.replace("-", " ")}
      </MDTypography> */}
    </MDBox>
  );
}
Breadcrumbs.defaultProps = {
  light: false,
};
Breadcrumbs.propTypes = {
  icon: PropTypes.node.isRequired,
  route: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  light: PropTypes.bool,
  venue: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.bool, PropTypes.func])
  ).isRequired,
};

export default Breadcrumbs;
