// prop-types is a library for typechecking of props. https://www.npmjs.com/package/prop-types
import PropTypes from "prop-types";

import { useState, useEffect } from "react";

// Stripe https://stripe.com/docs/payments/checkout/client-subscription
// import { loadStripe } from "@stripe/stripe-js";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// HospoSure
import env from "env";
import { demoVenues, templateVenues } from "config";
import ProfileCardHeader from "components/ProfileCardHeader";
import Subscription from "./components/Subscription";
import SystemAccess from "./components/SystemAccess";
import UserVenues from "./components/UserVenues";

const { portalLink } = env;

// function SubscriptionOption({ period, amount, amountPerMonth, saving }) {
//   <Card>
//     <MDBox p={2}>
//       <MDTypography variant="h6" fontWeight="medium">
//         {period}
//       </MDTypography>
//       <hr />
//       <MDTypography variant="body2">
//         ${amount}
//       </MDTypography>
//       <MDTypography variant="body2" color="text">
//         / {period}
//         <br />
//         Equivalent to ${amountPerMonth} per month
//         <br />
//         {saving} saving
//       </MDTypography>
//     </MDBox>
//   </Card>;
// }

export default function Membership({
  member,
  membership,
  // stripe,
  venues,
  admin,
  updateVenue,
  isAdvanced,
  notice,
}) {
  const systemVenues = venues
    .filter((a) => [...demoVenues, ...templateVenues].includes(a.id))
    .map((a) => a.operation);
  const [userVenues, setUserVenues] = useState(
    venues.filter((a) => ![...demoVenues, ...templateVenues].includes(a.id))
  );
  const { quantity } = membership;

  const onChange = (id, updated) =>
    setUserVenues((existingVenues) =>
      existingVenues.map((venue) => (venue.id === id ? { ...venue, operation: updated } : venue))
    );

  const onBlur = (id, operation) => updateVenue({ id, operation });

  useEffect(() => {
    setUserVenues(venues.filter((a) => ![...demoVenues, ...templateVenues].includes(a.id)));
  }, [venues]);

  return (
    <Card>
      <MDBox p={3}>
        <ProfileCardHeader
          {...{
            icon: "loyalty",
            title: "Membership",
            description: "Your subscription and access levels.",
            buttons: [
              {
                key: 0,
                link: `${portalLink}`,
                label: "Manage Subscription",
                display: !admin && quantity,
                target: "_blank",
              },
              // {
              //   key: 1,
              //   link: `${stripe.paymentLink}?${params}`,
              //   label: "Subscribe",
              //   display: !subscribed,
              //   variant: "gradient",
              // },
            ],
          }}
        />
        <MDBox
          // display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          // flexDirection={{ xs: "column", sm: "row" }}
          pt={2}
        >
          <SystemAccess {...{ admin, systemVenues }} />
          <Subscription {...{ admin, member, membership, notice }} />
          <UserVenues {...{ admin, userVenues, isAdvanced, onChange, onBlur }} />
        </MDBox>
      </MDBox>
    </Card>
  );
}
Membership.defaultProps = {
  // customerEmail: "",
  // userId: "",
  member: { id: "", email: "" },
  membership: { subscribed: false, quantity: 0 },
  // stripe: {},
  venues: [],
  admin: false,
  notice: null,
};
Membership.propTypes = {
  // customerEmail: PropTypes.string,
  // userId: PropTypes.string,
  member: PropTypes.objectOf(PropTypes.string),
  membership: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string])
  ),
  // stripe: PropTypes.objectOf(PropTypes.string),
  venues: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object])
    )
  ),
  admin: PropTypes.bool,
  updateVenue: PropTypes.func.isRequired,
  isAdvanced: PropTypes.bool.isRequired,
  notice: PropTypes.element,
};
