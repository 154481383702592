/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useContext } from "react";
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props. https://www.npmjs.com/package/prop-types
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React examples
// import { useMaterialUIController, setOpenConfigurator } from "context";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";

// HospoSure
import { AppContext } from "GlobalContext";
import { config } from "config";
import { _ } from "general";
import useNotices from "hooks/useNotices";
import useNotifications from "hooks/useNotifications";
import PageTitleBox from "components/PageTitleBox";
import useItems from "./hooks/useItems";
import Table from "./components/Table";
import Form from "./components/Form";

function Entries({ route }) {
  const renderNotice = useNotices();
  const location = useLocation();
  const { postNotification, renderNotificationSB } = useNotifications();
  const { venue, userProfile, membership } = useContext(AppContext);
  const { userGroup, openForBusiness, readOnly: venueReadOnly } = venue;
  const { itemId } = location.state ? location.state : { itemId: null };
  const { maxItems, admin: userAdmin } = userProfile;
  const pageCfg = config.filter((a) => a.route === route)[0];
  const {
    title,
    description,
    icon,
    singleItem,
    admin,
    action,
    hasMany,
    readOnly: pageReadOnly,
  } = pageCfg;
  const hasManyCfg = hasMany && config.filter((a) => a.type === hasMany.type)[0];
  const { Items } = useItems({
    userGroup: admin ? null : userGroup,
    maxItems,
    userProfile,
    pageCfg,
    itemId,
    // refresh,
  });
  const readOnly = (venueReadOnly && !userProfile.admin) || pageReadOnly;

  // useEffect(() => {
  //   setPageCfg(config.filter((a) => a.route === route)[0]);
  // }, [route]);

  return (
    <DashboardLayout>
      {renderNotice("Global")}
      <DashboardNavbar {...{ venue, membership, admin: userAdmin }} />
      <Grid container spacing={3}>
        {pageCfg && (
          <PageTitleBox
            icon={icon}
            title={title}
            description={description}
            action={action && openForBusiness ? action.open : action && action.startup}
          />
        )}
        {pageCfg &&
          userProfile &&
          (singleItem ? (
            <Form {...{ userProfile, pageCfg, readOnly, Items }} />
          ) : (
            <Table
              {...{
                userProfile,
                venue,
                pageCfg,
                readOnly,
                Items,
                postNotification,
              }}
            />
          ))}

        {hasManyCfg && userProfile && singleItem && _.exists(Items.items) && (
          <Table
            {...{
              userProfile,
              venue,
              pageCfg: hasManyCfg,
              readOnly,
              Items,
              postNotification,
            }}
          />
        )}
      </Grid>
      <Footer />
      {renderNotificationSB}
    </DashboardLayout>
  );
}
Entries.propTypes = {
  route: PropTypes.string.isRequired,
};

export default Entries;
