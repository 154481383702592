// prop-types is a library for typechecking of props https://www.npmjs.com/package/prop-types
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

export default function FilterSwitch({ switchFilter, showFilter, isAdvanced }) {
  return (
    <Grid item xs={5} md={3} lg={2}>
      <Card>
        <MDBox px={2} py={1.5}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Tooltip
                title={!isAdvanced ? `${showFilter ? "Hide" : "Show"} Search filter` : ""}
                placement="top"
                TransitionComponent={Zoom}
              >
                <Switch checked={showFilter} onChange={() => switchFilter()} />
              </Tooltip>
            </Grid>
            <Grid item xs={6}>
              <MDTypography variant="caption" color="text">
                Search
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </Grid>
  );
}
FilterSwitch.defaultProps = {
  showFilter: false,
  isAdvanced: false,
};
FilterSwitch.propTypes = {
  showFilter: PropTypes.bool,
  switchFilter: PropTypes.func.isRequired,
  isAdvanced: PropTypes.bool,
};
