import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props. https://www.npmjs.com/package/prop-types
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
  // setOpenConfigurator,
  // setTransparentSidenav,
  // setWhiteSidenav,
  // setMiniSidenav,
  setFixedNavbar,
  // setSidenavColor,
  setDarkMode,
} from "context";

// HospoSure
// import { _ } from "general";
import { create, update, list } from "database";
import { config } from "config";

const initial = config.filter((a) => a.initial);
const type = "UserProfile";

function useUserProfile({ name, email, userId: id, userGroups, subscribed }) {
  const [, dispatch] = useMaterialUIController();
  const initialCreateState = {
    id,
    name,
    email,
    isAdvanced: false,
    isDarkMode: false,
    isFixedNavbar: true,
    maxItems: 20,
    pageSettings: JSON.stringify(
      Object.assign(
        {},
        ...initial.map((a) => ({
          [a.type]: {
            ...a.initial,
          },
        }))
      )
    ),
    selectedVenue: undefined,
    accessLevel: subscribed ? "subscribed" : "demo",
  };
  const [userProfile, setUserProfile] = useState(initialCreateState);

  async function updateUserProfile(updates) {
    const { isDarkMode, isFixedNavbar } = updates;
    // include new fields added to schema & intialCreateState
    const add = Object.assign(
      {},
      ...Object.entries(initialCreateState)
        .filter(([k]) => userProfile[k] === null)
        .map(([k, v]) => ({ [k]: v }))
    );
    if (id && Object.keys(updates).length > 0) {
      if (isDarkMode !== undefined) setDarkMode(dispatch, isDarkMode);
      if (isFixedNavbar !== undefined) setFixedNavbar(dispatch, isFixedNavbar);
      setUserProfile((a) => ({ ...a, ...add, ...updates }));
      await update({ entry: { id, ...add, ...updates }, type });
    }
  }

  async function getUserProfile() {
    try {
      const { dataSet } = await list({ type });
      if (dataSet?.length) {
        setUserProfile(dataSet[0]);
        setDarkMode(dispatch, dataSet[0].isDarkMode);
        setFixedNavbar(dispatch, dataSet[0].isFixedNavbar);
      } else {
        setUserProfile(await create({ entry: initialCreateState, type }));
      }
    } catch {
      updateUserProfile(initialCreateState);
    }
  }

  const updatePageSettings = ({ pageType, setting }) => {
    const pageSettingsObj = JSON.parse(userProfile.pageSettings);
    updateUserProfile({
      pageSettings: JSON.stringify({
        ...pageSettingsObj,
        [pageType]: {
          ...pageSettingsObj[pageType],
          ...Object.assign(
            {},
            ...Object.entries(setting).map(([k, v]) => ({
              [k]:
                k === "sort"
                  ? {
                      ...setting?.sort,
                      ascending:
                        pageSettingsObj[pageType][k].key === setting?.sort?.key
                          ? !pageSettingsObj[pageType][k].ascending
                          : true,
                    }
                  : v,
            }))
          ),
        },
      }),
    });

    // const [k, v] = Object.entries(setting)[0];
    // const old = pageSettingsObj[type][k];
    // const upd =
    //   k === "sort"
    //     ? {
    //         ...setting.sort,
    //         ascending: old.key === setting.sort.key ? !old.ascending : true,
    //       }
    //     : v;
    // updateUserProfile({
    //   pageSettings: JSON.stringify({
    //     ...pageSettingsObj,
    //     [type]: upd ? { ...pageSettingsObj[type], [k]: upd } : pageSettingsObj[type],
    //   }),
    // });
  };

  useEffect(() => {
    getUserProfile();
  }, [id, userGroups]);

  return { userProfile, updateUserProfile, updatePageSettings };
}
useUserProfile.defaultProps = {
  name: "",
  userGroups: [],
};
useUserProfile.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  userGroups: PropTypes.arrayOf(PropTypes.string),
};

export default useUserProfile;
