// prop-types is a library for typechecking of props. https://www.npmjs.com/package/prop-types
import PropTypes from "prop-types";

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
// import MDCheckbox from "components/MDCheckbox";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDEditor from "components/MDEditor";
import MDInput from "components/MDInput";

// NewProduct page components
import FormField from "components/FormField";

// HospoSure
import { _ } from "general";

function Field({
  label,
  value,
  onChange,
  onBlur,
  lookups,
  lovs,
  singleKey,
  title,
  placeholder,
  showSelect,
  onKeyDown,
}) {
  const { type, keyType, selectKey } = singleKey;
  const props = {
    type: "text",
    label,
    value,
    onChange: (event) => onChange(event.target.value),
    onBlur: (event) => onBlur(singleKey.keyType.remove(event.target.value)),
    onKeyDown: (event) => onKeyDown(event),
    // title,
    placeholder,
  };
  const options =
    (_.exists(lookups[type]) && [
      ...(showSelect ? [{ id: 0, [selectKey || type.toLowerCase()]: "(select)" }] : []),
      ...(lookups[type].map((a) => a.id).includes(value)
        ? []
        : [{ id: 0, [type.toLowerCase()]: "" }]),
      ...lookups[type],
    ]) ||
    lovs;

  switch (keyType.display) {
    case "checkbox":
      return (
        <Tooltip title={title || ""} placement="top" TransitionComponent={Zoom}>
          <span>
            <Checkbox
              checked={value}
              onChange={(event) => onChange(event.target.checked)}
              onBlur={(event) => onBlur(singleKey.keyType.remove(event.target.checked))}
              onKeyDown={(event) => onKeyDown(event)}
              className="Checkbox"
            />
          </span>
        </Tooltip>
      );
    case "static":
      return (
        <MDBox lineHeight="normal">
          {label && (
            <MDBox>
              <Tooltip title={title || ""} placement="top" TransitionComponent={Zoom}>
                <MDTypography
                  variant="caption"
                  textTransform="capitalize"
                  color="text"
                  fontWeight="regular"
                  // title={title}
                >
                  {label}
                </MDTypography>
              </Tooltip>
            </MDBox>
          )}
          {value && (
            <MDBox>
              <MDTypography variant="button" fontWeight="regular">
                {value}
              </MDTypography>
            </MDBox>
          )}
        </MDBox>
      );
    case "staticSelect":
      return options ? (
        <Tooltip title={title || ""} placement="top" TransitionComponent={Zoom}>
          <MDBox>
            <Autocomplete
              value={value || options[0]}
              options={options}
              renderInput={(params) => <MDInput {...params} variant="standard" label={label} />}
              onChange={(_event, newValue) => onChange(newValue)}
              onBlur={() => onBlur(value)}
              disableClearable
            />
          </MDBox>
        </Tooltip>
      ) : null;
    case "select":
      return options ? (
        <Tooltip title={title || ""} placement="top" TransitionComponent={Zoom}>
          {/* bgColor={_.exists(options.filter((a) => a.id === value)) ? "transparent" : "warning"} */}
          <MDBox>
            <Autocomplete
              value={
                options.filter((a) => a.id === value)[0] || options.filter((a) => a.id === 0)[0]
              } // lookups[type][0]
              options={options}
              renderInput={(params) => <MDInput {...params} variant="standard" label={label} />}
              getOptionLabel={(option) => option[selectKey || type.toLowerCase()]}
              onChange={(_event, newValue) => onChange(newValue)}
              onBlur={() => onBlur(value)}
              disableClearable
              // title={title}
            />
          </MDBox>
        </Tooltip>
      ) : null;
    case "list":
      return (
        <Tooltip title={title || ""} placement="top" TransitionComponent={Zoom}>
          <span>
            <Autocomplete
              // value={
              //   options.filter((a) => a.id === value)[0] || options.filter((a) => a.id === 0)[0]
              // }
              options={value}
              renderInput={(params) => <MDInput {...params} variant="standard" label={label} />}
              getOptionLabel={(option) => option.name}
              onChange={(_event, newValue) => onChange(newValue.id)}
              // onBlur={() => onBlur(value)}
              // disableClearable
              // title={title}
            />
          </span>
        </Tooltip>
      );
    case "number":
      return (
        <Tooltip title={title || ""} placement="top" TransitionComponent={Zoom}>
          <span>
            <FormField {...{ ...props, inputMode: "numeric" }} />
          </span>
        </Tooltip>
      );
    case "multilineText":
      return (
        <Tooltip title={title || ""} placement="top" TransitionComponent={Zoom}>
          <span>
            <FormField {...props} multiline />
          </span>
        </Tooltip>
      );
    case "text":
    default:
      return (
        <Tooltip title={title || ""} placement="top" TransitionComponent={Zoom}>
          <span>
            <FormField {...props} />
          </span>
        </Tooltip>
      );
  }
}

Field.defaultProps = {
  label: null,
  value: null,
  lookups: null,
  lovs: null,
  onChange: null,
  onBlur: null,
  onKeyDown: null,
  // readOnly: false,
  title: null,
  placeholder: null,
  showSelect: false,
};
Field.propTypes = {
  singleKey: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
      PropTypes.number,
      PropTypes.func,
      PropTypes.object,
      PropTypes.bool,
    ])
  ).isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
    PropTypes.bool,
  ]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  lookups: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number, PropTypes.bool])
    )
  ),
  lovs: PropTypes.arrayOf(PropTypes.string),
  // readOnly: PropTypes.bool,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  showSelect: PropTypes.bool,
};

export default Field;
