// prop-types is a library for typechecking of props. https://www.npmjs.com/package/prop-types
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

const progress = {
  linear: (
    <MDBox sx={{ width: "100%" }}>
      <LinearProgress color="primary" />
    </MDBox>
  ),
  circular: <CircularProgress color="primary" />,
};

export default function Loading({ type }) {
  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item>{progress[type]}</Grid>
    </Grid>
  );
}
Loading.defaultProps = {
  type: "circular",
};
Loading.propTypes = {
  type: PropTypes.string,
};
