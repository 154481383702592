// prop-types is a library for typechecking of props. https://www.npmjs.com/package/prop-types
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

function PageTitleBox({ icon, title, description, action }) {
  return (
    <Grid item xs={12}>
      <Card id="title">
        <MDBox p={3}>
          <MDBox
            // display="flex"
            justifyContent="space-between"
            alignItems={{ xs: "flex-start" }}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <MDBox
              display="flex"
              // justifyContent="flex-start"
              flexDirection={{ xs: "row" }}
              alignItems="center"
            >
              {icon && (
                <MDBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  bgColor="secondary"
                  color="white"
                  width="2rem"
                  height="2rem"
                  borderRadius="50%"
                  sx={{ fontSize: ({ typography: { size } }) => size.lg }}
                  flexDirection={{ xs: "column", sm: "row" }}
                  marginRight="10px"
                >
                  <Icon fontSize="inherit">{icon}</Icon>
                </MDBox>
              )}
              <MDBox>
                <MDTypography variant="h5">{title}</MDTypography>
              </MDBox>
            </MDBox>
            <MDBox>
              <MDTypography variant="body2">
                {description} {action}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </Grid>
  );
}
PageTitleBox.defaultProps = {
  icon: null,
  action: null,
};
PageTitleBox.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.string,
};

export default PageTitleBox;
