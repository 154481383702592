// prop-types is a library for typechecking of props. https://www.npmjs.com/package/prop-types
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";

// HospoSure
import env from "env";
import { venueType } from "config";
import { getDate } from "general";
import PricingCard from "./components/PricingCard";

const { pricing } = env;

export default function Subscription({ admin, member, membership, notice }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const { subscribed, quantity, currentPeriodEnd, cancelAtPeriodEnd, cancelAt } = membership;
  const { id, email } = member;
  const params = `client_reference_id=${id}&prefilled_email=${encodeURIComponent(email)}`;

  return !admin ? (
    <>
      <MDBox display="flex" py={1} pr={2}>
        <MDTypography variant="body2" fontWeight="bold" textTransform="capitalize">
          Subscription: &nbsp;
        </MDTypography>
        <MDTypography variant="body2">
          &nbsp;
          {subscribed
            ? `You are subscribed for ${quantity} ${venueType} venue${quantity > 1 ? "s" : ""}. Your subscription will ${cancelAtPeriodEnd ? "expire" : "renew"} on ${cancelAtPeriodEnd ? getDate(cancelAt) : getDate(currentPeriodEnd)}.`
            : `You are not currently subscribed. Subscribe now and start modelling your ${venueType} venue.`}
        </MDTypography>
      </MDBox>
      {!subscribed && notice ? (
        <MDBox display="flex" py={1} pr={2}>
          {notice}
        </MDBox>
      ) : null}
      {!subscribed ? (
        <MDBox position="relative" zIndex={10} mt={2} px={{ xs: 1, sm: 0 }}>
          <Grid container spacing={3} justifyContent="center">
            {pricing.map((price) => (
              <Grid key={price.value} item xs={12} md={4}>
                <PricingCard
                  color={darkMode ? "dark" : "light"}
                  // specifications={[
                  //   { label: "2 team members", includes: true },
                  //   { label: "20GB Cloud storage", includes: true },
                  //   { label: "Integration help", includes: false },
                  //   { label: "Sketch Files", includes: false },
                  //   { label: "API Access", includes: false },
                  //   { label: "Complete documentation", includes: false },
                  // ]}
                  action={{
                    route: `${price.paymentLink}?${params}`,
                    color: "success",
                    label: "Subscribe",
                  }}
                  shadow={darkMode}
                  {...{ price }}
                />
              </Grid>
            ))}
          </Grid>
        </MDBox>
      ) : null}
    </>
  ) : null;
}
Subscription.defaultProps = {
  membership: { subscribed: false, quantity: 0 },
  notice: null,
};
Subscription.propTypes = {
  admin: PropTypes.bool.isRequired,
  member: PropTypes.objectOf(PropTypes.string).isRequired,
  membership: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string])
  ),
  notice: PropTypes.element,
};
