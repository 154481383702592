/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props. https://www.npmjs.com/package/prop-types
import PropTypes from "prop-types";

// import { useContext } from "react";
import { useLocation } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewProduct page components
// import FormField from "components/FormField";

// HospoSure
// import { AppContext } from "GlobalContext";
import ProfileCardHeader from "components/ProfileCardHeader";
import { config } from "config";

function PlatformSettings({ userProfile }) {
  // const [followsMe, setFollowsMe] = useState(true);
  // const [answersPost, setAnswersPost] = useState(false);
  // const [mentionsMe, setMentionsMe] = useState(true);
  // const [newLaunches, setNewLaunches] = useState(false);
  // const [productUpdate, setProductUpdate] = useState(true);
  // const [newsletter, setNewsletter] = useState(false);

  // const { userProfile } = useContext(AppContext);
  const { isDarkMode, isFixedNavbar, isAdvanced, updateUserProfile } = userProfile; // maxItems
  const location = useLocation();
  const pageCfg = config.filter((a) => a.route === location.pathname)[0] || {};
  const { description } = pageCfg;

  return (
    <Card sx={{ boxShadow: "none" }}>
      <MDBox p={3}>
        <ProfileCardHeader
          {...{
            icon: "settings",
            title: "Settings",
            description,
          }}
        />

        <MDBox pt={1} pb={2} px={3} lineHeight={1.25}>
          <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
            <MDBox mt={0.5}>
              <Switch
                checked={!isAdvanced}
                onChange={() => updateUserProfile({ isAdvanced: !isAdvanced })}
              />
            </MDBox>
            <MDBox width="80%" ml={0.5}>
              <MDTypography variant="button">Heading & Button mouseover tips</MDTypography>
            </MDBox>
          </MDBox>
          <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
            <MDBox mt={0.5}>
              <Switch
                checked={isDarkMode}
                onChange={() => {
                  updateUserProfile({ isDarkMode: !isDarkMode });
                }}
              />
            </MDBox>
            <MDBox width="80%" ml={0.5}>
              <MDTypography variant="button">Dark mode</MDTypography>
            </MDBox>
          </MDBox>
          <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
            <MDBox mt={0.5}>
              <Switch
                checked={isFixedNavbar}
                onChange={() => {
                  updateUserProfile({ isFixedNavbar: !isFixedNavbar });
                }}
              />
            </MDBox>
            <MDBox width="80%" ml={0.5}>
              <MDTypography variant="button">Fixed top navigation bar</MDTypography>
            </MDBox>
          </MDBox>

          {/* <MDBox display="flex" alignItems="center" mb={0.5}>
              <MDBox mt={0.5} width={47}>
                <FormField
                  type="number"
                  // label="Maximum entries to display"
                  value={maxItems}
                  id="maxEntries"
                  onChange={(event) => {
                    document.getElementById("maxEntries").value =
                      event.target.value === "" ? 0 : Math.max(Number(event.target.value), 0);
                    updateUserProfile({
                      maxItems:
                        event.target.value === "" ? 0 : Math.max(Number(event.target.value), 0),
                    });
                  }}
                  onBlur={() => {
                    if (maxItems < 10) updateUserProfile({ maxItems: 10 });
                  }}
                />
              </MDBox>
              <MDBox width="80%" ml={0.5}>
                <MDTypography variant="button"  color="text">
                  Maximum entries to display
                </MDTypography>
              </MDBox>
            </MDBox> */}
        </MDBox>
      </MDBox>
    </Card>
  );
}
PlatformSettings.propTypes = {
  userProfile: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
      PropTypes.string,
      PropTypes.func,
      PropTypes.object,
    ])
  ).isRequired,
};

export default PlatformSettings;
